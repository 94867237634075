import React, {Component} from "react";

//Components
import Fab from "@material-ui/core/Fab";

class ToolFab extends Component {

    state = {
        hovered:false
    }

    hoverOn = () => {
        this.setState({hovered:true});
    }

    hoverOff = () => {
        this.setState({hovered:false});
    }

    render() {

        let style = null;

        if(this.state.hovered) {
            style = {
                backgroundColor:'#333333'
            }
        }else {
            style = {
                backgroundColor:'#111111'
            }
        }

        return (
            <Fab onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff} style={style} onClick={this.props.onClick} props={this.props} color='primary' className={['tool-fab', this.props.className].join(' ')}>
                {this.props.children}
            </Fab>
        )
    }
}

export default ToolFab;