import React, {Component} from "react";
import './UserReport.css';

//Components
import Paper from '@material-ui/core/Paper';
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

class UserReport extends Component {

    state = {
        folded:true
    }

    toggleContent = () => {
        this.setState({folded:!this.state.folded});
    }

    formatString = (string, maxLength) => {
        let formattedString = string;
        if(string.length > maxLength) {
            formattedString = string.substring(0,maxLength) + '...';
        }
        return formattedString;
    }

    buildSum = (offer) => {
        let sum = 0;
        for(let i = 0; i < offer.documentPositions.length; i++) {
            sum = sum + offer.documentPositions[i].price;
        }
        return sum;
    }

    getRecentOffers = () => {
        let offers = [];
        let oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        for(let i = 0; i < this.props.userReport.offers.length; i++) {
            let offerDate = new Date();
            let date = this.props.userReport.offers[i].date.substring(8,10)
            let month = this.props.userReport.offers[i].date.substring(5,7)
            let year = this.props.userReport.offers[i].date.substring(0,4)
            offerDate.setFullYear(year);
            offerDate.setMonth(month - 1);
            offerDate.setDate(date);
            if(offerDate >= oneMonthAgo) {
                offers.push(this.props.userReport.offers[i]);
            }
        }
        return offers;
    }

    render() {

        let style;

        if(this.props.company.orderCountPerEmployee && this.getRecentOffers().length >= this.props.company.orderCountPerEmployee) {
            style = {
                backgroundColor:"lightgreen"
            }
        }else if(this.props.company.orderCountPerEmployee && this.getRecentOffers().length < this.props.company.orderCountPerEmployee && this.getRecentOffers().length >= (this.props.company.orderCountPerEmployee / 2)) {
            style = {
                backgroundColor:"lightyellow"
            }
        }else if(this.props.company.orderCountPerEmployee && this.getRecentOffers().length < (this.props.company.orderCountPerEmployee / 2)) {
            style = {
                backgroundColor:"lightcoral"
            }
        }

        let detailedInformation = this.state.folded ?
            null :
            <div className='detailed-information'>
                <table>
                    <tr>
                        <th>Kunde</th>
                        <th>Umsatz</th>
                        <th>Datum</th>
                        <th>Status</th>
                    </tr>
                    {this.props.userReport.offers.sort((a,b) => a.date < b.date ? 1 : -1).map(offer => (
                        <tr>
                            <td>{this.formatString(offer.customer.name, 15)}</td>
                            <td>{this.buildSum(offer) + " €"}</td>
                            <td>{this.props.formatDate(offer.date)}</td>
                            <td>{offer.status === 1 ? "aktiv" : offer.status === 2 ? "abgeschlossen" : offer.status === 3 ? "abgelehnt" : "unbekannt"}</td>
                        </tr>
                    ))}
                </table>
            </div>

        return (
            <Paper style={style} elevation={3} className='user-report'>
                <div className='top-field'>
                    <ul>
                        <li>
                            <h3 className='field'>{this.props.userReport.applicationUser && this.props.userReport.applicationUser.userInformation && this.props.userReport.applicationUser.userInformation.firstName && this.props.userReport.applicationUser.userInformation.lastName ? this.props.formatString(this.props.userReport.applicationUser.userInformation.firstName + " " + this.props.userReport.applicationUser.userInformation.lastName,15) : this.props.formatString(this.props.userReport.applicationUser.username,15)}</h3>
                        </li>
                        <li>
                            <h3 className='field'>{'Umsatz: ' + this.props.userReport.sum + " €"}</h3>
                        </li>
                        <li>
                            <h3 className='field'>{'Provision: ' + this.props.userReport.provisionSum + " €"}</h3>
                        </li>
                        <li className='icon-holder'>
                            {this.state.folded ?
                                <KeyboardArrowRightIcon onClick={this.toggleContent} className='arrow-icon'/> :
                                <KeyboardArrowDownIcon onClick={this.toggleContent} className='arrow-icon' />}
                        </li>
                    </ul>
                </div>
                {detailedInformation}
            </Paper>
        )
    }
}

export default UserReport;