import React, {Component} from "react";
import './Tool.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from "../../api";

//Components
import Toolbar from "./Toolbar/Toolbar";
import SidePanel from "./SidePanel/SidePanel";
import BottomPanel from "./BottomPanel/BottomPanel";
import Actions from "./Actions/Actions";
import ActionsMenu from "./ActionsMenu/ActionsMenu";
import UserMenu from "./UserMenu/UserMenu";
import jsPDF from "jspdf";

class Tool extends Component {

    state = {
        company:null,
        user:null,
        users:[],
        applications:[],
        articles:[],
        customers:[],
        customerRequests:[],
        offers:[],
        suppliers:[],
        craftsmen:[],
        teams:[],
        companyDataReports:null
    };

    //Load data

    componentDidMount() {
        this.loadUser();
        this.loadUsers();
        this.loadCompany();
        this.loadApplications();
        this.loadArticles();
        this.loadCustomers();
        this.loadCustomerRequests();
        this.loadOffers();
        this.loadSuppliers();
        this.loadCraftsmen();
        this.loadTeams();
    }

    loadUser = () => {
        const url = '/users/current';
        api.get(url)
            .then(response => {
                this.setState({user:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadUsers = () => {
        const url = '/users';
        api.get(url)
            .then(response => {
                this.setState({users:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCompany = () => {
        const url = '/company';
        api.get(url)
            .then(response => {
                if(response.data.rights === 1) {
                    this.loadCompanyDataReports();
                }
                this.setState({company:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadApplications = () => {
        const url = '/applications';
        api.get(url)
            .then(response => {
                this.setState({applications:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadArticles = () => {
        const url = '/articles';
        api.get(url)
            .then(response => {
                this.setState({articles:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCustomers = () => {
        const url = '/customers';
        api.get(url)
            .then(response => {
                this.setState({customers:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCustomerRequests = () => {
        const url = '/customer-requests';
        api.get(url)
            .then(response => {
                this.setState({customerRequests:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadOffers = () => {
        const url = '/offers';
        api.get(url)
            .then(response => {
                this.setState({offers:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadSuppliers = () => {
        const url = '/suppliers';
        api.get(url)
            .then(response => {
                this.setState({suppliers:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCraftsmen = () => {
        const url = '/craftsmen';
        api.get(url)
            .then(response => {
                this.setState({craftsmen:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadTeams = () => {
        const url = '/teams';
        api.get(url)
            .then(response => {
                this.setState({teams:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCompanyDataReports = () => {
        const url = '/company-data-reports';
        api.get(url)
            .then(response => {
                this.setState({companyDataReports:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    // List changes

    updateContractFileStorageLocation = (contractFileStorageLocation) => {
        let user = this.state.user;
        user.contractFileStorageLocation = contractFileStorageLocation;
        this.setState({user:user});
    }

    addApplicationToList = (application) => {
        let applications = this.state.applications;
        applications.push(application);
        this.setState({applications:applications});
    };

    removeApplicationFromList = (application) => {
        let applications = [...this.state.applications];
        for(let i = 0; i < applications.length; i++) {
            if (applications[i].id === application.id) {
                applications.splice(i,1)
                this.setState({applications:applications});
                return;
            }
        }
    }

    updateUserInList = (user) => {
        let users = this.state.users;
        for(let i = 0; i < users.length; i++) {
            if(users[i].id === user.id) {
                users[i] = user;
                break;
            }
        }
        this.setState({users:users});
    }

    addUserToList = (user) => {
        let users = this.state.users;
        users.push(user);
        this.setState({users:users});
    };

    removeUserFromList = (user) => {
        let users = [...this.state.users];
        for(let i = 0; i < users.length; i++) {
            if (users[i].id === user.id) {
                users.splice(i,1)
                this.setState({users:users});
                return;
            }
        }
    }

    //Article actions
    addNewArticle = (article) => {
        const url = '/articles';
        api.post(url,article)
            .then(response => {
                this.addArticleToList(response.data);
                this.props.showMessage(0,'Artikel erfolgreich hinzugefügt');
                return true;
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateArticle = (article) => {
        console.log(article)
        const url = '/articles';
        api.put(url,article)
            .then(response => {
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteArticle = (article) => {
        api.delete("/articles/" + article.id)
            .then(response => {
                this.props.showMessage(0,"Artikel erfolgreich gelöscht");
                this.removeArticleFromList(article);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    addArticleToList = (article) => {
        let articles = this.state.articles;
        articles.push(article);
        this.setState({articles:articles});
    };

    removeArticleFromList = (article) => {
        let articles = [...this.state.articles];
        for(let i = 0; i < articles.length; i++) {
            if (articles[i].id === article.id) {
                articles.splice(i,1)
                this.setState({articles:articles});
                return;
            }
        }
    }

    //Customer actions
    addNewCustomer = (customer) => {
        const url = '/customers';
        api.post(url,customer)
            .then(response => {
                this.addCustomerToList(response.data);
                this.props.showMessage(0,'Kunde erfolgreich angelegt')
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateCustomer = (customer) => {
        const url = '/customers';
        api.put(url,customer)
            .then(response => {
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert")
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteCustomer = (customer) => {
        api.delete("/customers/" + customer.id)
            .then(response => {
                this.props.showMessage(0,"Kunde erfolgreich gelöscht");
                this.removeCustomerFromList(customer.id);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    addMultipleCustomers = (customers) => {
        const url = '/customers/multiple';
        api.post(url,customers)
            .then(response => {
                response.data.forEach(customer => {
                    this.addCustomerToList(customer);
                })
                this.props.showMessage(0,response.data.length + ' Datensätze wurden erfolgreich angelegt. Kunden mit gedoppelten Kundennummern wurden nicht hinzugefügt');
                return true;
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    addCustomerToList = (customer) => {
        let customers = this.state.customers;
        customers.push(customer);
        this.setState({customers:customers});
    }

    removeCustomerFromList = (id) => {
        let customers = [...this.state.customers];
        for(let i = 0; i < customers.length; i++) {
            if (customers[i].id === id) {
                customers.splice(i,1)
                this.setState({customers:customers});
                return;
            }
        }
    }

    //Supplier actions
    addNewSupplier = (supplier) => {
        const url = '/suppliers';
        api.post(url,supplier)
            .then(response => {
                this.addSupplierToList(response.data);
                this.props.showMessage(0,'Lieferant erfolgreich angelegt')
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateSupplier = (supplier) => {
        const url = '/suppliers';
        api.put(url,supplier)
            .then(response => {
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert")
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteSupplier = (supplier) => {
        api.delete("/suppliers/" + supplier.id)
            .then(response => {
                this.props.showMessage(0,"Lieferant erfolgreich gelöscht");
                this.removeSupplierFromList(supplier.id);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    addSupplierToList = (supplier) => {
        let suppliers = this.state.suppliers;
        suppliers.push(supplier);
        this.setState({suppliers:suppliers});
    }

    removeSupplierFromList = (id) => {
        let suppliers = [...this.state.suppliers];
        for(let i = 0; i < suppliers.length; i++) {
            if (suppliers[i].id === id) {
                suppliers.splice(i,1)
                this.setState({suppliers:suppliers});
                return;
            }
        }
    }

    //Craftsman actions
    addNewCraftsman = (craftsman) => {
        const url = '/craftsmen';
        api.post(url,craftsman)
            .then(response => {
                this.addCraftsmanToList(response.data);
                this.props.showMessage(0,'Kunde erfolgreich angelegt')
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateCraftsman = (craftsman) => {
        const url = '/craftsmen';
        api.put(url,craftsman)
            .then(response => {
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert")
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteCraftsman = (craftsman) => {
        api.delete("/craftsmen/" + craftsman.id)
            .then(response => {
                this.props.showMessage(0,"Kunde erfolgreich gelöscht");
                this.removeCraftsmanFromList(craftsman.id);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    addCraftsmanToList = (craftsman) => {
        let craftsmen = this.state.craftsmen;
        craftsmen.push(craftsman);
        this.setState({craftsmen:craftsmen});
    }

    removeCraftsmanFromList = (id) => {
        let craftsmen = [...this.state.craftsmen];
        for(let i = 0; i < craftsmen.length; i++) {
            if (craftsmen[i].id === id) {
                craftsmen.splice(i,1)
                this.setState({craftsmen:craftsmen});
                return;
            }
        }
    }

    //Team actions

    addTeamToList = (team) => {
        let teams = this.state.teams;
        teams.push(team);
        this.setState({teams:teams});
    }

    removeTeamFromList = (id) => {
        let teams = [...this.state.teams];
        for(let i = 0; i < teams.length; i++) {
            if (teams[i].id === id) {
                teams.splice(i,1)
                this.setState({teams:teams});
                return;
            }
        }
    }

    //Customer Requests

    addCustomerRequestToList = (customerRequest) => {
        let customerRequests = this.state.customerRequests;
        customerRequests.push(customerRequest);
        this.setState({customerRequests:customerRequests});
    }

    removeCustomerRequestFromList = (customerRequest) => {
        let customerRequests = [...this.state.customerRequests];
        for(let i = 0; i < customerRequests.length; i++) {
            if (customerRequests[i].id === customerRequest.id) {
                customerRequests.splice(i,1)
                this.setState({customerRequests:customerRequests});
                return;
            }
        }
    }

    addAppointmentToList = (appointment) => {
        let user = this.state.user;
        user.appointments.push(appointment);
        this.setState({user:user});
    }

    removeAppointmentFromList = (appointment) => {
        let user = this.state.user;
        for(let i = 0; i < user.appointments.length; i++) {
            if (user.appointments[i].id === appointment.id) {
                user.appointments.splice(i,1)
                this.setState({user:user});
                return;
            }
        }
    }

    // Offers

    addOfferToList = (offer) => {
        let offers = this.state.offers;
        offers.push(offer);
        this.setState({offers:offers});
    }

    updateOfferInList = (offer) => {
        let offers = [...this.state.offers];
        for(let i = 0; i < offers.length; i++) {
            if (offers[i].id === offer.id) {
                offers[i] = offer;
                this.setState({offers:offers});
                return;
            }
        }
    }

    removeOfferFromList = (offer) => {
        let offers = [...this.state.offers];
        for(let i = 0; i < offers.length; i++) {
            if (offers[i].id === offer.id) {
                offers.splice(i,1)
                this.setState({offers:offers});
                return;
            }
        }
    }

    round = (value, decimals) => {
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    }

    formatString = (string,maxLength) => {
        let substring = string;
        if(string.length > maxLength) {
            substring = string.substring(0,maxLength) + "..."
        }
        return substring;
    }

    formatDate = (date) => {
        return date.substring(8, 10) + "." + date.substring(5,7) + "." + date.substring(0, 4);
    }

    generateOffer = (offer) => {
        let pdf = new jsPDF('p','px');
        //format: ~445 x 630
        pdf.addFont('OpenSans-Regular.ttf', 'OpenSans-Regular-normal', 'normal');
        pdf.addFont('OpenSans-Regular.ttf', 'OpenSans-Regular-normal', 'normal');
        pdf.setFont('OpenSans-Regular');
        let documentPositions = [];
        for(let i = 0; i < offer.documentPositions.length; i++) {
            documentPositions.push(offer.documentPositions[i]);
        }
        let countPages = Math.ceil(documentPositions.length / 5);
        for(let i = 0; i < countPages; i++) {
            let pageNumber = i + 1;
            if(i !== 0) {
                pdf.addPage('string','p')
            }
            pdf.setFontSize(8);
            if(this.state.company.logo) {
                pdf.addImage(this.state.company.logo, 185, 20, 75, 75);
            }

            // Company Strings
            let companyName = this.state.company.name ? this.state.company.name : '';
            let companyStreetName = this.state.company.streetName ? this.state.company.streetName : '';
            let companyStreetNr = this.state.company.streetNr ? this.state.company.streetNr : '';
            let companyPostalCode = this.state.company.postalCode ? this.state.company.postalCode : '';
            let companyCity = this.state.company.city ? this.state.company.city : '';
            let companyCountry = this.state.company.country ? this.state.company.country : '';
            let companyWebsite = this.state.company.website ? this.state.company.website : '';
            let companyEmail = this.state.company.email ? this.state.company.email : '';
            let companyChiefExecutiveOfficer = this.state.company.chiefExecutiveOfficer ? this.state.company.chiefExecutiveOfficer : '';
            let companyPhoneNumber = this.state.company.phoneNumber ? this.state.company.phoneNumber : '';
            let companyBic = this.state.company.bic ? this.state.company.bic : '';
            let companyIban = this.state.company.iban ? this.state.company.iban : '';
            let companyTaxId = this.state.company.taxId ? this.state.company.taxId : '';
            let companyTaxNr = this.state.company.taxNr ? this.state.company.taxNr : '';
            let companyCompanyRegistrationNumber = this.state.company.companyRegistrationNumber ? this.state.company.companyRegistrationNumber : '';
            let companyCommercialCourtLocation = this.state.company.commercialCourtLocation ? this.state.company.commercialCourtLocation : '';

            let companyStreetNameAndNr = companyStreetName + ' ' + companyStreetNr;
            let companyPostalCodeAndCity = companyPostalCode + ' ' + companyCity;

            let companyInfoHeadline = companyName + ' - ' + companyStreetName + ' ' + companyStreetNr + ' - ' + companyPostalCode + ' ' + companyCity;

            // Customer Strings
            let customerName = offer.customer.name ? offer.customer.name : '';
            let customerStreetName = offer.customer ? offer.customer.streetName ? offer.customer.streetName : '' : '';
            let customerStreetNr = offer.customer ? offer.customer.streetNr ? offer.customer.streetNr : '' : '';
            let customerPostalCode = offer.customer ? offer.customer.postCode ? offer.customer.postCode : '' : '';
            let customerCity = offer.customer ? offer.customer.city ? offer.customer.city : '' : '';
            let customerCountry = offer.customer ? offer.customer.country ? offer.customer.country : '' : '';

            let customerStreetNameAndNr = customerStreetName + ' ' + customerStreetNr;
            let customerPostalCodeAndCity = customerPostalCode + ' ' + customerCity;

            pdf.text(companyInfoHeadline,40,120);
            pdf.setFontSize(10);
            pdf.text(customerName,40,150);
            pdf.text(customerStreetNameAndNr,40,160);
            pdf.text(customerPostalCodeAndCity,40,170);
            pdf.text(customerCountry,40,180);

            pdf.setFontSize(12);

            let date = offer.date ? (offer.date.substring(8,10) + '.' + offer.date.substring(5,7) + '.' + offer.date.substring(0,4)) : '';

            pdf.setFontSize(8);
            pdf.text('Kundennr.: ' + offer.customer.nr ,300,152);
            pdf.text('Datum: ' + date,300,160);
            pdf.text(offer.contactPerson ? 'Ansprechpartner: ' + offer.contactPerson : '',300,184);
            pdf.text(offer.contactEmail ? 'E-Mail: ' + offer.contactEmail : '',300,192);
            pdf.text(offer.contactPhoneNumber ? 'Telefonnummer: ' + offer.contactPhoneNumber : '',300,200);

            pdf.setFontSize(16);
            pdf.setFont('OpenSans-Bold','normal');
            pdf.text('Angebot',40,220);
            pdf.setFontSize(10);
            pdf.setFont('OpenSans-Regular','normal');
            pdf.text('Das Angebot gilt bis zwei Wochen nach Erstellung dieses Dokumentes',40,240);
            pdf.text('Artikel',40,260);
            pdf.text('Menge',240,260);
            pdf.text('Stk. Preis',290,260);
            pdf.text('Gesamt',340,260);
            let countStart = i * 5;
            let countEnd = i === (countPages - 1) ? documentPositions.length % 5 === 0 ? countStart + 5 : (countStart + (documentPositions.length % 5)) : (i * 5 + 5);
            for(let j = countStart; j < countEnd; j++) {
                pdf.text('____________________________________________________________________________________________________',40,265 + (j % 5 * 20));

                let articleCount = (documentPositions[j].article.count && documentPositions[j].article.count !== 1) ? ' (x' + documentPositions[j].article.count + ')' : '';

                pdf.text(documentPositions[j].article.name + articleCount,40,275 + (j % 5 * 20));
                pdf.setTextColor('gray');
                pdf.text(documentPositions[j].article.nr,40,283 + (j % 5 * 20));
                pdf.setTextColor('black');
                pdf.text(documentPositions[j].count.toString(),240,279 + (j % 5 * 20));

                let salesNetPrice = documentPositions[j].price / documentPositions[j].count;

                pdf.text(this.round(salesNetPrice,2) + ' €',290,279 + (j % 5 * 20));
                pdf.text(this.round(documentPositions[j].price,2) + ' €',340,279 + (j % 5 * 20));
            }
            if(i === (countPages - 1)) {
                let netSum = 0;
                let vat = 0;
                for(let k = 0;k < offer.documentPositions.length; k++) {
                    netSum = netSum + parseFloat(documentPositions[k].price);
                    vat = netSum * 0.19;
                }
                let sum = vat + netSum;

                pdf.text('Summe netto',270,380);
                pdf.text(this.round(netSum,2) + ' €',340,380);

                pdf.text('MwSt. 19 %',270,390);
                pdf.text(this.round(vat,2) + ' €',340,390);

                pdf.setFont('OpenSans-Bold','normal');
                pdf.text('Gesamt',270,400);
                pdf.text(this.round(sum,2) + ' €',340,400);
                pdf.text('========================',270,405);

                pdf.setFont('OpenSans-Regular','normal');
                pdf.text('Wir bedanken uns für Ihre Zeit',40,460);
                pdf.text('und freuen uns auf eine gute Zusammenarbeit.',40,470);
            }
            pdf.setFontSize(8);
            pdf.setTextColor('gray');

            pdf.text(companyName,40,560);
            pdf.text(companyStreetNameAndNr,40,568);
            pdf.text(companyPostalCodeAndCity,40,576);
            pdf.text(companyCountry,40,584);

            pdf.text(this.state.company.website ? 'Web: ' + companyWebsite : '',160,560);
            pdf.text(this.state.company.email ? 'E-Mail: ' + companyEmail : '',160,568);
            pdf.text(this.state.company.phoneNumber ? 'Tel.: ' + companyPhoneNumber : '',160,576);
            pdf.text(this.state.company.chiefExecutiveOfficer ? 'Geschäftsführung:' : '',160,584);
            pdf.text(this.state.company.chiefExecutiveOfficer ? companyChiefExecutiveOfficer : '',160,592);

            pdf.text(this.state.company.bic ? 'BLZ: ' + companyBic : '',280,560);
            pdf.text(this.state.company.iban ? 'IBAN: ' +  companyIban : '',280,568);
            pdf.text(this.state.company.taxId ? 'USt-ID: ' + companyTaxId : '',280,576);
            pdf.text(this.state.company.taxNr ? 'Steuernr.: ' + companyTaxNr : '',280,584);
            pdf.text(this.state.company.commercialCourtLocation ? 'Handelsreg. Ort: ' + companyCommercialCourtLocation : '',280,592);
            pdf.text(this.state.company.companyRegistrationNumber ? 'Handelsreg. Nr.: ' + companyCompanyRegistrationNumber : '',280,600);

            pdf.setFontSize(10);
            pdf.setTextColor('black');
            pdf.text('Seite ' + pageNumber + ' von ' + countPages,380,620);
        }
        pdf.save('angebot_' + offer.customer.name + '.pdf');
    }

    displayFile = (pathFileRequest) => {
        const url = '/file-storage';
        api.post(url,pathFileRequest,{responseType:'blob'})
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: response.data.type});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    displayEmployeeFile = (employeeFileRequest) => {
        let url = '/users/display-file/employee';
        api.post(url,employeeFileRequest,{responseType:'blob'})
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: response.data.type});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    render() {
        return (
            <div className='tool'>

                <Toolbar
                    user={this.state.user}
                    company={this.state.company}
                />
                <div className='content'>
                    <UserMenu
                        //Data
                        user={this.state.user}
                        logout={this.props.logout}
                    />
                    <ActionsMenu/>
                    <Actions
                        //Common
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        round={this.round}
                        generateOffer={this.generateOffer}
                        formatDate={this.formatDate}
                        formatString={this.formatString}

                        //User
                        user={this.state.user}
                        users={this.state.users}
                        loadUser={this.loadUser}
                        logout={this.props.logout}
                        updateUserInList={this.updateUserInList}
                        addUserToList={this.addUserToList}
                        removeUserFromList={this.removeUserFromList}
                        updateContractFileStorageLocation={this.updateContractFileStorageLocation}

                        //Company
                        company={this.state.company}

                        //Reporting
                        applications={this.state.applications}
                        addApplicationToList={this.addApplicationToList}
                        removeApplicationFromList={this.removeApplicationFromList}

                        //Articles
                        articles={this.state.articles}
                        addNewArticle={this.addNewArticle}
                        updateArticle={this.updateArticle}
                        deleteArticle={this.deleteArticle}
                        addArticleToList={this.addArticleToList}
                        removeArticleFromList={this.removeArticleFromList}

                        //Customers
                        customers={this.state.customers}
                        addNewCustomer={this.addNewCustomer}
                        updateCustomer={this.updateCustomer}
                        deleteCustomer={this.deleteCustomer}
                        addCustomerToList={this.addCustomerToList}
                        removeCustomerFromList={this.removeCustomerFromList}

                        //Suppliers
                        suppliers={this.state.suppliers}
                        addNewSupplier={this.addNewSupplier}
                        updateSupplier={this.updateCustomer}
                        deleteSupplier={this.deleteSupplier}
                        addSupplierToList={this.addSupplierToList}
                        removeSupplierFromList={this.removeSupplierFromList}

                        //Customers
                        craftsmen={this.state.craftsmen}
                        addNewCraftsman={this.addNewCraftsman}
                        updateCraftsman={this.updateCraftsman}
                        deleteCraftsman={this.deleteCraftsman}
                        addMultipleCustomers={this.addMultipleCustomers}
                        addCraftsmanToList={this.addCraftsmanToList}
                        removeCraftsmanFromList={this.removeCraftsmanFromList}

                        //File
                        displayEmployeeFile={this.displayEmployeeFile}

                        //Customer Requests
                        customerRequests={this.state.customerRequests}
                        addCustomerRequestToList={this.addCustomerRequestToList}
                        removeCustomerRequestFromList={this.removeCustomerRequestFromList}

                        //Appointments
                        addAppointmentToList={this.addAppointmentToList}
                        removeAppointmentFromList={this.removeAppointmentFromList}

                        //Offers
                        offers={this.state.offers}
                        addOfferToList={this.addOfferToList}
                        updateOfferInList={this.updateOfferInList}
                        removeOfferFromList={this.removeOfferFromList}

                        //Teams
                        teams={this.state.teams}
                        addTeamToList={this.addTeamToList}
                        removeTeamFromList={this.removeTeamFromList}

                        //Company Data Reports
                        companyDataReports={this.state.companyDataReports}
                    />
                    <BottomPanel
                        //Common
                        showMessage={this.props.showMessage}
                    />
                    <SidePanel
                        //Common
                        showMessage={this.props.showMessage}
                    />
                </div>
            </div>
        )
    }
}

export default Tool;