import React, {Component} from "react";
import './CustomerRequestDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from "react-tooltip";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PlanAppointmentDialog from "./PlanAppointmentDialog/PlanAppointmentDialog";
import DialogContent from "@material-ui/core/DialogContent";

class CustomerRequestDialog extends Component {

    state = {
        customerRequest:this.props.customerRequest,
        isNewCustomer:false,
        dateTime:'',

        // Dialog actions
        showPlanAppointmentDialog:false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({customerRequest:this.props.customerRequest});
        }
    }

    customerOnChangeHandler = (value) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer = value;
        this.setState({customerRequest:customerRequest});
    }

    customerNrOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.nr = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    customerStreetNameOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.streetName = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    customerStreetNrOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.streetNr = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    customerAddressAdditionOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.addressAddition = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    customerPostCodeOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.postCode = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    customerCityOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.city = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    customerCountryOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.country = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    customerEmailOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.email = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    customerPhoneOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.customer.phone = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    descriptionOnChangeHandler = (e) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.description = e.target.value;
        this.setState({customerRequest:customerRequest});
    }

    userOnChangeHandler = (value) => {
        let customerRequest = this.state.customerRequest;
        customerRequest.user = value;
        this.setState({customerRequest:customerRequest});
    }

    sendCustomerRequestOnClickHandler = (customerRequest) => {
        if(customerRequest.customer) {
            if(customerRequest.id) {
                this.props.updateCustomerRequest(customerRequest);
            }else{
                this.props.sendCustomerRequest(customerRequest);
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    dateTimeOnChangeHandler = (e) => {
        this.setState({dateTime:e.target.value});
    }

    planAppointmentOnClickHandler = () => {
        if(this.state.dateTime) {
            let appointmentRequest = {
                customerRequest:this.state.customerRequest,
                dateTime:this.state.dateTime
            }
            this.props.planAppointment(appointmentRequest);
            this.closePlanAppointmentDialog();
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte")
        }
    }

    // Dialog actions

    showPlanAppointmentDialog = () => {
        this.setState({showPlanAppointmentDialog:true});
    }

    closePlanAppointmentDialog = () => {
        this.setState({showPlanAppointmentDialog:false});
    }

    render() {

        const filter = createFilterOptions();

        return (
            <Dialog scroll='body' maxWidth={"xl"} open={this.props.open} onClose={this.props.close} className='customer-request-dialog'>
                <ReactTooltip/>

                <PlanAppointmentDialog
                    open={this.state.showPlanAppointmentDialog}
                    close={this.closePlanAppointmentDialog}
                    showMessage={this.props.showMessage}
                    dateTimeOnChangeHandler={this.dateTimeOnChangeHandler}
                    dateTime={this.state.dateTime}
                    planAppointmentOnClickHandler={this.planAppointmentOnClickHandler}
                />
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle><h1>Kundenanfrage</h1></DialogTitle>
                <DialogContent>
                    <div>
                        <h2>Kunde</h2>
                        <Autocomplete
                            disabled={this.state.customerRequest && this.state.customerRequest.id || (this.state.customerRequest && (this.props.user.rights !== 3 && this.props.user.rights !== 4))}
                            value={this.state.customerRequest && this.state.customerRequest.customer ? this.state.customerRequest.customer.name: ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.customerOnChangeHandler({
                                        name: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    this.customerOnChangeHandler({
                                        name: newValue.inputValue,
                                    });
                                    this.setState({isNewCustomer:true});
                                } else {
                                    this.customerOnChangeHandler(newValue);
                                    this.setState({isNewCustomer:false});
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.name);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        name: `Erstellen "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="customers"
                            className='field'
                            options={this.props.customers}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Kunde" />
                            )}
                        />
                        {this.state.customerRequest && this.state.customerRequest.customer && !this.state.customerRequest.customer.id && this.state.isNewCustomer ?
                            <div>
                                <TextField className='field' label='Kundennummer' value={this.state.customerRequest.customer.nr} onChange={this.customerNrOnChangeHandler}/>
                                <TextField className='field' label='Straße' value={this.state.customerRequest.customer.streetName} onChange={this.customerStreetNameOnChangeHandler}/>
                                <TextField className='field' label='Hausnummer' value={this.state.customerRequest.customer.streetNr} onChange={this.customerStreetNrOnChangeHandler}/>
                                <TextField className='field' label='Adresszusatz' value={this.state.customerRequest.customer.addressAddition} onChange={this.customerAddressAdditionOnChangeHandler}/>
                                <TextField className='field' label='Postleitzahl' value={this.state.customerRequest.customer.postCode} onChange={this.customerPostCodeOnChangeHandler}/>
                                <TextField className='field' label='Stadt' value={this.state.customerRequest.customer.city} onChange={this.customerCityOnChangeHandler}/>
                                <TextField className='field' label='Land' value={this.state.customerRequest.customer.country} select onChange={this.customerCountryOnChangeHandler}>
                                    {this.props.countries}
                                </TextField>
                                <TextField className='field' label='E-Mail' value={this.state.customerRequest.customer.email} onChange={this.customerEmailOnChangeHandler}/>
                                <TextField className='field' label='Telefon' value={this.state.customerRequest.customer.phone} onChange={this.customerPhoneOnChangeHandler}/>
                            </div> : null}
                        {this.state.customerRequest && this.state.customerRequest.id && this.state.customerRequest.customer ?
                            <div>
                                <TextField className='field' label='Kundennummer' disabled value={this.state.customerRequest.customer.nr}/>
                                <TextField className='field' label='Straße' disabled value={this.state.customerRequest.customer.streetName}/>
                                <TextField className='field' label='Hausnummer' disabled value={this.state.customerRequest.customer.streetNr}/>
                                <TextField className='field' label='Adresszusatz' disabled value={this.state.customerRequest.customer.addressAddition}/>
                                <TextField className='field' label='Postleitzahl' disabled value={this.state.customerRequest.customer.postCode}/>
                                <TextField className='field' label='Stadt' disabled value={this.state.customerRequest.customer.city}/>
                                <TextField className='field' label='Land' disabled value={this.state.customerRequest.customer.country}/>
                                <TextField className='field' label='E-Mail' disabled value={this.state.customerRequest.customer.email}/>
                                <TextField className='field' label='Telefon' disabled value={this.state.customerRequest.customer.phone}/>
                            </div> : null}

                    </div>
                    <div>
                        <h2>Anfrage</h2>
                        <Autocomplete
                            disabled={this.props.user && (this.props.user.rights !== 3 && this.props.user.rights !== 4)}
                            value={this.state.customerRequest && this.state.customerRequest.user ? this.state.customerRequest.user.username : ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.userOnChangeHandler({
                                        username: newValue,
                                    });
                                } else {
                                    this.userOnChangeHandler(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="users"
                            className='field'
                            options={this.props.users}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.username;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.username}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <TextField helperText='optional' {...params} label="Mitarbeiter" />
                            )}
                        />
                        <p className='info-text'>Wenn dieses Feld leer gelassen wird, wird die Kundenanfrage einem freien Mitarbeiter in der Nähe des Kunden zugewiesen.</p>
                        <TextField disabled={this.props.user && (this.props.user.rights !== 3 && this.props.user.rights !== 4)} inputProps={{maxLength:200}} value={this.state.customerRequest ? this.state.customerRequest.description : ''} label="Beschreibung" helperText='optional' multiline className='multiline-input' minRows={7} onChange={this.descriptionOnChangeHandler}/>
                    </div>
                </DialogContent>

                    {this.props.user && (this.props.user.rights === 3 || this.props.user.rights === 4) ?
                        <DialogActions>
                            {this.state.customerRequest && this.state.customerRequest.id ? <ToolButton className='button' main onClick={() => this.sendCustomerRequestOnClickHandler(this.state.customerRequest)}>Speichern</ToolButton> : <ToolButton className='button' main onClick={() => this.sendCustomerRequestOnClickHandler(this.state.customerRequest)}>Abschicken</ToolButton>}
                        </DialogActions> :
                        <DialogActions>
                            <ToolButton className='button' main onClick={this.showPlanAppointmentDialog}>Termin planen</ToolButton>
                        </DialogActions>}
            </Dialog>
        )
    }
}

export default CustomerRequestDialog;