import React, {Component} from "react";
import './ConditionItem.css';

//Components
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";

class ConditionItem extends Component {
    render() {

        const filter = createFilterOptions();

        let conditionTypes = [];
        let conditionSelection = null;
        let infoText = null;

        conditionTypes.push(<MenuItem key={1} value={1}>Artikel</MenuItem>);
        conditionTypes.push(<MenuItem key={2} value={2}>Kunde</MenuItem>);
        conditionTypes.push(<MenuItem key={3} value={3}>Mitarbeiter</MenuItem>);
        conditionTypes.push(<MenuItem key={4} value={4}>Status</MenuItem>);

        switch (this.props.condition.type) {
            case 1:
                conditionSelection = <Autocomplete
                    value={this.props.condition && this.props.condition.article ? this.props.condition.article.name : ''}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            this.props.articleOnChangeHandler({
                                name: newValue,
                            },this.props.index);
                        } else {
                            this.props.articleOnChangeHandler(newValue,this.props.index);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="customers"
                    className='input-field'
                    options={this.props.articles}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} label="Artikel" />
                    )}
                />;
                infoText = <p className='info-text'>Zeigt die kompletten Angebote, in denen dieser Artikel vorkommt</p>
                break;
            case 2:
                conditionSelection = <Autocomplete
                    value={this.props.condition && this.props.condition.customer ? this.props.condition.customer.name : ''}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            this.props.customerOnChangeHandler({
                                name: newValue,
                            },this.props.index);
                        } else {
                            this.props.customerOnChangeHandler(newValue,this.props.index);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="customers"
                    className='input-field'
                    options={this.props.customers}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} label="Kunde" />
                    )}
                />;
                break;
            case 3:
                conditionSelection = <Autocomplete
                    value={this.props.condition && this.props.condition.applicationUser ? this.props.condition.applicationUser.username : ''}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            this.props.userOnChangeHandler({
                                name: newValue,
                            },this.props.index);
                        } else {
                            this.props.userOnChangeHandler(newValue,this.props.index);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="customers"
                    className='input-field'
                    options={this.props.users}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.username;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.username}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} label="Benutzer" />
                    )}
                />;
                break;
            case 4:
                conditionSelection = <TextField value={this.props.condition.status} onChange={(e) => this.props.statusOnChangeHandler(e,this.props.index)} select className='input-field' label='Status'>
                    <MenuItem key={1} value={1}>aktiv</MenuItem>
                    <MenuItem key={2} value={2}>abgeschlossen</MenuItem>
                    <MenuItem key={3} value={3}>abgelehnt</MenuItem>
                </TextField>;
                break;
            default:
                conditionSelection = null;
                break;
        }

        return (
            <div className='condition-item'>
                <TextField onChange={(e) => this.props.typeOnChangeHandler(e,this.props.index)} label='Bedingungstyp' className='input-field' select>
                    {conditionTypes}
                </TextField>
                {conditionSelection}
                {infoText}
                <i onClick={() => this.props.removeConditionOnClickHandler(this.props.index)} className="fas fa-trash-alt"/>
            </div>
        )
    }
}

export default ConditionItem;