import React, {Component} from 'react';
import './ChangeUserPasswordDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import api from "../../../../../../../api";

class ChangeUserPasswordDialog extends Component {

    state = {
        newPassword:'',
        newPasswordCheck:''
    }

    //On Change Handler

    newPasswordOnChangeHandler = (e) => {
        this.setState({newPassword:e.target.value});
    }

    newPasswordCheckOnChangeHandler = (e) => {
        this.setState({newPasswordCheck:e.target.value});
    }

    changeUserPasswordOnClickHandler = () => {
        if(this.state.newPassword && this.state.newPasswordCheck) {
            if (this.state.newPassword === this.state.newPasswordCheck) {
                this.changeUserPassword();
            } else {
                this.props.showMessage(2, "Die Passwörter stimmen nicht über ein");
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    changeUserPassword = () => {
        let changePasswordRequest = {
            newPassword:this.state.newPassword,
            applicationUser:this.props.user
        }
        console.log(changePasswordRequest)
        let url = '/users/change-password';
        api.put(url,changePasswordRequest)
            .then(response => {
                this.props.showMessage(0,"Das Password wurde erfolgreich geändert");
                this.props.close();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='change-user-password-dialog'>
                <ReactTooltip/>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle><h1 className='item-name'>Benutzer</h1></DialogTitle>
                <DialogContent>
                    <TextField onChange={this.newPasswordOnChangeHandler} value={this.state.newPassword} type='password' className='field' label='Neues Passwort'/>
                    <TextField onChange={this.newPasswordCheckOnChangeHandler} value={this.state.newPasswordCheck} type='password' className='field' label='Neues Passwort wiederholen'/>
                </DialogContent>
                <DialogActions>
                    <ToolButton className='button' main onClick={this.changeUserPasswordOnClickHandler}>Ändern</ToolButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ChangeUserPasswordDialog;