import React, {Component} from 'react';
import './MasterDataContent.css';

//Components
import MenuItem from '@material-ui/core/MenuItem';
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import AddIcon from '@material-ui/icons/Add';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import MasterDataItem from "./MasterDataItem/MasterDataItem";
import ItemDialog from "./ItemDialog/ItemDialog";
import TextField from "@material-ui/core/TextField";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";
import ReactTooltip from "react-tooltip";
import UploadIcon from '@mui/icons-material/Upload';
import ImportDataDialog from "./ImportDataDialog/ImportDataDialog";

class MasterDataContent extends Component {

    state = {
        shownItems: [],
        searchWord:'',
        searchType:'name',
        selectedItem:null,
        action:null,
        item: {
            nr:'',
            name:'',
            salesNetPrice:'',
            packingUnit:1,
        },

        //Dialogs
        showItemDialog:false,
        showDeleteItemDialog:false,
        showImportDataDialog:false
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            this.searchOnClick();
        }
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownItems = [];
            this.props.items.forEach(element => {
                if(element[this.state.searchType] && element[this.state.searchType].toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                    shownItems.push(element);
                }
            });
            this.setState({shownItems:shownItems});
        }else{
            this.props.showMessage(2,'Wählen Sie eine Suche aus');
        }
    }

    //Dialog actions
    showItemDialog = (isNewItem,item) => {
        this.setState({isNewItem:isNewItem,item:item,showItemDialog:true});
    }

    closeItemDialog = () => {
        this.setState({showItemDialog:false});
    }

    showDeleteItemDialog = (item) => {
        this.setState({item:item,showDeleteItemDialog:true});
    }

    closeDeleteItemDialog = () => {
        this.setState({showDeleteItemDialog:false});
    }

    showImportDataDialog = () => {
        this.setState({showImportDataDialog:true});
    }

    closeImportDataDialog = () => {
        this.setState({showImportDataDialog:false});
    }

    render() {

        const blankItem = {
            nr:'',
            name:'',
            salesNetPrice:'',
            count:1,
        }

        let searchTypes = [];
        let title = null;
        let itemName = null;

        switch (this.props.type) {
            case 0:
                searchTypes = [
                    {
                        value:'nr',
                        label:'Artikelnummer'
                    },
                    {
                        value:'name',
                        label:'Artikelname'
                    }
                ];
                title = 'Artikel';
                itemName = 'Artikel';
                break;
            case 1:
                searchTypes = [
                    {
                        value:'nr',
                        label:'Kundennummer'
                    },
                    {
                        value:'name',
                        label:'Kundenname'
                    }
                ];
                title = 'Kunden';
                itemName = 'Kunde';
                break;
            case 2:
                searchTypes = [
                    {
                        value:'nr',
                        label:'Lieferantennummer'
                    },
                    {
                        value:'name',
                        label:'Lieferantenname'
                    }
                ];
                title = 'Lieferanten';
                itemName = 'Lieferant';
                break;
            case 3:
                searchTypes = [
                    {
                        value:'nr',
                        label:'Handwerker'
                    },
                    {
                        value:'name',
                        label:'Handwerkername'
                    }
                ];
                title = 'Handwerker';
                itemName = 'Handwerker';
                break;
            default:
                break;
        }

        return (
            <div className='master-data-content'>
                <ReactTooltip/>

                <ItemDialog
                    open={this.state.showItemDialog}
                    close={this.closeItemDialog}
                    type={this.props.type}
                    addNewItem={this.props.addNewItem}
                    updateItem={this.props.updateItem}
                    showMessage={this.props.showMessage}
                    item={this.state.item}
                    isNewItem={this.state.isNewItem}
                    countries={this.props.countries}
                    suppliers={this.props.suppliers}
                />

                <YesCancelDialog
                    open={this.state.showDeleteItemDialog}
                    close={this.closeDeleteItemDialog}
                    header='Löschen'
                    text={'Wollen Sie den ' + itemName + ' wirklich löschen?'}
                    onClick={() => this.props.deleteItem(this.state.item)}
                />

                <ImportDataDialog
                    open={this.state.showImportDataDialog}
                    close={this.closeImportDataDialog}
                    addMultipleItems={this.props.addMultipleItems}
                    type={this.props.type}
                />

                <div className='header-bar'>
                    <h1>{title}</h1>
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-select' label='Suche'>
                        {searchTypes.map((searchType,index) => (
                            <MenuItem key={index} value={searchType.value}>
                                {searchType.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ToolButton className='search-button' main onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownItems.map((item,index) => (
                        <MasterDataItem
                            key={index}
                            item={item}
                            showItemDialog={this.showItemDialog}
                            deleteItem={() => this.showDeleteItemDialog(item)}
                        />
                    ))}
                </div>

                {this.props.type === 1 ?
                    <ToolFab onClick={this.showImportDataDialog} className='upload-fab' aria-label="add">
                        <UploadIcon />
                    </ToolFab> : null}

                <ToolFab className='add-fab' onClick={() => this.showItemDialog(true,blankItem)} aria-label="add">
                    <AddIcon />
                </ToolFab>
            </div>
        )
    }
}

export default MasterDataContent;