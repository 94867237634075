import React, {Component} from "react";
import './ActionsMenu.css';

//Components
import {NavLink, Route} from "react-router-dom";

class ActionsMenu extends Component {
    render() {
        return (
        <div className='actions-menu'>
            <Route path='/master-data'>
                <menu className='master-data-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/master-data'><h2>Artikel</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/master-data/customers'><h2>Kunden</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/master-data/suppliers'><h2>Lieferanten</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/master-data/craftsmen'><h2>Handwerker</h2></NavLink>
                </menu>
            </Route>
            <Route path='/appointment-management'>
                <menu className='appointment-management-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/appointment-management'><h2>Kundenanfragen</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/appointment-management/appointments'><h2>Termine</h2></NavLink>
                </menu>
            </Route>
            <Route path='/administration'>
                <menu className='administration-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/administration'><h2>Benutzerverwaltung</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/administration/teams'><h2>Teamverwaltung</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/administration/company'><h2>Unternehmensverwaltung</h2></NavLink>
                </menu>
            </Route>
            <Route path='/settings'>
                <menu className='settings-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/settings'><h2>Allgemein</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/settings/security'><h2>Sicherheit</h2></NavLink>
                </menu>
            </Route>
            </div>
        )
    }
}

export default ActionsMenu;