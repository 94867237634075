import React, {Component} from "react";
import './ApplicationDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from "react-tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";
import api from "../../../../../api";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";

class ApplicationDialog extends Component {

    state = {
        //Dialog actions
        showAcceptDialog:false,
        showDeclineDialog:false
    }

    acceptApplication = (application) => {
        const url = '/applications/accept';
        api.post(url,application)
            .then(response => {
                this.props.showMessage(0,"Der Bewerber wurde erfolgreich angenommen");
                this.props.removeApplicationFromList(application);
                this.props.addUserToList(response.data);
                this.props.searchOnClick();
                this.props.close();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    declineApplication = (application) => {
        const url = '/applications/decline';
        api.post(url,application)
            .then(response => {
                this.props.showMessage(0,"Der Bewerber wurde erfolgreich abgelehnt");
                this.props.removeApplicationFromList(application);
                this.props.close();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    displayEmployeeFileOnClickHandler = (fileStorageLocation) => {
        let pathFileRequest = {
            username: this.props.application.futureUsername,
            fileStorageLocation: fileStorageLocation
        }
        this.props.displayEmployeeFile(pathFileRequest);
    }

    showAcceptDialog = () => {
        this.setState({showAcceptDialog:true});
    }

    closeAcceptDialog = () => {
        this.setState({showAcceptDialog:false});
    }

    showDeclineDialog = () => {
        this.setState({showDeclineDialog:true});
    }

    closeDeclineDialog = () => {
        this.setState({showDeclineDialog:false});
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='application-dialog'>
                <ReactTooltip/>

                <YesCancelDialog
                    open={this.state.showAcceptDialog}
                    close={this.closeAcceptDialog}
                    showMessage={this.props.showMessage}
                    onClick={() => this.acceptApplication(this.props.application)}
                    header="Bewerbung annehmen"
                    text="Wollen Sie die Bewerbung wirklich annehmen?"
                />

                <YesCancelDialog
                    open={this.state.showDeclineDialog}
                    close={this.closeDeclineDialog}
                    showMessage={this.props.showMessage}
                    onClick={() => this.declineApplication(this.props.application)}
                    header="Bewerbung ablehnen"
                    text="Wollen Sie die Bewerbung wirklich ablehnen?"
                />
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle>Bewerbung</DialogTitle>
                <DialogContent>
                    {this.props.application && this.props.application.type === "distributor" ? <div>
                        <div>
                            <h2>Angaben zum Unternehmen</h2>
                            <TextField value={this.props.application.userInformation.companyName ? this.props.application.userInformation.companyName : ""} disabled className='field' label='Unternehmensname (inkl. Rechtsf.)'/>
                            <TextField value={this.props.application.userInformation.streetName ? this.props.application.userInformation.streetName : ""} disabled className='field' label='Straße'/>
                            <TextField value={this.props.application.userInformation.streetNr ? this.props.application.userInformation.streetNr : ""} disabled className='field' label='Hausnummer'/>
                            <TextField value={this.props.application.userInformation.addressAddition ? this.props.application.userInformation.addressAddition : ""} helperText='optional' disabled className='field' label='Adresszusatz'/>
                            <TextField value={this.props.application.userInformation.postCode ? this.props.application.userInformation.postCode : ""} disabled className='field' label='Postleitzahl'/>
                            <TextField value={this.props.application.userInformation.city ? this.props.application.userInformation.city : ""} disabled className='field' label='Ort'/>
                            <TextField value={this.props.application.userInformation.country ? this.props.application.userInformation.country : ""} disabled className='field' label='Land'>
                                {this.props.countries}
                            </TextField>
                            <TextField value={this.props.application.userInformation.taxNumber ? this.props.application.userInformation.taxNumber : ""} disabled className='field' label='Steuernummer'/>
                            <TextField value={this.props.application.userInformation.vatNumber ? this.props.application.userInformation.vatNumber : ""} disabled className='field' label='Umsatzsteuernummer'/>
                        </div>
                        <div>
                            <h2>Geschäftsinhaber</h2>
                            <TextField value={this.props.application.userInformation.salutation} disabled select className='field' label='Anrede'>
                                <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                            </TextField>
                            <TextField value={this.props.application.userInformation.firstName ? this.props.application.userInformation.firstName : ""} disabled className='field' label='Vorname'/>
                            <TextField value={this.props.application.userInformation.lastName ? this.props.application.userInformation.lastName : ""} disabled className='field' label='Nachname'/>
                            <TextField value={this.props.application.userInformation.birthDate ? this.props.application.userInformation.birthDate : ""} disabled type='date' className='field' label='Geburtsdatum'/>
                            <TextField value={this.props.application.userInformation.email ? this.props.application.userInformation.email : ""} disabled type='email' className='field' label='E-Mail'/>
                            <TextField value={this.props.application.userInformation.phone ? this.props.application.userInformation.phone : ""} disabled className='field' label='Telefon'/>
                            <TextField value={this.props.application.userInformation.jacketSize} disabled select className='field' label='Jacken Größe'>
                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                            </TextField>
                            <TextField value={this.props.application.userInformation.tshirtSize} disabled select className='field' label='T-Shirt Größe'>
                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                            </TextField>
                        </div>
                        <div>
                            <h2>Bankverbindung</h2>
                            <TextField value={this.props.application.userInformation.accountHolder ? this.props.application.userInformation.accountHolder : ""} disabled className='field' label='Kontoinhaber'/>
                            <TextField value={this.props.application.userInformation.iban ? this.props.application.userInformation.iban : ""} disabled className='field' label='IBAN'/>
                            <TextField value={this.props.application.userInformation.bic ? this.props.application.userInformation.bic : ""} disabled className='field' label='BIC'/>
                            <TextField value={this.props.application.userInformation.plannedEmploymentStart ? this.props.application.userInformation.plannedEmploymentStart : ""} disabled type='date' className='field' label='Geplanter Beschäftigungsstart'/>
                            <TextField value={this.props.application.userInformation.desiredSalary ? this.props.application.userInformation.desiredSalary : ""} disabled helperText='optional' type='number' className='field' label='Wunschgehalt (netto)'/>
                            <div className='field'>
                                <p>Bild</p>
                                <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.application.userInformation.pictureFileStorageLocation)}/>
                            </div>
                            <div className='field'>
                                <p>Personalausweis Vorderseite</p>
                                <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.application.userInformation.idFrontFileStorageLocation)}/>
                            </div>
                            <div className='field'>
                                <p>Personalausweis Rückseite</p>
                                <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.application.userInformation.idBackFileStorageLocation)}/>
                            </div>
                            <TextField value={this.props.application.userInformation.idNumber ? this.props.application.userInformation.idNumber : ""} disabled className='field' label='Personalausweis Nummer'/>
                            <div className='field'>
                                <p>Gewerbescheinauszug</p>
                                <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.application.userInformation.businessLicenceFileStorageLocation)}/>
                            </div>
                        </div>
                    </div> : this.props.application && this.props.application.type === "employee" ? <div>
                        <div>
                            <h2>Angaben zur Person</h2>
                            <TextField value={this.props.application.userInformation.salutation} disabled select className='field' label='Anrede'>
                                <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                            </TextField>
                            <TextField value={this.props.application.userInformation.firstName ? this.props.application.userInformation.firstName : ""} disabled className='field' label='Vorname'/>
                            <TextField value={this.props.application.userInformation.lastName ? this.props.application.userInformation.lastName : ""} disabled className='field' label='Nachname'/>
                            <TextField value={this.props.application.userInformation.streetName ? this.props.application.userInformation.streetName : ""} disabled className='field' label='Straße'/>
                            <TextField value={this.props.application.userInformation.streetNr ? this.props.application.userInformation.streetNr : ""} disabled className='field' label='Hausnummer'/>
                            <TextField value={this.props.application.userInformation.addressAddition ? this.props.application.userInformation.addressAddition : ""} helperText='optional' disabled className='field' label='Adresszusatz'/>
                            <TextField value={this.props.application.userInformation.postCode ? this.props.application.userInformation.postCode : ""} disabled className='field' label='Postleitzahl'/>
                            <TextField value={this.props.application.userInformation.city ? this.props.application.userInformation.city : ""} disabled className='field' label='Stadt'/>
                            <TextField value={this.props.application.userInformation.birthDate ? this.props.application.userInformation.birthDate : ""} disabled type='date' className='field' label='Geburtsdatum'/>
                            <TextField value={this.props.application.userInformation.nationality ? this.props.application.userInformation.nationality : ""} select disabled className='field' label='Staatsangehörigkeit'>
                                {this.props.countries}
                            </TextField>
                            <TextField value={this.props.application.userInformation.email ? this.props.application.userInformation.email : ""} disabled type='email' className='field' label='E-Mail'/>
                            <TextField value={this.props.application.userInformation.phone ? this.props.application.userInformation.phone : ""} disabled className='field' label='Telefon'/>
                            <TextField value={this.props.application.userInformation.jacketSize} disabled select className='field' label='Jacken Größe'>
                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                            </TextField>
                            <TextField value={this.props.application.userInformation.tshirtSize} disabled select className='field' label='T-Shirt Größe'>
                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                            </TextField>
                        </div>
                        <div className='field'>
                            <p>Bild</p>
                            <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.application.userInformation.pictureFileStorageLocation)}/>
                        </div>
                        <div className='field'>
                            <p>Personalausweis Vorderseite</p>
                            <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.application.userInformation.idFrontFileStorageLocation)}/>
                        </div>
                        <div className='field'>
                            <p>Personalausweis Rückseite</p>
                            <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.application.userInformation.idBackFileStorageLocation)}/>
                        </div>
                        <div>
                            <h2>Bankverbindung</h2>
                            <TextField value={this.props.application.userInformation.accountHolder ? this.props.application.userInformation.accountHolder : ""} disabled className='field' label='Kontoinhaber'/>
                            <TextField value={this.props.application.userInformation.iban ? this.props.application.userInformation.iban : ""} disabled className='field' label='IBAN'/>
                            <TextField value={this.props.application.userInformation.bic ? this.props.application.userInformation.bic : ""} disabled className='field' label='BIC'/>
                        </div>
                        <div>
                            <h2>Lohnsteuer</h2>
                            <TextField value={this.props.application.userInformation.taxId ? this.props.application.userInformation.taxId : ""} disabled className='field' label='Kontoinhaber'/>
                            <TextField value={this.props.application.userInformation.taxBracket ? this.props.application.userInformation.taxBracket : ""} disabled className='field' label='IBAN'/>
                            <TextField value={this.props.application.userInformation.childAllowance ? this.props.application.userInformation.childAllowance : ""} disabled className='field' label='BIC'/>
                            <FormControlLabel className='field' control={<Checkbox checked={this.props.application.userInformation.churchTax} disabled/>} label="Kirchensteuerpflichtig" />
                        </div>
                        <div>
                            <h2>Sozialversicherung</h2>
                            <TextField value={this.props.application.userInformation.healthInsurance ? this.props.application.userInformation.healthInsurance : ""} disabled className='field' label='Krankenkasse'/>
                            <TextField value={this.props.application.userInformation.insuranceNumber ? this.props.application.userInformation.insuranceNumber : ""} disabled className='field' label='Versichertennummer'/>
                            <TextField value={this.props.application.userInformation.insuranceStatus ? this.props.application.userInformation.insuranceStatus : ""} disabled select className='field' label='Versichertenstatus'>
                                <MenuItem key={0} value={'statutory'}>gesetzlich</MenuItem>
                                <MenuItem key={1} value={'private'}>privat</MenuItem>
                            </TextField>
                            <TextField value={this.props.application.userInformation.nationalInsuranceNumber ? this.props.application.userInformation.nationalInsuranceNumber : ""} disabled className='field' label='Sozialversicherungsnummer'/>
                            <FormControlLabel className='field' control={<Checkbox checked={this.props.hasChildren} disabled/>} label="Ich habe Kinder" />
                            {this.props.hasChildren ? <TextField value={this.props.application.userInformation.children ? this.props.application.userInformation.children.length : 0} type='number' disabled className='field' label='Anzahl Kinder'/> : null}
                            {this.props.application.userInformation.children ? this.props.application.userInformation.children.map((child,index) => (
                                <div className='field'>
                                    <h2>{index + 1}. Kind</h2>
                                    <TextField value={child.firstName} disabled className='field' label='Vorname'/>
                                    <TextField value={child.lastName} disabled className='field' label='Nachname'/>
                                    <TextField value={child.birthDate} type='date' disabled className='field' label='Geburtsdatum'/>
                                </div>
                            )) : null}
                        </div>
                    </div> : null}
                    <DialogContentText>
                        <p className='info-text'>
                            Nach dem Annehmen oder Ablehnen der Bewerbung erhält der Bewerber eine Benachrichtigung an die hinterlegte E-Mail Adresse.
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ToolButton className='button' main onClick={this.showAcceptDialog}>Annehmen</ToolButton>
                    <ToolButton className='button' onClick={this.showDeclineDialog}>Ablehnen</ToolButton>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ApplicationDialog;