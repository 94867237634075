import React, {Component} from "react";
import './Settings.css';

//Components
import {Route} from "react-router-dom";
import CommonSettings from "./CommonSettings/CommonSettings";
import SecuritySettings from "./SecuritySettings/SecuritySettings";

class Administration extends Component {
    render() {
        return (
            <div className='settings'>
                <Route exact path='/settings'>
                    <CommonSettings
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        company={this.props.company}

                        //Users
                        user={this.props.user}
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUserInList={this.props.updateUserInList}
                        addUserToList={this.props.addUserToList}
                        removeUserFromList={this.props.removeUserFromList}

                        //File
                        displayEmployeeFile={this.props.displayEmployeeFile}
                    />
                </Route>
                <Route exact path='/settings/security'>
                    <SecuritySettings
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}

                        //Users
                        user={this.props.user}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}
                    />
                </Route>
            </div>
        )
    }
}

export default Administration;