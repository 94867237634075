import React, {Component} from "react";
import './Reporting.css';

//Components
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@material-ui/icons/Info";
import api from "../../../../api";
import ReportDialog from "./ReportDialog/ReportDialog";
import ToolButton from "../../../../reusable/ToolButton/ToolButton";
import ConditionItem from "./ConditionItem/ConditionItem";

class Reporting extends Component {

    state = {
        reportRequest: {
            fromDate:'',
            toDate:'',
            reportConditions:[]
        },
        report:null,
        formattedResult:null,
        calculationData:{
            employees:null,
            monthlyProvisionCosts:null,
            monthlyRequests:null,
            monthlyRequestCosts:null,
            monthlyCosts:null,
            monthlyOrders:null,
            months:null,
            monthlyRecruitingCostsSum:null
        },

        // Dialog
        showReportDialog:false
    }

    calculateData = () => {
        if(this.props.company
            && this.props.company.orderCount
            && this.props.company.targetDate
            && this.props.company.orderProfit
            && this.props.company.revenueSum
            && this.props.company.orderCountPerEmployee
            && this.props.company.completionRate
            && this.props.company.requestCosts
            && this.props.company.monthlyRecruitingCosts
            && this.props.company.provision
        ) {
            let employees;
            let monthlyProvisionCosts;
            let monthlyRequests;
            let monthlyRequestCosts;
            let monthlyCosts;
            let monthlyOrders;
            let months;
            let monthlyRecruitingCostsSum;

            //calculate months
            let startDate = new Date();
            startDate.setDate(1);
            startDate.setMonth(startDate.getMonth() + 1);
            let endDate = new Date();
            let date = this.props.company.targetDate.substring(8,10)
            let month = this.props.company.targetDate.substring(5,7)
            let year = this.props.company.targetDate.substring(0,4)
            endDate.setFullYear(year);
            endDate.setMonth(month - 1);
            endDate.setDate(date);
            months = this.monthDifference(startDate,endDate) + 1;

            //calculate monthlyOrders
            monthlyOrders = this.props.company.orderCount / months;
            //calculate monthlyRequests
            monthlyRequests = monthlyOrders / (this.props.company.completionRate / 100);
            //calculate employees
            employees = monthlyOrders / this.props.company.orderCountPerEmployee;
            //calculate monthlyRequestCosts
            monthlyRequestCosts = monthlyRequests * this.props.company.requestCosts;
            //calculate monthlyRecruitingCostsSum
            monthlyRecruitingCostsSum = employees * this.props.company.monthlyRecruitingCosts;
            //calculate monthlyProvisionCosts
            monthlyProvisionCosts = monthlyOrders * this.props.company.provision;
            //calculate monthlyCosts
            monthlyCosts = monthlyProvisionCosts + monthlyRecruitingCostsSum + monthlyRequestCosts;
            let calculationData = {
                employees:employees,
                monthlyProvisionCosts:monthlyProvisionCosts,
                monthlyRequests:monthlyRequests,
                monthlyRequestCosts:monthlyRequestCosts,
                monthlyCosts:monthlyCosts,
                monthlyOrders:monthlyOrders,
                months:months,
                monthlyRecruitingCostsSum:monthlyRecruitingCostsSum
            }
            console.log(calculationData);
            this.setState({calculationData:calculationData});
        }
    }

    monthDifference = (startDate,endDate) => {
        let months;
        months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
        months -= startDate.getMonth();
        months += endDate.getMonth();
        return months <= 0 ? 0 : months;
    }

    //Handler

    fromDateOnChangeHandler = (e) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.fromDate = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    toDateOnChangeHandler = (e) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.toDate = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    addConditionOnClickHandler = () => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions.push({
            type:0,
            article:null,
            customer:null,
            applicationUser:null,
            operator:null
        });
        this.setState({reportRequest:reportRequest});
    }

    removeConditionOnClickHandler = (index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions.splice(index,1);
        this.setState({reportRequest:reportRequest});
    }

    reportRequestOnClickHandler = () => {
        if(this.state.reportRequest.fromDate !== '' && this.state.reportRequest.toDate !== '') {
            this.sendReportRequest(this.state.reportRequest);
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    sendReportRequest = (reportRequest) => {
        console.log(reportRequest)
        const url = '/report';
        api.post(url,reportRequest)
            .then(response => {
                this.showReportDialog(response.data);
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error.message);
            });
    }

    //Condition Handler

    typeOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].type = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    articleOnChangeHandler = (article,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].article = article;
        this.setState({reportRequest:reportRequest});
    }

    customerOnChangeHandler = (customer,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].customer = customer;
        this.setState({reportRequest:reportRequest});
    }

    userOnChangeHandler = (applicationUser,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].applicationUser = applicationUser;
        this.setState({reportRequest:reportRequest});
    }

    operatorOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].operator = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    statusOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].status = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    // Dialog actions

    showReportDialog = (report) => {
        this.calculateData();
        this.setState({report:report,showReportDialog:true});
    }

    closeReportDialog = () => {
        this.setState({showReportDialog:false});
    }

    render() {
        return (
            <div className='reporting'>
                <ReactTooltip/>

                <ReportDialog
                    open={this.state.showReportDialog}
                    close={this.closeReportDialog}
                    round={this.props.round}
                    report={this.state.report}
                    formattedReport={this.state.formattedReport}
                    formatDate={this.props.formatDate}
                    formatString={this.props.formatString}
                    calculationData={this.state.calculationData}

                    //Company
                    company={this.props.company}
                />

                <h1>Reporting</h1>
                <TextField className='date-input' type='date' helperText='Datum von' onChange={this.fromDateOnChangeHandler} />
                <TextField className='date-input' type='date' helperText='Datum bis' onChange={this.toDateOnChangeHandler} />
                <ToolButton className='add-attribute-button' onClick={this.addConditionOnClickHandler}>Bedingung hinzufügen</ToolButton>
                <p className='info-text'>Erstellt ein Reporting über alle Angebote, auf die jede der eingestellten Bedingungen zutreffen</p>
                <div className='condition-list'>
                    {this.state.reportRequest.reportConditions.map((condition, index) => (
                        <ConditionItem
                            key={index}
                            index={index}
                            condition={condition}

                            customers={this.props.customers}
                            users={this.props.users}
                            articles={this.props.articles}

                            removeConditionOnClickHandler={this.removeConditionOnClickHandler}
                            typeOnChangeHandler={this.typeOnChangeHandler}

                            customerOnChangeHandler={this.customerOnChangeHandler}
                            userOnChangeHandler={this.userOnChangeHandler}
                            articleOnChangeHandler={this.articleOnChangeHandler}
                            operatorOnChangeHandler={this.operatorOnChangeHandler}
                            statusOnChangeHandler={this.statusOnChangeHandler}
                        />
                    ))}
                </div>
                <ToolButton main className='report-button' onClick={this.reportRequestOnClickHandler}>Report generieren</ToolButton>
            </div>
        )
    }
}

export default Reporting;