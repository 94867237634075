import React, {Component} from 'react';
import './OfferManagement.css';

//Components
import MenuItem from '@material-ui/core/MenuItem';
import ToolButton from "../../../../reusable/ToolButton/ToolButton";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../reusable/ListItem/ListItem";
import api from "../../../../api";
import OfferDialog from "./OfferDialog/OfferDialog";
import ToolFab from "../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@material-ui/icons/Add";
import YesCancelDialog from "../../../../reusable/YesCancelDialog/YesCancelDialog";

class OfferManagement extends Component {

    state = {
        activeItem:null,
        shownOffers: [],
        searchWord:1,
        searchType:'status',
        selectedItem:null,
        action:null,
        formattedItems:[],

        //Dialogs
        showOfferDialog:false,
        showInformSuppliersDialog:false,
        showInformCraftsmanDialog:false
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        switch (e.target.value) {
            case "status":
                this.setState({searchWord:1,searchType:e.target.value});
                break;
            case "customerNr":
                this.setState({searchWord:"",searchType:e.target.value});
                break;
            case "customerName":
                this.setState({searchWord:"",searchType:e.target.value});
                break;
            default:
                this.setState({searchType:e.target.value});
                break;
        }
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownOffers = [];
            switch (this.state.searchType) {
                case "status":
                    this.props.offers.forEach(element => {
                        if(element.status === this.state.searchWord) {
                            shownOffers.push(element);
                        }
                    });
                    break;
                case "customerNr":
                    this.props.offers.forEach(element => {
                        if(element.customer && element.customer.nr && element.customer.nr.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownOffers.push(element);
                        }
                    });
                    break;
                case "customerName":
                    this.props.offers.forEach(element => {
                        if(element.customer && element.customer.name.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownOffers.push(element);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownOffers:shownOffers});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    createOffer = (offer) => {
        const url = '/offers';
        api.post(url,offer)
            .then(response => {
                this.props.showMessage(0,"Das Angebot wurde erfolgreich erstellt");
                this.props.addOfferToList(response.data);
                if(!offer.customer.id) {
                    this.props.addCustomerToList(response.data.customer);
                }
                this.setState({activeItem:response.data});
                this.searchOnClick();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateOffer = (offer) => {
        console.log(offer)
        const url = '/offers';
        api.put(url,offer)
            .then(response => {
                this.props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert");
                if(!offer.customer.id) {
                    this.props.addCustomerToList(response.data.customer);
                }
                this.setState({activeItem:response.data});
                this.searchOnClick();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteOffer = (offer) => {
        const url = '/offers/' + offer.id;
        api.delete(url)
            .then(response => {
                this.props.showMessage(0,"Das Angebot wurde erfolgreich gelöscht");
                this.searchOnClick();
                this.closeOfferDialog();
                this.setState({activeItem:null});
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    appendFile = (offer,multipartFile) => {
        let url = '/offers/append-file/offer';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.post(url + "/" + offer.id,formData)
            .then(response => {
                let activeItem = this.state.activeItem;
                activeItem.offerFileStorageLocation = response.data;
                this.setState({activeItem:activeItem});
                this.props.showMessage(0,'Datei erfolgreich angehängt');
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    removeFile = (offer) => {
        let url = "/offers/remove/offer"
        api.put(url,offer)
            .then(response => {
                let activeItem = this.state.activeItem;
                activeItem.offerFileStorageLocation = null;
                this.setState({activeItem:activeItem});
                this.props.showMessage(0,'Datei erfolgreich entfernt');
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    displayOfferFile = (offer) => {
        let url = '/offers/display-file/offer';
        api.post(url,offer,{responseType:'blob'})
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: response.data.type});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    sendOfferFileAsEmail = (offer) => {
        const url = '/offers/send-email/offer';
        api.post(url,offer)
            .then(response => {
                this.props.showMessage(0,"Die E-Mail wurde erfolgreich versandt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    informSuppliers = (offer) => {
        const url = '/offers/send-email/suppliers';
        api.put(url,offer)
            .then(response => {
                this.setState({activeItem:response.data});
                this.props.showMessage(0,"Die Lieferanten wurden erfolgreich informiert");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    informCraftsman = (offer) => {
        const url = '/offers/send-email/craftsman';
        api.put(url,offer)
            .then(response => {
                this.setState({activeItem:response.data});
                this.props.showMessage(0,"Der Handwerker wurde erfolgreich informiert");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    completeOffer = (offer) => {
        const url = '/offers/complete';
        api.put(url,offer)
            .then(response => {
                this.props.updateOfferInList(response.data);
                this.props.showMessage(0,"Das Angebot wurde erfolgreich abgeschlossen");
                this.closeOfferDialog();
                this.searchOnClick();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    cancelOffer = (offer) => {
        const url = '/offers/cancel';
        api.put(url,offer)
            .then(response => {
                this.props.updateOfferInList(response.data);
                this.props.showMessage(0,"Das Angebot wurde abgebrochen");
                this.closeOfferDialog();
                this.searchOnClick();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    reactivateOffer = (offer) => {
        const url = '/offers/reactivate';
        api.put(url,offer)
            .then(response => {
                this.props.updateOfferInList(response.data);
                this.props.showMessage(0,"Das Angebot wurde erfolgreich reaktiviert");
                this.closeOfferDialog();
                this.searchOnClick();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions

    showOfferDialog = (offer) => {
        this.setState({activeItem:offer,showOfferDialog:true});
    }

    closeOfferDialog = () => {
        this.setState({showOfferDialog:false});
    }

    showInformSuppliersDialog = () => {
        this.setState({showInformSuppliersDialog:true});
    }

    closeInformSuppliersDialog = () => {
        this.setState({showInformSuppliersDialog:false});
    }

    showInformCraftsmanDialog = () => {
        this.setState({showInformCraftsmanDialog:true});
    }

    closeInformCraftsmanDialog = () => {
        this.setState({showInformCraftsmanDialog:false});
    }

    render() {

        let blankOffer = {
            status:1,
            customer:{

            },
            documentPositions:[
                {
                    article:{

                    }
                }
            ]
        }

        return (
            <div className='applications'>
                <ReactTooltip/>

                <OfferDialog
                    open={this.state.showOfferDialog}
                    close={this.closeOfferDialog}
                    showMessage={this.props.showMessage}
                    offer={this.state.activeItem}
                    addOfferToList={this.props.addOfferToList}
                    removeOfferFromList={this.props.removeOfferFromList}
                    countries={this.props.countries}
                    customers={this.props.customers}
                    articles={this.props.articles}
                    createOffer={this.createOffer}
                    updateOffer={this.updateOffer}
                    user={this.props.user}
                    generateOffer={this.props.generateOffer}
                    appendFile={this.appendFile}
                    removeFile={this.removeFile}
                    displayOfferFile={this.displayOfferFile}
                    sendOfferFileAsEmail={this.sendOfferFileAsEmail}
                    showInformSuppliersDialog={this.showInformSuppliersDialog}
                    showInformCraftsmanDialog={this.showInformCraftsmanDialog}
                    completeOffer={this.completeOffer}
                    cancelOffer={this.cancelOffer}
                    reactivateOffer={this.reactivateOffer}

                    //Suppliers
                    suppliers={this.props.suppliers}
                    informSuppliers={this.informSuppliers}

                    //Customers
                    craftsmen={this.props.craftsmen}
                    informCraftsman={this.informCraftsman}
                />

                <YesCancelDialog
                    open={this.state.showInformSuppliersDialog}
                    close={this.closeInformSuppliersDialog}
                    showMessage={this.props.showMessage}
                    onClick={() => this.informSuppliers(this.state.activeItem)}
                    header={this.state.activeItem && this.state.activeItem.informedSuppliers ? "Lieferanten erneut Informieren" : "Lieferanten informieren"}
                    text={this.state.activeItem && this.state.activeItem.informedSuppliers ? "Die hinterlegten Lieferanten wurden bereits informiert! Wollen Sie sie wirklich erneut informieren?" : "Wollen Sie die hinterlegten Lieferanten informieren?"}
                />

                <YesCancelDialog
                    open={this.state.showInformCraftsmanDialog}
                    close={this.closeInformCraftsmanDialog}
                    showMessage={this.props.showMessage}
                    onClick={() => this.informCraftsman(this.state.activeItem)}
                    header={this.state.activeItem && this.state.activeItem.informedCraftsman ? "Handwerker erneut Informieren" : "Handwerker informieren"}
                    text={this.state.activeItem && this.state.activeItem.informedCraftsman ? "Der hinterlegte Handwerker wurde bereits informiert! Wollen Sie ihn wirklich erneut informieren?" : "Wollen Sie den hinterlegten Handwerker informieren?"}
                />

                <div className='header-bar'>
                    <h1>Angebote</h1>
                    {this.state.searchType !== "status" ? <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/> :
                    <TextField value={this.state.searchWord} onChange={this.searchWordOnChange} size='small' select className='search-input' label='Suche'>
                        <MenuItem key={1} value={1}>aktiv</MenuItem>
                        <MenuItem key={2} value={2}>abgeschlossen</MenuItem>
                        <MenuItem key={3} value={3}>abgebrochen</MenuItem>
                    </TextField>}
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-input' label='Suche'>
                        <MenuItem key={1} value={'status'}>Status</MenuItem>
                        <MenuItem key={2} value={'customerNr'}>Kundennummer</MenuItem>
                        <MenuItem key={3} value={'customerName'}>Kundenname</MenuItem>
                    </TextField>
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownOffers.sort((a,b) => a.date < b.date ? 1 : -1).map((offer,index) => (
                        <ListItem
                            key={index}
                            type={5}
                            item={offer}
                            showItemDialog={() => this.showOfferDialog(offer)}
                            showDeleteItemDialog={() => this.showDeleteOfferDialog(offer)}
                        />
                    ))}
                </div>
                <ToolFab className='add-fab' onClick={() => this.showOfferDialog(blankOffer)} aria-label="add">
                    <AddIcon/>
                </ToolFab>
            </div>
        )
    }
}

export default OfferManagement;