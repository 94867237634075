import React, {Component} from "react";
import './DocumentPosition.css';

//Components
import TextField from "@material-ui/core/TextField";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Paper from "@material-ui/core/Paper";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MenuItem from "@material-ui/core/MenuItem";

class DocumentPosition extends Component {

    render() {

        const filter = createFilterOptions();

        return (
            <Paper elevation={3} className='document-position'>
                {!this.props.disabled ? <CloseIcon onClick={() => this.props.removeDocumentPosition(this.props.index)} className='icon close-icon'/> : null}
                {
                    this.props.documentPosition.article && this.props.documentPosition.article.name ?
                        <p className='name'>{this.props.documentPosition.article ? this.props.documentPosition.article.name : ''}<CheckIcon className='check-icon'/></p> :
                        <p className='name inactive'>Unbekannter Artikel</p>
                }
                <Autocomplete
                    disabled={this.props.disabled}
                    value={this.props.documentPosition.article ? this.props.documentPosition.article.nr: ''}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            this.props.articleOnChange({
                                nr: newValue,
                            },this.props.index);
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            this.props.articleOnChange({
                                nr: newValue.inputValue,
                            },this.props.index);
                        } else {
                            this.props.articleOnChange(newValue,this.props.index);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.nr);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                nr: `Erstellen "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="articles"
                    className='position-field'
                    options={this.props.articles}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.nr;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.nr}</li>}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} helperText="Artikelnummer" />
                    )}
                />
                <TextField disabled={this.props.disabled} value={this.props.documentPosition ? this.props.documentPosition.count : ''} onChange={(e) => this.props.countOnChange(e,this.props.index)} className='position-field' helperText='Anzahl' type='number'/>
                <TextField disabled value={this.props.documentPosition ? this.props.documentPosition.articlePrice : ''} onChange={(e) => this.props.articlePriceOnChange(e,this.props.index)} className='position-field' helperText='Artikelpreis' type='number'/>
                <TextField disabled={this.props.disabled} value={this.props.documentPosition ? this.props.documentPosition.provision : ''} type='number' onChange={(e) => this.props.provisionOnChangeHandler(e,this.props.index)} className='position-field' helperText='Provision'/>
                <TextField disabled value={this.props.documentPosition ? this.props.documentPosition.price : ''} className='position-field' helperText='Nettopositionspreis' type='number'/>
            </Paper>
        )
    }
}

export default DocumentPosition;