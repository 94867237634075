import React, {Component} from 'react';
import './Footer.css';

//Components
import ImpressumDialog from "./ImpressumDialog/ImpressumDialog";
import PrivacyPolicyDialog from "./PrivacyPolicyDialog/PrivacyPolicyDialog";

class Footer extends Component {

    state = {
        showImpressumDialog:false,
        showPrivacyPolicyDialog:false
    }

    showImpressumDialog = () => {
        this.setState({showImpressumDialog:true});
    }

    closeImpressumDialog = () => {
        this.setState({showImpressumDialog:false});
    }

    showPrivacyPolicyDialog = () => {
        this.setState({showPrivacyPolicyDialog:true});
    }

    closePrivacyPolicyDialog = () => {
        this.setState({showPrivacyPolicyDialog:false});
    }

    render() {
        return (
            <div className='footer'>

                <ImpressumDialog
                    open={this.state.showImpressumDialog}
                    close={this.closeImpressumDialog}
                />

                <PrivacyPolicyDialog
                    open={this.state.showPrivacyPolicyDialog}
                    close={this.closePrivacyPolicyDialog}
                />

                <ul>
                    <li>ONE M</li>
                    <li>|</li>
                    <li className='pointer' onClick={this.showImpressumDialog}>Impressum</li>
                    <li>|</li>
                    <li className='pointer' onClick={this.showPrivacyPolicyDialog}>Datenschutz</li>
                </ul>
            </div>
        )
    }
}

export default Footer;
