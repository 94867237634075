import React, {Component} from "react";
import './ReportDialog.css';
import Dialog from "@material-ui/core/Dialog";

//Components
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserReport from "./UserReport/UserReport";
import ReactTooltip from "react-tooltip";

class ReportDialog extends Component {
    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='report-dialog'>
                <ReactTooltip/>
                <DialogTitle>Report von {this.props.report ? this.props.formatDate(this.props.report.fromDate) : ""} bis {this.props.report ? this.props.formatDate(this.props.report.toDate) : ""}</DialogTitle>
                {this.props.report ? <DialogContent>
                    {(this.props.calculationData &&
                        this.props.calculationData.employees &&
                        this.props.calculationData.monthlyProvisionCosts &&
                        this.props.calculationData.monthlyRequests &&
                        this.props.calculationData.monthlyRequestCosts &&
                        this.props.calculationData.monthlyCosts &&
                        this.props.calculationData.monthlyOrders &&
                        this.props.calculationData.months &&
                        this.props.calculationData.monthlyRecruitingCostsSum) ?
                        <div>
                            <p>Die folgenden Werte ergeben sich rein aus den Unternehmenszielen, definiert in der Unternehmensverwaltung<br/><br/></p>
                            <p data-tip="Monatliche Bestellungen / Aufträge pro Mitarbeiter">Benötigte Mitarbeiter: {this.props.round(this.props.calculationData.employees,2)}</p>
                            <p data-tip="Monatliche Aufträge / (Abschlussquote in Prozent / 100)">Monatliche Anfragen: {this.props.round(this.props.calculationData.monthlyRequests,2)}</p>
                            <p data-tip="Zielaufträge / Monate">Monatliche Aufträge: {this.props.round(this.props.calculationData.monthlyOrders,2)}</p>
                            <p data-tip="Monatliche Aufträge * Auftragsprovision">Monatliche Provisionskosten: {this.props.round(this.props.calculationData.monthlyProvisionCosts,2)} €</p>
                            <p data-tip="Benötigte Mitarbeiter * monatliche Provisionskosten">Monatliche Recruitingkosten: {this.props.round(this.props.calculationData.monthlyRecruitingCostsSum,2)} €</p>
                            <p data-tip="Monatliche Anfrage * Anfragekosten">Monatliche Anfragekosten: {this.props.round(this.props.calculationData.monthlyRequestCosts,2)} €</p>
                            <p data-tip="Monatliche Provisionskosten + monatliche Recruitingkosten + monatliche Anfragekosten">Monatliche Gesamtkosten: {this.props.round(this.props.calculationData.monthlyCosts,2)} €</p>
                        </div> :
                        <div>
                            <p>Wenn in der Unternehmensverwaltung alle Unternehmensziele angegeben sind, werden hier die entsprechenden Berechnungen angezeigt</p>
                        </div>}
                    <div>
                        <p><b>Gesamtumsatz: {this.props.report.sum} €</b></p>
                    </div>
                    {this.props.report ? this.props.report.userReports.map(userReport => (
                        <UserReport
                            userReport={userReport}
                            formatDate={this.props.formatDate}
                            formatString={this.props.formatString}

                            //Company
                            company={this.props.company}
                        />
                    )) : null}
                </DialogContent> : null}
            </Dialog>
        )
    }
}

export default ReportDialog;