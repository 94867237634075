import React, {Component} from "react";
import './AppointmentManagement.css';

//Components
import {Route} from "react-router-dom";
import CustomerRequestOverview from "./CustomerRequestOverview/CustomerRequestOverview";
import AppointmentOverview from "./AppointmentOverview/AppointmentOverview";

class AppointmentManagement extends Component {

    render() {
        return (
            <div className='appointment-management'>
                <Route exact path='/appointment-management'>
                    <CustomerRequestOverview
                        showMessage={this.props.showMessage}
                        user={this.props.user}
                        users={this.props.users}

                        //Customer Requests
                        customerRequests={this.props.customerRequests}
                        addCustomerRequestToList={this.props.addCustomerRequestToList}
                        removeCustomerRequestFromList={this.props.removeCustomerRequestFromList}

                        //Customers
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}

                        //Appointments
                        addAppointmentToList={this.props.addAppointmentToList}
                        removeAppointmentFromList={this.props.removeAppointmentFromList}
                    />
                </Route>
                <Route exact path='/appointment-management/appointments'>
                    <AppointmentOverview
                        showMessage={this.props.showMessage}
                        user={this.props.user}
                        countries={this.props.countries}

                        //Customers
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}

                        //Appointments
                        addAppointmentToList={this.props.addAppointmentToList}
                        removeAppointmentFromList={this.props.removeAppointmentFromList}
                    />
                </Route>
            </div>
        )
    }
}

export default AppointmentManagement;