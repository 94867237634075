import React, {Component} from 'react';
import './CustomerRequestOverview.css';

//Components
import MenuItem from '@material-ui/core/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import CustomerRequestDialog from "./CustomerRequestDialog/CustomerRequestDialog";
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@material-ui/icons/Add";
import api from "../../../../../api";
import PlanAppointmentDialog from "./CustomerRequestDialog/PlanAppointmentDialog/PlanAppointmentDialog";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";

class CustomerRequestOverview extends Component {

    state = {
        activeCustomerRequest:null,
        shownCustomerRequests: [],
        searchWord:'',
        searchType:'customerNr',

        //Dialogs
        showCustomerRequestDialog:false,
        showDeleteCustomerRequestDialog:false
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownCustomerRequests = [];
            switch (this.state.searchType) {
                case "customerNr":
                    this.props.customerRequests.forEach(element => {
                        if(element.customer && element.customer.nr && element.customer.nr.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownCustomerRequests.push(element);
                        }
                    });
                    break;
                case "customerName":
                    this.props.customerRequests.forEach(element => {
                        if(element.customer && element.customer.name.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownCustomerRequests.push(element);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownCustomerRequests:shownCustomerRequests});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    sendCustomerRequest = (customerRequest) => {
        const url = '/customer-requests';
        api.post(url,customerRequest)
            .then(response => {
                this.props.showMessage(0,"Die Kundenanfrage wurde erfolgreich verschickt");
                this.props.addCustomerRequestToList(response.data);
                if(!customerRequest.customer.id) {
                    this.props.addCustomerToList(response.data.customer);
                }
                this.closeCustomerRequestDialog();
                this.searchOnClick();
                this.setState({activeCustomerRequest:null});
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateCustomerRequest = (customerRequest) => {
        const url = '/customer-requests';
        api.put(url,customerRequest)
            .then(response => {
                this.props.showMessage(0,"Die Änderungen wurde erfolgreich gespeichert");
                if(!customerRequest.customer.id) {
                    this.props.addCustomerToList(response.data.customer);
                }
                this.closeCustomerRequestDialog();
                this.setState({activeCustomerRequest:null});
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteCustomerRequest = (customerRequest) => {
        const url = '/customer-requests/' + customerRequest.id;
        api.delete(url)
            .then(response => {
                this.props.showMessage(0,"Die Kundenanfrage wurde erfolgreich gelöscht");
                this.props.removeCustomerRequestFromList(customerRequest);
                this.searchOnClick();
                this.closeDeleteCustomerRequestDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    planAppointment = (appointmentRequest) => {
        const url = '/users/appointments';
        api.post(url,appointmentRequest)
            .then(response => {
                this.props.showMessage(0,"Der Termin wurde erfolgreich angelegt");
                this.props.removeCustomerRequestFromList(appointmentRequest.customerRequest);
                this.props.addAppointmentToList(response.data);
                this.searchOnClick();
                this.closeCustomerRequestDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions

    showCustomerRequestDialog = (customerRequest) => {
        this.setState({activeCustomerRequest:customerRequest,showCustomerRequestDialog:true});
    }

    closeCustomerRequestDialog = () => {
        this.setState({showCustomerRequestDialog:false});
    }

    showDeleteCustomerRequestDialog = (customerRequest) => {
        this.setState({activeCustomerRequest:customerRequest,showDeleteCustomerRequestDialog:true});
    }

    closeDeleteCustomerRequestDialog = () => {
        this.setState({showDeleteCustomerRequestDialog:false});
    }

    render() {

        let blankCustomerRequest = {
            customer:null,
            description:''
        }

        return (
            <div className='customer-request-overview'>
                <ReactTooltip/>

                <CustomerRequestDialog
                    open={this.state.showCustomerRequestDialog}
                    close={this.closeCustomerRequestDialog}
                    showMessage={this.props.showMessage}
                    customerRequest={this.state.activeCustomerRequest}
                    customers={this.props.customers}
                    addCustomerRequestToList={this.props.addCustomerRequestToList}
                    removeCustomerRequestFromList={this.props.removeCustomerRequestFromList}
                    user={this.props.user}
                    users={this.props.users}
                    sendCustomerRequest={this.sendCustomerRequest}
                    updateCustomerRequest={this.updateCustomerRequest}
                    planAppointment={this.planAppointment}
                />

                <PlanAppointmentDialog
                    open={this.state.showPlanAppointmentDialog}
                    close={this.closePlanAppointmentDialog}
                    showMessage={this.props.showMessage}
                    dateTimeOnChangeHandler={this.dateTimeOnChangeHandler}
                    dateTime={this.state.dateTime}
                    planAppointmentOnClickHandler={this.planAppointmentOnClickHandler}
                />

                <YesCancelDialog
                    open={this.state.showDeleteCustomerRequestDialog}
                    close={this.closeDeleteCustomerRequestDialog}
                    header='Löschen'
                    text={'Wollen Sie die Kundenanfrage wirklich löschen?'}
                    onClick={() => this.deleteCustomerRequest(this.state.activeCustomerRequest)}
                />

                <div className='header-bar'>
                    <h1>Kundenanfragen</h1>
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'customerNr'}>Kundennummer</MenuItem>
                        <MenuItem key={1} value={'customerName'}>Kundenname</MenuItem>
                    </TextField>
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownCustomerRequests.map((customerRequest,index) => (
                        <ListItem
                            user={this.props.user}
                            key={index}
                            type={3}
                            item={customerRequest}
                            showItemDialog={() => this.showCustomerRequestDialog(customerRequest)}
                            showDeleteItemDialog={() => this.showDeleteCustomerRequestDialog(customerRequest)}
                        />
                    ))}
                </div>
                {this.props.user && (this.props.user.rights === 3 || this.props.user.rights === 4) ? <ToolFab className='add-fab' onClick={() => this.showCustomerRequestDialog(blankCustomerRequest)} aria-label="add">
                    <AddIcon/>
                </ToolFab> : null}
            </div>
        )
    }
}

export default CustomerRequestOverview;