import React, {Component} from 'react';
import './CompanyDataReportItem.css';

//Components
import Paper from '@material-ui/core/Paper';
import ReactTooltip from "react-tooltip";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

class CompanyDataReportItem extends Component {

    state = {
        folded:true
    }

    toggleContent = () => {
        this.setState({folded:!this.state.folded});
    }

    render() {

        let detailedInformation = this.state.folded ?
            null :
            <div className='detailed-information'>
                <h3>Anzahl Benutzer: {this.props.companyDataReport.applicationUserCount}</h3>
                <h3>Anzahl Angebote: {this.props.companyDataReport.offerCount}</h3>
                <h3>Anzahl Kunden: {this.props.companyDataReport.customerCount}</h3>
                <h3>Anzahl Kundenanfragen: {this.props.companyDataReport.customerRequestCount}</h3>
                <h3>Anzahl Artikel: {this.props.companyDataReport.articleCount}</h3>
                <h3>Anzahl Handwerker: {this.props.companyDataReport.craftsmenCount}</h3>
                <h3>Anzahl Lieferanten: {this.props.companyDataReport.supplierCount}</h3>
            </div>

        return (
            <Paper elevation={3} className='company-data-report-item'>
                <ReactTooltip/>
                <div className='top-field'>
                    <h2 className='field'>{this.props.companyDataReport && this.props.companyDataReport.company && this.props.companyDataReport.company.name ? this.props.companyDataReport.company.name : "Unbekannter Unternehmensname"}</h2>
                    {this.state.folded ?
                        <KeyboardArrowRightIcon onClick={this.toggleContent} className='arrow-icon'/> :
                        <KeyboardArrowDownIcon onClick={this.toggleContent} className='arrow-icon' />}
                </div>
                {detailedInformation}
            </Paper>
        )
    }
}

export default CompanyDataReportItem;