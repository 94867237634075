import React, {Component} from 'react';
import './App.css';

//Components
import Tool from './components/Tool/Tool';
import Login from './components/Login/Login';
import Footer from "./components/Footer/Footer";
import api from './api';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import MenuItem from "@material-ui/core/MenuItem";

toast.configure();

class App extends Component {

    state = {
        loggedIn: localStorage.getItem('token')!== null,
        usernameInput: '',
        passwordInput: '',
        abbreviationInput: localStorage.getItem('abbreviation'),
        username:localStorage.getItem('username')
    };

    //General actions

    showMessage = (kind,message) => {
        if(kind === 0) {
            toast.success(message,{position:toast.POSITION.BOTTOM_CENTER,autoClose:3000});
        }else if(kind === 1) {
            toast.warning(message,{position:toast.POSITION.BOTTOM_CENTER,autoClose:3000});
        }else if(kind === 2) {
            toast.error(message,{position:toast.POSITION.BOTTOM_CENTER,autoClose:3000});
        }
    };

    //Login functions

    loginUsernameChangeHandler = (e) => {
        const username = e.target.value;
        this.setState({usernameInput:username});
    };

    loginPasswordChangeHandler = (e) => {
        const password = e.target.value;
        this.setState({passwordInput:password});
    };

    loginAbbreviationChangeHandler = (e) => {
        const abbreviation = e.target.value;
        this.setState({abbreviationInput:abbreviation});
    };

    loginClickHandler = () => {
        if(this.state.usernameInput !== '' && this.state.passwordInput !== '') {
            this.login();
        }else{
            this.showMessage(2,'Bitte füllen Sie alle Felder aus');
        }
    }

    login = () => {
        const data = {
            username:this.state.abbreviationInput + "." + this.state.usernameInput,
            password:this.state.passwordInput
        };
        api.post("/login",data)
            .then(response => {
                localStorage.setItem('abbreviation',this.state.abbreviationInput);
                localStorage.setItem('username',this.state.usernameInput);
                localStorage.setItem('token',response.headers.authorization);
                this.setState({loggedIn:true});
            })
            .catch(error => {
                if(error.response) {
                    this.showMessage(2,"Unternehmenskürzel, Benutzername und Passwort stimmen nicht über ein");
                    console.log(error.response)
                } else if (error.request) {
                    console.log("2")
                    this.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    console.log("3")
                    this.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    registerClickHandler = () => {
        this.showMessage(2,'Nicht verfügbar')
    };

    logout = () => {
        if(true) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            window.location.reload(false);
        }
    }

    render() {

        let countries = [
            <MenuItem value='Deutschland'>Deutschland</MenuItem>,<MenuItem value='USA'>USA</MenuItem>,<MenuItem value='Frankreich'>Frankreich</MenuItem>,<MenuItem value='England'>England</MenuItem>,<MenuItem value='Niederlande'>Niederlande</MenuItem>,
            <MenuItem value='China'>China</MenuItem>,<MenuItem value='Österreich'>Österreich</MenuItem>,<MenuItem value='italien'>Italien</MenuItem>,<MenuItem value='Polen'>Polen</MenuItem>,<MenuItem value='Schweiz'>Schweiz</MenuItem>,
            <MenuItem value='Belgien'>Belgien</MenuItem>,<MenuItem value='Spanien'>Spanien</MenuItem>,<MenuItem value='Tschechien'>Tschechien</MenuItem>,<MenuItem value='Schweden'>Schweden</MenuItem>,<MenuItem value='Türkei'>Türkei</MenuItem>,
            <MenuItem value='Ungarn'>Ungarn</MenuItem>,<MenuItem value='---'>---</MenuItem>,<MenuItem value='Afghanistan'>Afghanistan</MenuItem>,<MenuItem value='Ägypten'>Ägypten</MenuItem>,<MenuItem value='Albanien'>Albanien</MenuItem>,<MenuItem value='Algerien'>Algerien</MenuItem>,<MenuItem value='Andorra'>Andorra</MenuItem>,
            <MenuItem value='Angola'>Angola</MenuItem>,<MenuItem value='Antigua und Barbuda'>Antigua und Barbuda</MenuItem>,<MenuItem value='Äquatorialguinea'>Äquatorialguinea</MenuItem>,<MenuItem value='Argentinien'>Argentinien</MenuItem>,<MenuItem value='Armenien'>Armenien</MenuItem>,
            <MenuItem value='Aserbaidschan'>Aserbaidschan</MenuItem>,<MenuItem value='Äthiopien'>Äthiopien</MenuItem>,<MenuItem value='Australien'>Australien</MenuItem>,<MenuItem value='Bahamas'>Bahamas</MenuItem>,<MenuItem value='Bahrain'>Bahrain</MenuItem>,
            <MenuItem value='Bangladesch'>Bangladesch</MenuItem>,<MenuItem value='Barbados'>Barbados</MenuItem>,<MenuItem value='Belarus'>Belarus</MenuItem>,<MenuItem value='Belgien'>Belgien</MenuItem>,<MenuItem value='Belize'>Belize</MenuItem>,
            <MenuItem value='Benin'>Benin</MenuItem>,<MenuItem value='Bhutan'>Bhutan</MenuItem>,<MenuItem value='Bolivien'>Bolivien</MenuItem>,<MenuItem value='Bosnien und Herzegowina'>Bosnien und Herzegowina</MenuItem>,<MenuItem value='Botsuana'>Botsuana</MenuItem>,
            <MenuItem value='Brasilien'>Brasilien</MenuItem>,<MenuItem value='Brunei Darussalam'>Brunei Darussalam</MenuItem>,<MenuItem value='Bulgarien'>Bulgarien</MenuItem>,<MenuItem value='Burkina Faso'>Burkina Faso</MenuItem>,<MenuItem value='Burundi'>Burundi</MenuItem>,
            <MenuItem value='Cabo Verde'>Cabo Verde</MenuItem>,<MenuItem value='Chile'>Chile</MenuItem>,<MenuItem value='China'>China</MenuItem>,<MenuItem value='Cookinseln'>Cookinseln</MenuItem>,<MenuItem value='Costa Rica'>Costa Rica</MenuItem>,
            <MenuItem value='Cote d Ivoire'>Cote d Ivoire</MenuItem>,<MenuItem value='Dänemark'>Dänemark</MenuItem>,<MenuItem value='Deutschland'>Deutschland</MenuItem>,<MenuItem value='Dominica'>Dominica</MenuItem>,<MenuItem value='Dominikanische Republik'>Dominikanische Republik</MenuItem>,
            <MenuItem value='Dschibuti'>Dschibuti</MenuItem>,<MenuItem value='Ecuador'>Ecuador</MenuItem>,<MenuItem value='El Salvador'>El Salvador</MenuItem>,<MenuItem value='England'>England</MenuItem>,<MenuItem value='Eritrea'>Eritrea</MenuItem>,
            <MenuItem value='Estland'>Estland</MenuItem>,<MenuItem value='Eswatini'>Eswatini</MenuItem>,<MenuItem value='Fidschi'>Fidschi</MenuItem>,<MenuItem value='Finnland'>Finnland</MenuItem>,<MenuItem value='Frankreich'>Frankreich</MenuItem>,
            <MenuItem value='Gabun'>Gabun</MenuItem>,<MenuItem value='Gambia'>Gambia</MenuItem>,<MenuItem value='Georgien'>Georgien</MenuItem>,<MenuItem value='Ghana'>Ghana</MenuItem>,<MenuItem value='Grenada'>Grenada</MenuItem>,
            <MenuItem value='Griechenland'>Griechenland</MenuItem>,<MenuItem value='Guatemala'>Guatemala</MenuItem>,<MenuItem value='Guinea'>Guinea</MenuItem>,<MenuItem value='Guinea-Bissau'>Guinea-Bissau</MenuItem>,<MenuItem value='Guyana'>Guyana</MenuItem>,
            <MenuItem value='Haiti'>Haiti</MenuItem>,<MenuItem value='Heiliger Stuhl'>Heiliger Stuhl</MenuItem>,<MenuItem value='Honduras'>Honduras</MenuItem>,<MenuItem value='Indien'>Indien</MenuItem>,<MenuItem value='Indonesien'>Indonesien</MenuItem>,
            <MenuItem value='Irak'>Irak</MenuItem>,<MenuItem value='Iran'>Iran</MenuItem>,<MenuItem value='Irland'>Irland</MenuItem>,<MenuItem value='Island'>Island</MenuItem>,<MenuItem value='Israel'>Israel</MenuItem>,
            <MenuItem value='Italien'>Italien</MenuItem>,<MenuItem value='Jamaika'>Jamaika</MenuItem>,<MenuItem value='Japan'>Japan</MenuItem>,<MenuItem value='Jemen'>Jemen</MenuItem>,<MenuItem value='Jordanien'>Jordanien</MenuItem>,
            <MenuItem value='Kambodscha'>Kambodscha</MenuItem>,<MenuItem value='Kamerun'>Kamerun</MenuItem>,<MenuItem value='Kanada'>Kanada</MenuItem>,<MenuItem value='Kasachstan'>Kasachstan</MenuItem>,<MenuItem value='Katar'>Katar</MenuItem>,
            <MenuItem value='Kenia'>Kenia</MenuItem>,<MenuItem value='Kirgisistan'>Kirgisistan</MenuItem>,<MenuItem value='Kiribati'>Kiribati</MenuItem>,<MenuItem value='Kolumbien'>Kolumbien</MenuItem>,<MenuItem value='Komoren'>Komoren</MenuItem>,
            <MenuItem value='Kongo'>Kongo</MenuItem>,<MenuItem value='Korea'>Korea</MenuItem>,<MenuItem value='Kosovo'>Kosovo</MenuItem>,<MenuItem value='Kroatien'>Kroatien</MenuItem>,<MenuItem value='Kuba'>Kuba</MenuItem>,
            <MenuItem value='Kuwait'>Kuwait</MenuItem>,<MenuItem value='Laos'>Laos</MenuItem>,<MenuItem value='Lesotho'>Lesotho</MenuItem>,<MenuItem value='Lettland'>Lettland</MenuItem>,<MenuItem value='Libanon'>Libanon</MenuItem>,
            <MenuItem value='Liberia'>Liberia</MenuItem>,<MenuItem value='Libyen'>Libyen</MenuItem>,<MenuItem value='Liechtenstein'>Liechtenstein</MenuItem>,<MenuItem value='Litauen'>Litauen</MenuItem>,<MenuItem value='Luxemburg'>Luxemburg</MenuItem>,
            <MenuItem value='Madagaskar'>Madagaskar</MenuItem>,<MenuItem value='Malawi'>Malawi</MenuItem>,<MenuItem value='Malaysia'>Malaysia</MenuItem>,<MenuItem value='Malediven'>Malediven</MenuItem>,<MenuItem value='Mali'>Mali</MenuItem>,
            <MenuItem value='Malta'>Malta</MenuItem>,<MenuItem value='Marokko'>Marokko</MenuItem>,<MenuItem value='Marshallinseln'>Marshallinseln</MenuItem>,<MenuItem value='Mauretanien'>Mauretanien</MenuItem>,<MenuItem value='Mexiko'>Mexiko</MenuItem>,
            <MenuItem value='Mikronesien'>Mikronesien</MenuItem>,<MenuItem value='Moldau'>Moldau</MenuItem>,<MenuItem value='Monaco'>Monaco</MenuItem>,<MenuItem value='Mongolei'>Mongolei</MenuItem>,<MenuItem value='Montenegro'>Montenegro</MenuItem>,
            <MenuItem value='Mosambik'>Mosambik</MenuItem>,<MenuItem value='Namibia'>Namibia</MenuItem>,<MenuItem value='Nauru'>Nauru</MenuItem>,<MenuItem value='Nepal'>Nepal</MenuItem>,<MenuItem value='Neuseeland'>Neuseeland</MenuItem>,
            <MenuItem value='Nicaragua'>Nicaragua</MenuItem>,<MenuItem value='Niederlande'>Niederlande</MenuItem>,<MenuItem value='Niger'>Niger</MenuItem>,<MenuItem value='Nigeria'>Nigeria</MenuItem>,<MenuItem value='Niue'>Niue</MenuItem>,
            <MenuItem value='Nordmazedonien'>Nordmazedonien</MenuItem>,<MenuItem value='Norwegen'>Norwegen</MenuItem>,<MenuItem value='Oman'>Oman</MenuItem>,<MenuItem value='Österreich'>Österreich</MenuItem>,<MenuItem value='Pakistan'>Pakistan</MenuItem>,
            <MenuItem value='Palau'>Palau</MenuItem>,<MenuItem value='Panama'>Panama</MenuItem>,<MenuItem value='Papua-Neuguinea'>Papua-Neuguinea</MenuItem>,<MenuItem value='Paraguay'>Paraguay</MenuItem>,<MenuItem value='Peru'>Peru</MenuItem>,
            <MenuItem value='Philippinen'>Philippinen</MenuItem>,<MenuItem value='Polen'>Polen</MenuItem>,<MenuItem value='Portugal'>Portugal</MenuItem>,<MenuItem value='Ruanda'>Ruanda</MenuItem>,<MenuItem value='Rumänien'>Rumänien</MenuItem>,
            <MenuItem value='Russland'>Russland</MenuItem>,<MenuItem value='Salomonen'>Salomonen</MenuItem>,<MenuItem value='Sambia'>Sambia</MenuItem>,<MenuItem value='Samoa'>Samoa</MenuItem>,<MenuItem value='San Marino'>San Marino</MenuItem>,
            <MenuItem value='Sao Tome und Principe'>São Tomé und Príncipe</MenuItem>,<MenuItem value='Saudi-Arabien'>Saudi-Arabien</MenuItem>,<MenuItem value='Schottland'>Schottland</MenuItem>,<MenuItem value='Schweden'>Schweden</MenuItem>,<MenuItem value='Schweiz'>Schweiz</MenuItem>,
            <MenuItem value='Senegal'>Senegal</MenuItem>,<MenuItem value='Serbien'>Serbien</MenuItem>,<MenuItem value='Seychellen'>Seychellen</MenuItem>,<MenuItem value='Sierra Leone'>Sierra Leone</MenuItem>,<MenuItem value='Simbabwe'>Simbabwe</MenuItem>,
            <MenuItem value='Singapur'>Singapur</MenuItem>,<MenuItem value='Slowakei'>Slowakei</MenuItem>,<MenuItem value='Slowenien'>Slowenien</MenuItem>,<MenuItem value='Somalia'>Somalia</MenuItem>,<MenuItem value='Spanien'>Spanien</MenuItem>,
            <MenuItem value='Sri Lanka'>Sri Lanka</MenuItem>,<MenuItem value='St Kitts und Nevis'>St. Kitts und Nevis</MenuItem>,<MenuItem value='St Lucia'>St. Lucia</MenuItem>,<MenuItem value='St Vincent und die Grenadinen'>St. Vincent und die Grenadinen</MenuItem>,<MenuItem value='Südafrika'>Südafrika</MenuItem>,
            <MenuItem value='Sudan'>Sudan</MenuItem>,<MenuItem value='Südsudan'>Südsudan</MenuItem>,<MenuItem value='Suriname'>Suriname</MenuItem>,<MenuItem value='Syrien'>Syrien</MenuItem>,<MenuItem value='Tadschikistan'>Tadschikistan</MenuItem>,
            <MenuItem value='Tansania'>Tansania</MenuItem>,<MenuItem value='Thailand'>Thailand</MenuItem>,<MenuItem value='Timor-Leste'>Timor-Leste</MenuItem>,<MenuItem value='Togo'>Togo</MenuItem>,<MenuItem value='Tonga'>Tonga</MenuItem>,
            <MenuItem value='Trinidad und Tobago'>Trinidad und Tobago</MenuItem>,<MenuItem value='Tschad'>Tschad</MenuItem>,<MenuItem value='Tschechien'>Tschechien</MenuItem>,<MenuItem value='Tunesien'>Tunesien</MenuItem>,<MenuItem value='Türkei'>Türkei</MenuItem>,
            <MenuItem value='Turkmenistan'>Turkmenistan</MenuItem>,<MenuItem value='Tuvalu'>Tuvalu</MenuItem>,<MenuItem value='Uganda'>Uganda</MenuItem>,<MenuItem value='Ukraine'>Ukraine</MenuItem>,<MenuItem value='Ungarn'>Ungarn</MenuItem>,
            <MenuItem value='Uruguay'>Uruguay</MenuItem>,<MenuItem value='USA'>USA</MenuItem>,<MenuItem value='Usbekistan'>Usbekistan</MenuItem>,<MenuItem value='Vanuatu'>Vanuatu</MenuItem>,<MenuItem value='Vatikanstadt'>Vatikanstadt</MenuItem>,
            <MenuItem value='Venezuela'>Venezuela</MenuItem>,<MenuItem value='Vereinigte Arabische Emirate'>Vereinigte Arabische Emirate</MenuItem>,<MenuItem value='Vietnam'>Vietnam</MenuItem>,<MenuItem value='Wales'>Wales</MenuItem>,<MenuItem value='Weißrussland'>Weißrussland</MenuItem>,
            <MenuItem value='Zentralafrikanische Republik'>Zentralafrikanische Republik</MenuItem>,<MenuItem value='Zypern'>Zypern</MenuItem>
        ]

        let content;
        if(localStorage.getItem('token') !== null) {
            //show tool
            content = <Tool
                showMessage={this.showMessage}
                username={this.state.usernameInput}
                logout={this.logout}
                countries={countries}
            />;
        }else{
            //show login
            content = <Login
                showMessage={this.showMessage}

                loginUsernameChangeHandler={this.loginUsernameChangeHandler}
                loginPasswordChangeHandler={this.loginPasswordChangeHandler}
                loginAbbreviationChangeHandler={this.loginAbbreviationChangeHandler}
                loginClick={this.loginClickHandler}
                registerClick={this.registerClickHandler}
                countries={countries}
            />;
        }

        return (
            <Router>
                <div className='App'>
                    <ReactTooltip/>
                    <div className='app-content'>
                        {content}
                    </div>
                    <Footer/>
                </div>
            </Router>
        )
    }

}

export default App;
