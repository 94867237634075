import React, {Component} from "react";
import './UserMenu.css';

//Components

class UserMenu extends Component {

    state = {
        showMenuPanel:false
    }

    showSettings = () => {
        this.toggleMenuPanel();
        this.props.showSettings();
    }

    toggleMenuPanel = () => {
        this.setState({showMenuPanel:!this.state.showMenuPanel});
    }

    render() {
        let menuPanel = null;
        if(this.state.showMenuPanel) {
            menuPanel =
                <ul className='MenuPanel'>
                    <li onClick={this.props.logout}>Logout</li>
                </ul>
        }

        return (
            <div className='UserMenu'>
                <h2 className='UsernameText' onClick={this.toggleMenuPanel}>{this.props.user ? this.props.user.username : 'Lädt...'}</h2>
                {menuPanel}
            </div>
        )
    }
}

export default UserMenu;