import React, {Component} from 'react';
import './TeamAdministration.css';

//Components
import MenuItem from '@material-ui/core/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import TeamDialog from "./TeamDialog/TeamDialog";
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@material-ui/icons/Add";
import api from "../../../../../api";

class TeamAdministration extends Component {

    state = {
        team:null,
        availableEmployees:[],
        availableTeamLeaders:[],
        shownTeams: [],
        searchWord:'',
        searchType:'name',
        selectedItem:null,
        action:null,
        isNewItem:true,
        formattedItems:[],
        isSaved:true,

        //Dialogs
        showTeamDialog:false
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownTeams = [];
            switch (this.state.searchType) {
                case "name":
                    this.props.teams.forEach(element => {
                        if(element.name && element.name.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTeams.push(element);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownTeams:shownTeams});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    //User on Change Handler

    nameOnChangeHandler = (e) => {
        let team = this.state.team
        team.name = e.target.value;
        this.setState({team:team});
    }

    teamLeaderOnChangeHandler = (teamLeader) => {
        let team = this.state.team;
        team.teamLeader = teamLeader;
        this.setState({team:team});
    }

    addTeamMemberOnClickHandler = () => {
        let team = this.state.team;
        team.employees.push({});
        this.setState({team:team});
    }

    changeEmployeeOnClickHandler = (index,employee) => {
        let team = this.state.team;
        team.employees[index] = employee;
        this.setState({team:team});
    }

    updateAvailableTeamLeaders = () => {
        let availableTeamLeaders = [];
        for(let i = 0; i < this.props.users.length; i++) {
            if(this.props.users[i].rights === 2) {
                availableTeamLeaders.push(this.props.users[i]);
            }
        }
        this.setState({availableTeamLeaders:availableTeamLeaders});
    }

    updateAvailableEmployees = () => {
        let availableEmployees = [];
        for(let i = 0; i < this.props.users.length; i++) {
            if(this.props.users[i].rights === 1) {
                availableEmployees.push(this.props.users[i]);
            }
        }
        this.setState({availableEmployees:availableEmployees});
    }

    saveTeamOnClickHandler = (team) => {
        if(team.name && team.teamLeader && team.employees && team.employees.length > 0) {
            for(let i = 0; i < team.employees.length; i++) {
                if(team.employees[i] && team.employees[i].id) {
                    for(let j = 0; j < team.employees.length; j++) {
                        if(team.employees[i].id === team.employees[j].id && i !== j) {
                            this.props.showMessage(2,"Fehlende oder falsche Werte")
                            return;
                        }
                    }
                }else{
                    this.props.showMessage(2,"Fehlende oder falsche Werte")
                    return;
                }
            }
            if(team.id) {
                this.updateTeam(team);
            }else{
                this.createNewTeam(team);
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte")
        }
    }

    createNewTeam = (team) => {
        const url = '/teams';
        api.post(url,team)
            .then(response => {
                this.props.showMessage(0,"Das Team wurde erfolgreich erstellt");
                this.props.addTeamToList(response.data);
                this.searchOnClick();
                this.closeTeamDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateTeam = (team) => {
        const url = '/teams';
        api.put(url,team)
            .then(response => {
                this.props.showMessage(0,"Das Team wurde erfolgreich erstellt");
                this.searchOnClick();
                this.closeTeamDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions

    showTeamDialog = (team) => {
        this.updateAvailableTeamLeaders();
        this.updateAvailableEmployees();
        this.setState({team:team,showTeamDialog:true});
    }

    closeTeamDialog = () => {
        this.setState({showTeamDialog:false});
    }

    render() {

        let blankTeam = {
            name:'',
            teamLeader:null,
            employees:[

            ]
        }

        return (
            <div className='team-administration'>
                <ReactTooltip/>

                <TeamDialog
                    open={this.state.showTeamDialog}
                    close={this.closeTeamDialog}
                    showMessage={this.props.showMessage}

                    //Users
                    currentUser={this.props.user}
                    availableEmployees={this.state.availableEmployees}
                    availableTeamLeaders={this.state.availableTeamLeaders}

                    //Teams
                    team={this.state.team}
                    updateTeam={this.updateTeam}
                    addTeamToList={this.props.addTeamToList}
                    removeTeamFromList={this.props.removeTeamFromList}
                    nameOnChangeHandler={this.nameOnChangeHandler}
                    teamLeaderOnChangeHandler={this.teamLeaderOnChangeHandler}
                    addTeamMemberOnClickHandler={this.addTeamMemberOnClickHandler}
                    changeEmployeeOnClickHandler={this.changeEmployeeOnClickHandler}
                    saveTeamOnClickHandler={this.saveTeamOnClickHandler}
                />

                <div className='header-bar'>
                    <h1>Teamverwaltung</h1>
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'name'}>Teamname</MenuItem>
                    </TextField>
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownTeams.map((team,index) => (
                        <ListItem
                            key={index}
                            type={6}
                            item={team}
                            showItemDialog={() => this.showTeamDialog(team)}
                        />
                    ))}
                </div>
                {this.props.user && this.props.user.rights === 4 ?
                    <ToolFab className='add-fab' onClick={() => this.showTeamDialog(blankTeam)} aria-label="add">
                        <AddIcon/>
                    </ToolFab> : null}
            </div>
        )
    }
}

export default TeamAdministration;