import React, {Component} from "react";
import './MasterData.css';
import {Route} from 'react-router-dom';

//Components
import MasterDataContent from "./MasterDataContent/MasterDataContent";

class MasterData extends Component {
    render() {
        return (
            <div className='master-data'>
                <Route exact path='/master-data'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.articles}
                        addNewItem={this.props.addNewArticle}
                        updateItem={this.props.updateArticle}
                        deleteItem={this.props.deleteArticle}
                        suppliers={this.props.suppliers}
                        type={0} // = articles
                    />
                </Route>
                <Route exact path='/master-data/customers'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        items={this.props.customers}
                        addNewItem={this.props.addNewCustomer}
                        updateItem={this.props.updateCustomer}
                        deleteItem={this.props.deleteCustomer}
                        addMultipleItems={this.props.addMultipleCustomers}
                        type={1} // = customers
                    />
                </Route>
                <Route exact path='/master-data/suppliers'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        items={this.props.suppliers}
                        addNewItem={this.props.addNewSupplier}
                        updateItem={this.props.updateSupplier}
                        deleteItem={this.props.deleteSupplier}
                        type={2} // = suppliers
                    />
                </Route>
                <Route exact path='/master-data/craftsmen'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        items={this.props.craftsmen}
                        addNewItem={this.props.addNewCraftsman}
                        updateItem={this.props.updateCraftsman}
                        deleteItem={this.props.deleteCraftsman}
                        type={3} // = craftsmen
                    />
                </Route>
            </div>
        )
    }
}

export default MasterData;