import React, {Component} from "react";
import './Dashboard.css';

//Components
import Button from "@material-ui/core/Button";
import api from "../../../../api";
import ToolButton from "../../../../reusable/ToolButton/ToolButton";

class Dashboard extends Component {

    state = {
        file:null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.user !== prevProps.user) {
            this.render();
        }
    }

    fileOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 1048576) {
            this.setState({file:file});
        }else{
            this.props.showMessage(2,"Die Datei darf maximal 1MB groß sein");
        }
    }

    uploadContractOnClickHandler = () => {
        if(this.state.file) {
            this.uploadContract(this.state.file);
        }else{
            this.props.showMessage(2,"Keine Datei ausgewählt");
        }
    }

    uploadContract = (multipartFile) => {
        let url = '/users/upload-contract';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.updateContractFileStorageLocation(response.data);
                this.props.showMessage(0,"Datei erfolgreich hochgeladen");
                this.setState({file:null});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    render() {
        return (
            <div className='dashboard'>
                <h1>Willkommen {this.props.user !== null ? this.props.user.username : 'Lädt...'}!</h1>
                {this.props.user && !this.props.user.approved ? <div>
                    {this.props.user.contractFileStorageLocation ? <div>
                        <p>Ihre Unterlagen werden aktuell geprüft. Ihr Account wird in Kürze freigeschaltet werden.</p>
                    </div> : <div>
                        <p>Laden Sie jetzt den unterschriebenen Vertrag hoch, um den letzten Bewerbungsschritt abzuschließen!</p>
                        <Button main variant="contained" type='main' component="label" className='field button' onChange={this.fileOnChangeHandler}>
                            {this.state.file ? this.state.file.name : "Datei auswählen"}
                            <input type="file" hidden accept="*"/>
                        </Button>
                        <ToolButton main className='field' onClick={this.uploadContractOnClickHandler}>Hochladen</ToolButton>
                    </div>}
                </div> : null}
            </div>
        )
    }
}

export default Dashboard;