import React, {Component} from 'react';
import './UserDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogContentText from "@material-ui/core/DialogContentText";
import EmailIcon from "@mui/icons-material/Email";
import ReactTooltip from "react-tooltip";
import ChangeUserPasswordDialog from "./ChangeUserPasswordDialog/ChangeUserPasswordDialog";

class UserDialog extends Component {

    state = {
        showChangeUserPasswordDialog:false
    }

    //On Change Handler

    displayEmployeeFileOnClickHandler = (contractFileStorageLocation) => {
        let employeeFileRequest = {
            username: this.props.user.username,
            fileStorageLocation: contractFileStorageLocation
        }
        this.props.displayEmployeeFile(employeeFileRequest);
    }

    createNewUserOnClickHandler = (user) => {
        if(user.username && user.password && user.rights) {
            if(this.props.user.id) {
                this.props.updateUser(user);
            }else{
                this.props.createNewUser(user);
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    // Dialog actions

    showChangeUserPasswordDialog = () => {
        this.setState({showChangeUserPasswordDialog:true});
    }

    closeChangeUserPasswordDialog = () => {
        this.setState({showChangeUserPasswordDialog:false});
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='user-dialog'>

                <ChangeUserPasswordDialog
                    open={this.state.showChangeUserPasswordDialog}
                    close={this.closeChangeUserPasswordDialog}
                    showMessage={this.props.showMessage}
                    currentUser={this.props.currentUser}
                    user={this.props.user}
                />

                <ReactTooltip/>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle><h1 className='item-name'>Benutzer</h1></DialogTitle>
                <DialogContent>
                    <h2>Anmeldung</h2>
                    <div>
                        <TextField disabled={this.props.user ? this.props.user.id : false} value={this.props.user && this.props.user.username ? this.props.user.username : ""} onChange={this.props.usernameOnChangeHandler} className='field' label='Benutzername'/>
                        {this.props.user && !this.props.user.id ? <TextField disabled={this.props.user ? this.props.user.id : false} value={this.props.user && this.props.user.password ? this.props.user.password : ""} onChange={this.props.passwordOnChangeHandler} type='password' className='field' label='Password'/> : null}
                        {this.props.user ? <TextField value={this.props.user && this.props.user.rights ? this.props.user.rights : ""} onChange={this.props.rightsOnChangeHandler} select className='field' label='Rechte'>
                            <MenuItem key={1} value={1}>Mitarbeiter</MenuItem>
                            <MenuItem disabled={this.props.currentUser.rights < 2} key={2} value={2}>Teamleiter</MenuItem>
                            <MenuItem disabled={this.props.currentUser.rights < 3} key={3} value={3}>Personaler</MenuItem>
                            <MenuItem disabled={this.props.currentUser.rights < 4} key={4} value={4}>Geschäftsführer</MenuItem>
                        </TextField> : null}
                        {this.props.user && (this.props.user.rights === 1 || this.props.user.rights === 2) ? <TextField value={this.props.user.type} onChange={this.props.typeOnChangeHandler} select className='field' label='Mitarbeitertyp'>
                            <MenuItem disabled={this.props.currentUser.rights < 3} key={1} value="distributor">Vertriebspartner</MenuItem>
                            <MenuItem disabled={this.props.currentUser.rights < 3} key={2} value="employee">Angestellter</MenuItem>
                        </TextField> : null}
                    </div>
                    {this.props.user && this.props.user.contractFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.user.contractFileStorageLocation)}/> : this.props.user && this.props.user.id ? <p>Der Benutzer hat keinen Vertrag hochgeladen</p> : null}
                    {this.props.user && this.props.user.id ?
                        <div>
                            <h2>Aktionen</h2>
                            {this.props.currentUser && (this.props.currentUser.rights === 3 || this.props.currentUser.rights === 4) && this.props.user.userInformation && this.props.user.userInformation.email ? <EmailIcon onClick={this.props.showSendWarningDialog} data-tip="Verwarnung an die hinterlegte E-Mail versenden" className='icon mail-icon'/> : <EmailIcon style={{color:"gray"}} data-tip="Der Benutzer hat keine E-Mail hinterlegt" className='icon mail-icon'/>}
                            {this.props.currentUser && (this.props.currentUser.rights === 3 || this.props.currentUser.rights === 4) && this.props.user.userInformation && this.props.user.userInformation.email ? <EmailIcon onClick={this.props.showSendWarningNoticeDialog} data-tip="Abmahnung an die hinterlegte E-Mail versenden" className='icon mail-icon'/> : <EmailIcon style={{color:"gray"}} data-tip="Der Benutzer hat keine E-Mail hinterlegt" className='icon mail-icon'/>}
                            {this.props.currentUser && this.props.currentUser.rights === 4 && this.props.user.userInformation && this.props.user.userInformation.email ? <EmailIcon onClick={this.props.showSendTerminationDialog} data-tip="Kündigung an die hinterlegte E-Mail versenden" className='icon mail-icon'/> : <EmailIcon style={{color:"gray"}} data-tip="Der Benutzer hat keine E-Mail hinterlegt" className='icon mail-icon'/>}
                        </div> : null}
                    {this.props.user && this.props.user.userInformation ? <div>
                        {this.props.user.type === "distributor" ? <div>
                            <div>
                                <h2>Angaben zum Unternehmen</h2>
                                <TextField value={this.props.user.userInformation.companyName ? this.props.user.userInformation.companyName : ""} disabled className='field' label='Unternehmensname (inkl. Rechtsf.)'/>
                                <TextField value={this.props.user.userInformation.streetName ? this.props.user.userInformation.streetName : ""} disabled className='field' label='Straße'/>
                                <TextField value={this.props.user.userInformation.streetNr ? this.props.user.userInformation.streetNr : ""} disabled className='field' label='Hausnummer'/>
                                <TextField value={this.props.user.userInformation.addressAddition ? this.props.user.userInformation.addressAddition : ""} disabled className='field' label='Adresszusatz'/>
                                <TextField value={this.props.user.userInformation.postCode ? this.props.user.userInformation.postCode : ""} disabled className='field' label='Postleitzahl'/>
                                <TextField value={this.props.user.userInformation.city ? this.props.user.userInformation.city : ""} disabled className='field' label='Ort'/>
                                <TextField value={this.props.user.userInformation.country ? this.props.user.userInformation.country : ""} disabled className='field' label='Land'>
                                    {this.props.countries}
                                </TextField>
                                <TextField value={this.props.user.userInformation.taxNumber ? this.props.user.userInformation.taxNumber : ""} disabled className='field' label='Steuernummer'/>
                                <TextField value={this.props.user.userInformation.vatNumber ? this.props.user.userInformation.vatNumber : ""} disabled className='field' label='Umsatzsteuernummer'/>
                            </div>
                            <div>
                                <h2>Geschäftsinhaber</h2>
                                <TextField value={this.props.user.userInformation.salutation} disabled select className='field' label='Anrede'>
                                    <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                    <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                                </TextField>
                                <TextField value={this.props.user.userInformation.firstName ? this.props.user.userInformation.firstName : ""} disabled className='field' label='Vorname'/>
                                <TextField value={this.props.user.userInformation.lastName ? this.props.user.userInformation.lastName : ""} disabled className='field' label='Nachname'/>
                                <TextField value={this.props.user.userInformation.birthDate ? this.props.user.userInformation.birthDate : ""} disabled type='date' className='field' label='Geburtsdatum'/>
                                <TextField value={this.props.user.userInformation.email ? this.props.user.userInformation.email : ""} disabled type='email' className='field' label='E-Mail'/>
                                <TextField value={this.props.user.userInformation.phone ? this.props.user.userInformation.phone : ""} disabled className='field' label='Telefon'/>
                                <TextField value={this.props.user.userInformation.jacketSize} disabled select className='field' label='Jacken Größe'>
                                    <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                    <MenuItem key={1} value={'s'}>S</MenuItem>
                                    <MenuItem key={2} value={'m'}>M</MenuItem>
                                    <MenuItem key={3} value={'l'}>L</MenuItem>
                                    <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                    <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                </TextField>
                                <TextField value={this.props.user.userInformation.tshirtSize} disabled select className='field' label='T-Shirt Größe'>
                                    <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                    <MenuItem key={1} value={'s'}>S</MenuItem>
                                    <MenuItem key={2} value={'m'}>M</MenuItem>
                                    <MenuItem key={3} value={'l'}>L</MenuItem>
                                    <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                    <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                </TextField>
                            </div>
                            <div>
                                <h2>Bankverbindung</h2>
                                <TextField value={this.props.user.userInformation.accountHolder ? this.props.user.userInformation.accountHolder : ""} disabled className='field' label='Kontoinhaber'/>
                                <TextField value={this.props.user.userInformation.iban ? this.props.user.userInformation.iban : ""} disabled className='field' label='IBAN'/>
                                <TextField value={this.props.user.userInformation.bic ? this.props.user.userInformation.bic : ""} disabled className='field' label='BIC'/>
                                <TextField value={this.props.user.userInformation.plannedEmploymentStart ? this.props.user.userInformation.plannedEmploymentStart : ""} disabled type='date' className='field' label='Geplanter Beschäftigungsstart'/>
                                <TextField value={this.props.user.userInformation.desiredSalary ? this.props.user.userInformation.desiredSalary : ""} disabled type='number' className='field' label='Wunschgehalt (netto)'/>
                                <div className='field'>
                                    <p>Bild</p>
                                    {this.props.user && this.props.user.userInformation && this.props.user.userInformation.pictureFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.user.userInformation.pictureFileStorageLocation)}/> : <p>-</p>}
                                </div>
                                <div className='field'>
                                    <p>Personalausweis Vorderseite</p>
                                    {this.props.user && this.props.user.userInformation && this.props.user.userInformation.idFrontFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.user.userInformation.idFrontFileStorageLocation)}/> : <p>-</p>}
                                </div>
                                <div className='field'>
                                    <p>Personalausweis Rückseite</p>
                                    {this.props.user && this.props.user.userInformation && this.props.user.userInformation.idBackFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.user.userInformation.idBackFileStorageLocation)}/> : <p>-</p>}
                                </div>
                                <TextField value={this.props.user.userInformation.idNumber ? this.props.user.userInformation.idNumber : ""} disabled className='field' label='Personalausweis Nummer'/>
                                <div className='field'>
                                    <p>Gewerbescheinauszug</p>
                                    {this.props.user && this.props.user.userInformation && this.props.user.userInformation.businessLicenceFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.user.userInformation.businessLicenceFileStorageLocation)}/> : <p>-</p>}
                                </div>
                            </div>
                        </div> : <div>
                            <div>
                                <h2>Angaben zur Person</h2>
                                <TextField value={this.props.user.userInformation.salutation} disabled select className='field' label='Anrede'>
                                    <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                    <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                                </TextField>
                                <TextField value={this.props.user.userInformation.firstName ? this.props.user.userInformation.firstName : ""} disabled className='field' label='Vorname'/>
                                <TextField value={this.props.user.userInformation.lastName ? this.props.user.userInformation.lastName : ""} disabled className='field' label='Nachname'/>
                                <TextField value={this.props.user.userInformation.streetName ? this.props.user.userInformation.streetName : ""} disabled className='field' label='Straße'/>
                                <TextField value={this.props.user.userInformation.streetNr ? this.props.user.userInformation.streetNr : ""} disabled className='field' label='Hausnummer'/>
                                <TextField value={this.props.user.userInformation.addressAddition ? this.props.user.userInformation.addressAddition : ""} disabled className='field' label='Adresszusatz'/>
                                <TextField value={this.props.user.userInformation.postCode ? this.props.user.userInformation.postCode : ""} disabled className='field' label='Nachname'/>
                                <TextField value={this.props.user.userInformation.city ? this.props.user.userInformation.city : ""} disabled className='field' label='Nachname'/>
                                <TextField value={this.props.user.userInformation.birthDate ? this.props.user.userInformation.birthDate : ""} disabled type='date' className='field' label='Geburtsdatum'/>
                                <TextField value={this.props.user.userInformation.nationality ? this.props.user.userInformation.nationality : ""} select disabled className='field' label='Staatsangehörigkeit'>
                                    {this.props.countries}
                                </TextField>
                                <TextField value={this.props.user.userInformation.email ? this.props.user.userInformation.email : ""} disabled type='email' className='field' label='E-Mail'/>
                                <TextField value={this.props.user.userInformation.phone ? this.props.user.userInformation.phone : ""} disabled className='field' label='Telefon'/>
                                <TextField value={this.props.user.userInformation.jacketSize} disabled select className='field' label='Jacken Größe'>
                                    <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                    <MenuItem key={1} value={'s'}>S</MenuItem>
                                    <MenuItem key={2} value={'m'}>M</MenuItem>
                                    <MenuItem key={3} value={'l'}>L</MenuItem>
                                    <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                    <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                </TextField>
                                <TextField value={this.props.user.userInformation.tshirtSize} disabled select className='field' label='T-Shirt Größe'>
                                    <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                    <MenuItem key={1} value={'s'}>S</MenuItem>
                                    <MenuItem key={2} value={'m'}>M</MenuItem>
                                    <MenuItem key={3} value={'l'}>L</MenuItem>
                                    <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                    <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                </TextField>
                            </div>
                            <div className='field'>
                                <p>Bild</p>
                                {this.props.user && this.props.user.userInformation && this.props.user.userInformation.pictureFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.user.userInformation.pictureFileStorageLocation)}/> : <p>-</p>}
                            </div>
                            <div className='field'>
                                <p>Personalausweis Vorderseite</p>
                                {this.props.user && this.props.user.userInformation && this.props.user.userInformation.idFrontFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.user.userInformation.idFrontFileStorageLocation)}/> : <p>-</p>}
                            </div>
                            <div className='field'>
                                <p>Personalausweis Rückseite</p>
                                {this.props.user && this.props.user.userInformation && this.props.user.userInformation.idBackFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.props.user.userInformation.idBackFileStorageLocation)}/> : <p>-</p>}
                            </div>
                            <div>
                                <h2>Bankverbindung</h2>
                                <TextField value={this.props.user.userInformation.accountHolder ? this.props.user.userInformation.accountHolder : ""} disabled className='field' label='Kontoinhaber'/>
                                <TextField value={this.props.user.userInformation.iban ? this.props.user.userInformation.iban : ""} disabled className='field' label='IBAN'/>
                                <TextField value={this.props.user.userInformation.bic ? this.props.user.userInformation.bic : ""} disabled className='field' label='BIC'/>
                            </div>
                            <div>
                                <h2>Lohnsteuer</h2>
                                <TextField value={this.props.user.userInformation.taxId ? this.props.user.userInformation.taxId : ""} disabled className='field' label='Kontoinhaber'/>
                                <TextField value={this.props.user.userInformation.taxBracket ? this.props.user.userInformation.taxBracket : ""} disabled className='field' label='IBAN'/>
                                <TextField value={this.props.user.userInformation.childAllowance ? this.props.user.userInformation.childAllowance : ""} disabled className='field' label='BIC'/>
                                <FormControlLabel className='field' control={<Checkbox checked={this.props.user.userInformation.churchTax} disabled/>} label="Kirchensteuerpflichtig" />
                            </div>
                            <div>
                                <h2>Sozialversicherung</h2>
                                <TextField value={this.props.user.userInformation.healthInsurance ? this.props.user.userInformation.healthInsurance : ""} disabled className='field' label='Krankenkasse'/>
                                <TextField value={this.props.user.userInformation.insuranceNumber ? this.props.user.userInformation.insuranceNumber : ""} disabled className='field' label='Versichertennummer'/>
                                <TextField value={this.props.user.userInformation.insuranceStatus ? this.props.user.userInformation.insuranceStatus : ""} disabled select className='field' label='Versichertenstatus'>
                                    <MenuItem key={0} value={'statutory'}>gesetzlich</MenuItem>
                                    <MenuItem key={1} value={'private'}>privat</MenuItem>
                                </TextField>
                                <TextField value={this.props.user.userInformation.nationalInsuranceNumber ? this.props.user.userInformation.nationalInsuranceNumber : ""} disabled className='field' label='Sozialversicherungsnummer'/>
                                <FormControlLabel className='field' control={<Checkbox checked={this.props.hasChildren} disabled/>} label="Ich habe Kinder" />
                                {this.props.hasChildren ? <TextField value={this.props.user.userInformation.children ? this.props.user.userInformation.children.length : 0} type='number' disabled className='field' label='Anzahl Kinder'/> : null}
                                {this.props.user.userInformation.children ? this.props.user.userInformation.children.map((child,index) => (
                                    <div className='field'>
                                        <h2>{index + 1}. Kind</h2>
                                        <TextField value={child.firstName} disabled className='field' label='Vorname'/>
                                        <TextField value={child.lastName} disabled className='field' label='Nachname'/>
                                        <TextField value={child.birthDate} type='date' disabled className='field' label='Geburtsdatum'/>
                                    </div>
                                )) : null}
                            </div>
                        </div>}
                    </div> : null}

                </DialogContent>
                {this.props.user && !this.props.user.approved && this.props.user.id ? <DialogContentText>
                    <p className='info-text'>
                        Nach dem Freischalten erhält der Mitarbeiter eine Benachrichtigung an die hinterlegte E-Mail Adresse.
                    </p>
                </DialogContentText> : null}
                <DialogActions>
                    {this.props.user && this.props.user.id && this.props.currentUser && this.props.currentUser.rights === 4 ? <ToolButton className='button' onClick={this.showChangeUserPasswordDialog}>Passwort ändern</ToolButton> : null}
                    {this.props.user && !this.props.user.approved && this.props.user.id ? <ToolButton className='button' onClick={() => this.props.approveUser(this.props.user)}>Freischalten</ToolButton> : null}
                    <ToolButton className='button' main onClick={() => this.createNewUserOnClickHandler(this.props.user)}>Speichern</ToolButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default UserDialog;