import React, {Component} from 'react';
import './CompanyAdministration.css';

//Components
import TextField from "@material-ui/core/TextField";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import ClearIcon from '@material-ui/icons/Clear';
import api from "../../../../../api";
import Button from "@material-ui/core/Button";
class CompanyAdministration extends Component {

    state = {
        company:this.props.company
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({company:this.props.company});
        }
    }

    updateCompany = (company) => {
        const url = '/company';
        api.put(url,company)
            .then(response => {
                this.props.showMessage(0,"Die Unternehmensinformationen wurden erfolgreich gespeichert");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    logoOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 1048576) {
            this.fileToDataUri(file)
                .then(dataUri => {
                        console.log(dataUri)
                        let company = this.state.company;
                        company.companyInformation.logo = dataUri;
                        this.setState({company:company});
                    }
                );
        }else{
            this.props.showMessage(2,"Das Logo darf maximal 1MB groß sein");
        }
    }

    fileToDataUri = (file) => new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            resolve(e.target.result);
        };
        fileReader.readAsDataURL(file);
    })

    deleteLogoOnClickHandler = () => {
        let company = this.state.company;
        company.companyInformation.logo = null;
        this.setState({company:company});
    }

    streetNameOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.streetName = e.target.value;
        this.setState({company:company});
    }

    streetNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.streetNr = e.target.value;
        this.setState({company:company});
    }

    addressAdditionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.addressAddition = e.target.value;
        this.setState({company:company});
    }

    postCodeOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.postCode = e.target.value;
        this.setState({company:company});
    }

    cityOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.city = e.target.value;
        this.setState({company:company});
    }

    countryOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.country = e.target.value;
        this.setState({company:company});
    }

    websiteOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.website = e.target.value;
        this.setState({company:company});
    }

    emailOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.email = e.target.value;
        this.setState({company:company});
    }

    phoneNumberOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.phoneNumber = e.target.value;
        this.setState({company:company});
    }

    defaultContactPersonOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.defaultContactPerson = e.target.value;
        this.setState({company:company});
    }

    defaultContactEmailOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.defaultContactEmail = e.target.value;
        this.setState({company:company});
    }

    defaultContactPhoneNumberOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.defaultContactPhoneNumber = e.target.value;
        this.setState({company:company});
    }

    bicOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.bic = e.target.value;
        this.setState({company:company});
    }

    ibanOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.iban = e.target.value;
        this.setState({company:company});
    }

    taxIdOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.taxId = e.target.value;
        this.setState({company:company});
    }

    taxNrOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.taxNr = e.target.value;
        this.setState({company:company});
    }

    commercialCourtLocationOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.commercialCourtLocation = e.target.value;
        this.setState({company:company});
    }

    companyRegistrationNumberOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.companyRegistrationNumber = e.target.value;
        this.setState({company:company});
    }

    chiefExecutiveOfficerOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.chiefExecutiveOfficer = e.target.value;
        this.setState({company:company});
    }

    defaultVatOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.defaultVat = e.target.value;
        this.setState({company:company});
    }

    revenueSumOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.revenueSum = e.target.value;
        this.setState({company:company});
    }

    orderProfitOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.orderProfit = e.target.value;
        this.setState({company:company});
    }

    orderCountOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.orderCount = e.target.value;
        this.setState({company:company});
    }

    targetDateOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.targetDate = e.target.value;
        this.setState({company:company});
    }

    orderCountPerEmployeeOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.orderCountPerEmployee = e.target.value;
        this.setState({company:company});
    }

    completionRateOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.completionRate = e.target.value;
        this.setState({company:company});
    }

    requestCostsOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.requestCosts = e.target.value;
        this.setState({company:company});
    }

    monthlyRecruitingCostsOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.monthlyRecruitingCosts = e.target.value;
        this.setState({company:company});
    }

    provisionOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.provision = e.target.value;
        this.setState({company:company});
    }

    maxCustomerRequestCountOnChangeHandler = (e) => {
        let company = this.state.company;
        company.companyInformation.maxCustomerRequestCount = e.target.value;
        this.setState({company:company});
    }

    render() {
        return (
            <div className='company-administration'>
                <h1>Unternehmensverwaltung</h1>
                {this.state.company ? <div className='surrounder'>
                        <h2>Allgemein</h2>
                        <Button variant="contained" type='main' component="label" className='file-button button' onChange={this.logoOnChangeHandler}>
                            Logo auswählen
                            <input type="file" hidden accept="*"/>
                        </Button>
                        {this.state.company.companyInformation.logo ? <img className='logo-preview' src={this.state.company.companyInformation.logo} alt="Company Logo"/> : null}
                        {this.state.company.companyInformation.logo ? <ClearIcon onClick={this.deleteLogoOnClickHandler} className='clear-icon'/> : null}
                        <h2>Adresse</h2>
                        <TextField disabled={!this.state.company} value={this.state.company ? this.state.company.companyInformation.streetName : ''} onChange={this.streetNameOnChangeHandler} label='Straße' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.streetNr : ''} onChange={this.streetNrOnChangeHandler} label='Hausnummer' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.addressAddition : ''} onChange={this.addressAdditionOnChangeHandler} label='Adresszusatz' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.postCode : ''} onChange={this.postCodeOnChangeHandler} label='Postleitzahl' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.city : ''} onChange={this.cityOnChangeHandler} label='Stadt' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.country : ''} className='input' select label='Land' onChange={this.countryOnChangeHandler}>
                            {this.props.countries}
                        </TextField>
                        <h2>Kontakt</h2>
                        <TextField value={this.state.company ? this.state.company.companyInformation.website : ''} onChange={this.websiteOnChangeHandler} label='Webseite' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.email : ''} onChange={this.emailOnChangeHandler} label='E-Mail' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.phoneNumber : ''} onChange={this.phoneNumberOnChangeHandler} label='Telefonnummer' className='input'/>
                        <h2>Kommunikation</h2>
                        <TextField value={this.state.company ? this.state.company.companyInformation.defaultContactPerson : ''} onChange={this.defaultContactPersonOnChangeHandler} label='Name' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.defaultContactEmail : ''} onChange={this.defaultContactEmailOnChangeHandler} label='E-Mail' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.defaultContactPhoneNumber : ''} onChange={this.defaultContactPhoneNumberOnChangeHandler} label='Telefonnummer' className='input'/>
                        <h2>Rechnungsinformationen</h2>
                        <TextField value={this.state.company ? this.state.company.companyInformation.bic : ''} onChange={this.bicOnChangeHandler} label='Bankleitzahl' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.iban : ''} onChange={this.ibanOnChangeHandler} label='IBAN' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.taxId : ''} onChange={this.taxIdOnChangeHandler} label='USt-ID' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.taxNr : ''} onChange={this.taxNrOnChangeHandler} label='Steuernummer' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.commercialCourtLocation : ''} onChange={this.commercialCourtLocationOnChangeHandler} label='Handelsgericht Ort' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.companyRegistrationNumber : ''} onChange={this.companyRegistrationNumberOnChangeHandler} label='Handelsreg. Nr.' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.chiefExecutiveOfficer : ''} onChange={this.chiefExecutiveOfficerOnChangeHandler} label='Geschäftsführung' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.defaultVat : ''} onChange={this.defaultVatOnChangeHandler} label='Standard MwSt.' className='input' type='number'/>
                        <h2>Unternehmensziele</h2>
                        <TextField value={this.state.company ? this.state.company.companyInformation.orderCount : ''} onChange={this.orderCountOnChangeHandler} type='number' label='Zielaufträge' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.targetDate : ''} onChange={this.targetDateOnChangeHandler} type='date' label='Zieldatum' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.orderProfit : ''} onChange={this.orderProfitOnChangeHandler} type='number' label='Auftragsgewinn' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.revenueSum : ''} onChange={this.revenueSumOnChangeHandler} type='number' label='Zielumsatz' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.orderCountPerEmployee : ''} onChange={this.orderCountPerEmployeeOnChangeHandler} type='number' label='Aufträge pro Mitarbeiter' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.completionRate : ''} onChange={this.completionRateOnChangeHandler} type='number' label='Abschlussquote in Prozent' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.requestCosts : ''} onChange={this.requestCostsOnChangeHandler} type='number' label='Anfragekosten' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.monthlyRecruitingCosts : ''} onChange={this.monthlyRecruitingCostsOnChangeHandler} type='number' label='Monatliche Recruitingkosten' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.provision : ''} onChange={this.provisionOnChangeHandler} type='number' label='Auftragsprovision' className='input'/>
                        <TextField value={this.state.company ? this.state.company.companyInformation.maxCustomerRequestCount : ''} onChange={this.maxCustomerRequestCountOnChangeHandler} type='number' label='Max. Kundenanfr./Mitarbeiter' className='input'/>
                        <p className='info-text'>
                            Die berechneten Werte finden Sie im Reporting
                        </p>
                    </div> :
                    <h2>Lädt...</h2>}
                <ToolButton onClick={() => this.updateCompany(this.state.company)} main className='save-button'>Speichern</ToolButton>
            </div>
        )
    }
}

export default CompanyAdministration;