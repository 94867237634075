import React, {Component} from 'react';
import './TeamDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";

class TeamDialog extends Component {
    render() {

        const filter = createFilterOptions();

        return (
            <Dialog scroll='body' size="l" open={this.props.open} onClose={this.props.close} className='team-dialog'>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle><h1 className='item-name'>Team</h1></DialogTitle>
                <DialogContent>
                    <TextField value={this.props.team ? this.props.team.name : ''} onChange={this.props.nameOnChangeHandler} label='Teamname' className='field'/>
                    <Autocomplete
                        value={this.props.team && this.props.team.teamLeader ? this.props.team.teamLeader.username : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                this.props.teamLeaderOnChangeHandler({
                                    name: newValue,
                                });
                            } else {
                                this.props.teamLeaderOnChangeHandler(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="teamLeader"
                        className='field'
                        options={this.props.availableTeamLeaders}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.username;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.username}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} label="Teamleiter" />
                        )}
                    />
                    <div className='member-list'>
                        <h2>Teammitglieder</h2>
                        {this.props.team ? this.props.team.employees.map((employee,index) => (
                            <div>
                                <Autocomplete
                                    value={employee && employee.username ? employee.username : ''}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            this.props.changeEmployeeOnClickHandler(index,{
                                                name: newValue,
                                            });
                                        } else {
                                            this.props.changeEmployeeOnClickHandler(index,newValue);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="employee"
                                    className='field'
                                    options={this.props.availableEmployees}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.username;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.username}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Teammitglied" />
                                    )}
                                />
                            </div>
                        )) : null}
                        <ToolButton className='button' onClick={this.props.addTeamMemberOnClickHandler}>Mitglied hinzufügen</ToolButton>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ToolButton className='button' main onClick={() => this.props.saveTeamOnClickHandler(this.props.team)}>Speichern</ToolButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default TeamDialog;