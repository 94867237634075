import React, {Component} from 'react';
import './ItemDialog.css';

//Components
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import AttributeItem from "../../../../../../reusable/AttributeItem/AttributeItem";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import FileDialog from "../../../../../../reusable/FileDialog/FileDialog";
import api from "../../../../../../api";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";


class ItemDialog extends Component {

    state = {
        item:this.props.item
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            this.setState({item:this.props.item});
        }
    }

    //Change Handler

    //Common

    nrOnChangeHandler = (e) => {
        let item = this.state.item;
        item.nr = e.target.value;
        this.setState({item:item});
    }

    nameOnChangeHandler = (e) => {
        let item = this.state.item;
        item.name = e.target.value;
        this.setState({item:item});
    }

    priceOnChangeHandler = (e) => {
        let item = this.state.item;
        item.price = e.target.value;
        this.setState({item:item});
    }

    packingUnitOnChangeHandler = (e) => {
        let item = this.state.item;
        item.packingUnit = e.target.value;
        this.setState({item:item});
    }

    streetNameOnChangeHandler = (e) => {
        let item = this.state.item;
        item.streetName = e.target.value;
        this.setState({item:item});
    }

    streetNrOnChangeHandler = (e) => {
        let item = this.state.item;
        item.streetNr = e.target.value;
        this.setState({item:item});
    }

    addressAdditionOnChangeHandler = (e) => {
        let item = this.state.item;
        item.addressAddition = e.target.value;
        this.setState({item:item});
    }

    postCodeOnChangeHandler = (e) => {
        let item = this.state.item;
        item.postCode = e.target.value;
        this.setState({item:item});
    }

    cityOnChangeHandler = (e) => {
        let item = this.state.item;
        item.city = e.target.value;
        this.setState({item:item});
    }

    countryOnChangeHandler = (e) => {
        let item = this.state.item;
        item.country = e.target.value;
        this.setState({item:item});
    }

    emailOnChangeHandler = (e) => {
        let item = this.state.item;
        item.email = e.target.value;
        this.setState({item:item});
    }

    phoneOnChangeHandler = (e) => {
        let item = this.state.item;
        item.phone = e.target.value;
        this.setState({item:item});
    }

    addItemOnClickHandler = () => {
        switch (this.props.type) {
            case 0:
                if(this.state.item.nr && this.state.item.name) {
                    if(this.state.item.id) {
                        this.props.updateItem(this.state.item);
                        this.props.close();
                    }else{
                        this.props.addNewItem(this.state.item);
                        this.props.close();
                    }
                }else{
                    this.props.showMessage(2,"Fehlende oder falsche Werte");
                }
                break;
            case 1:
                if(this.state.item.nr && this.state.item.name && this.state.item.email) {
                    if(this.state.item.id) {
                        this.props.updateItem(this.state.item);
                        this.props.close();
                    }else{
                        this.props.addNewItem(this.state.item);
                        this.props.close();
                    }
                }else{
                    this.props.showMessage(2,"Fehlende oder falsche Werte");
                }
                break;
            case 2:
                if(this.state.item.nr && this.state.item.name && this.state.item.email) {
                    if(this.state.item.id) {
                        this.props.updateItem(this.state.item);
                        this.props.close();
                    }else{
                        this.props.addNewItem(this.state.item);
                        this.props.close();
                    }
                }else{
                    this.props.showMessage(2,"Fehlende oder falsche Werte");
                }
                break;
            case 3:
                if(this.state.item.nr && this.state.item.name && this.state.item.email) {
                    if(this.state.item.id) {
                        this.props.updateItem(this.state.item);
                        this.props.close();
                    }else{
                        this.props.addNewItem(this.state.item);
                        this.props.close();
                    }
                }else{
                    this.props.showMessage(2,"Fehlende oder falsche Werte");
                }
                break;
            default:
                if(this.state.item.nr && this.state.item.name) {
                    if(this.state.item.id) {
                        this.props.updateItem(this.state.item);
                        this.props.close();
                    }else{
                        this.props.addNewItem(this.state.item);
                        this.props.close();
                    }
                }else{
                    this.props.showMessage(2,"Fehlende oder falsche Werte");
                }
                break;
        }
    }

    supplierOnChangeHandler = (supplier) => {
        let item = this.state.item;
        item.supplier = supplier;
        this.setState({supplier:supplier});
    }

    render() {

        const filter = createFilterOptions();

        let title = 'unbekannt';
        let nrTextField = null;
        let labelName = null;

        let salesDiv = null;
        let addressDiv = null;
        let contactDiv = null;

        switch (this.props.type) {
            case 0:
                title = this.props.isNewItem ? 'Artikel anlegen' : 'Artikel bearbeiten'
                labelName = 'Artikelname';
                nrTextField = <TextField
                    disabled={this.props.item.id}
                    value={this.state.item.nr}
                    onChange={this.nrOnChangeHandler}
                    className='field'
                    label='Artikelnummer'
                />
                salesDiv = <div>
                    <h2>Handel</h2>
                    <TextField
                        value={this.state.item.packingUnit}
                        onChange={this.packingUnitOnChangeHandler}
                        className='field'
                        type='number'
                        helperText='optional'
                        label='Verpackungseinheit'
                    />
                    <TextField
                        value={this.state.item.price}
                        onChange={this.priceOnChangeHandler}
                        className='field'
                        type='number'
                        helperText='optional'
                        label='Verkaufspreis (netto)'
                    />
                    <Autocomplete
                        value={this.state.item && this.state.item.supplier ? this.state.item.supplier.name : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                this.supplierOnChangeHandler({
                                    name: newValue,
                                });
                            } else {
                                this.supplierOnChangeHandler(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="users"
                        className='field'
                        options={this.props.suppliers}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField helperText='optional' {...params} label="Lieferant" />
                        )}
                    />
                </div>
                break;
            case 1:
                title = this.props.isNewItem ? 'Kunde anlegen' : 'Kunde bearbeiten'
                labelName = 'Kundenname';
                nrTextField = <TextField
                    disabled={!this.props.isNewItem}
                    value={this.state.item.nr}
                    onChange={this.nrOnChangeHandler}
                    className='field'
                    label='Kundennummer'
                />
                addressDiv = <div>
                    <h2>Adresse</h2>
                    <TextField
                        value={this.state.item.streetName}
                        onChange={this.streetNameOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Straßenname'
                    />
                    <TextField
                        value={this.state.item.streetNr}
                        onChange={this.streetNrOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Hausnummer'
                    />
                    <TextField
                        value={this.state.item.addressAddition}
                        onChange={this.addressAdditionOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Adresszusatz'
                    />
                    <TextField
                        value={this.state.item.postCode}
                        onChange={this.postCodeOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Postleitzahl'
                    />
                    <TextField
                        value={this.state.item.city}
                        onChange={this.cityOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Stadt'
                    />

                    <TextField
                        helperText='optional'
                        className='field' label='Land'
                        value={this.state.item.country}
                        select
                        onChange={this.countryOnChangeHandler}
                    >
                        {this.props.countries}
                    </TextField>
                </div>
                contactDiv = <div>
                    <h2>Kontakt</h2>
                    <TextField
                        value={this.state.item.email}
                        onChange={this.emailOnChangeHandler}
                        className='field'
                        label='E-Mail'
                    />
                    <TextField
                        value={this.state.item.phone}
                        onChange={this.phoneOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Telefonnummer'
                    />
                </div>
                break;
            case 2:
                title = this.props.isNewItem ? 'Lieferant anlegen' : 'Lieferant bearbeiten'
                labelName = 'Lieferantenname';
                nrTextField = <TextField
                    disabled={!this.props.isNewItem}
                    value={this.state.item.nr}
                    onChange={this.nrOnChangeHandler}
                    className='field'
                    label='Lieferantennummer'
                />
                addressDiv = <div>
                    <h2>Adresse</h2>
                    <TextField
                        value={this.state.item.streetName}
                        onChange={this.streetNameOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Straßenname'
                    />
                    <TextField
                        value={this.state.item.streetNr}
                        onChange={this.streetNrOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Hausnummer'
                    />
                    <TextField
                        value={this.state.item.addressAddition}
                        onChange={this.addressAdditionOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Adresszusatz'
                    />
                    <TextField
                        value={this.state.item.postCode}
                        onChange={this.postCodeOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Postleitzahl'
                    />
                    <TextField
                        value={this.state.item.city}
                        onChange={this.cityOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Stadt'
                    />

                    <TextField
                        helperText='optional'
                        className='field' label='Land'
                        value={this.state.item.country}
                        select
                        onChange={this.countryOnChangeHandler}
                    >
                        {this.props.countries}
                    </TextField>
                </div>
                contactDiv = <div>
                    <h2>Kontakt</h2>
                    <TextField
                        value={this.state.item.email}
                        onChange={this.emailOnChangeHandler}
                        className='field'
                        label='E-Mail'
                    />
                    <TextField
                        value={this.state.item.phone}
                        onChange={this.phoneOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Telefonnummer'
                    />
                </div>
                break;
            case 3:
                title = this.props.isNewItem ? 'Handwerker anlegen' : 'Handwerker bearbeiten'
                labelName = 'Handwerkername';
                nrTextField = <TextField
                    disabled={!this.props.isNewItem}
                    value={this.state.item.nr}
                    onChange={this.nrOnChangeHandler}
                    className='field'
                    label='Handwerkernummer'
                />
                addressDiv = <div>
                    <h2>Adresse</h2>
                    <TextField
                        value={this.state.item.streetName}
                        onChange={this.streetNameOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Straßenname'
                    />
                    <TextField
                        value={this.state.item.streetNr}
                        onChange={this.streetNrOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Hausnummer'
                    />
                    <TextField
                        value={this.state.item.addressAddition}
                        onChange={this.addressAdditionOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Adresszusatz'
                    />
                    <TextField
                        value={this.state.item.postCode}
                        onChange={this.postCodeOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Postleitzahl'
                    />
                    <TextField
                        value={this.state.item.city}
                        onChange={this.cityOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Stadt'
                    />

                    <TextField
                        helperText='optional'
                        className='field' label='Land'
                        value={this.state.item.country}
                        select
                        onChange={this.countryOnChangeHandler}
                    >
                        {this.props.countries}
                    </TextField>
                </div>
                contactDiv = <div>
                    <h2>Kontakt</h2>
                    <TextField
                        value={this.state.item.email}
                        onChange={this.emailOnChangeHandler}
                        className='field'
                        label='E-Mail'
                    />
                    <TextField
                        value={this.state.item.phone}
                        onChange={this.phoneOnChangeHandler}
                        className='field'
                        helperText='optional'
                        label='Telefonnummer'
                    />
                </div>
                break;
            default:
                break;
        }

        return (
            <Dialog scroll='body' type={this.props.type} open={this.props.open} onClose={this.props.close} className='item-dialog'>
                <DialogTitle className='title'>{title}</DialogTitle>
                <DialogContent>
                    {nrTextField}
                    <TextField value={this.state.item.name} disabled={this.state.item.id} onChange={this.nameOnChangeHandler} className='field' label={labelName}/>
                    {salesDiv}
                    {addressDiv}
                    {contactDiv}
                </DialogContent>
                <DialogActions>
                    <ToolButton onClick={this.addItemOnClickHandler} main className='button'>{!this.state.item.id ? 'Anlegen' : 'Speichern'}</ToolButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ItemDialog;