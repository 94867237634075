import React, {Component} from 'react';
import './SecuritySettings.css';

//Components
import TextField from "@material-ui/core/TextField";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import api from "../../../../../api";

class SecuritySettings extends Component {

    state = {
        currentPassword:'',
        newPassword:'',
        checkPassword:'',
        user:this.props.user
    }

    currentPasswordOnChangeHandler = (e) => {
        this.setState({currentPassword:e.target.value});
    }

    newPasswordOnChangeHandler = (e) => {
        this.setState({newPassword:e.target.value})
    }

    checkPasswordOnChangeHandler = (e) => {
        this.setState({checkPassword:e.target.value});
    }

    changeOwnPasswordOnClickHandler = () => {
        if(this.state.currentPassword !== '' && this.state.newPassword !== '' && this.state.checkPassword !== '') {
            if(this.state.newPassword === this.state.checkPassword) {
                let user = this.props.user;
                user.password = this.state.newPassword;
                this.changeOwnPassword(this.state.currentPassword,user);
            }else{
                this.props.showMessage(2,'Das wiederholte Passwort entspricht nicht dem neuen Passwort');
            }
        }else{
            this.props.showMessage(2,'Bitte alle Felder ausfüllen');
        }
    }

    changeOwnPassword = (passwordConfirmation,user) => {
        let loginUser = {
            username:localStorage.getItem('abbreviation') + '.' + localStorage.getItem('username'),
            password:passwordConfirmation
        }
        const loginUrl = '/login';
        api.post(loginUrl,loginUser)
            .then(response => {
                const url = '/users/change-own-password';
                api.put(url,user)
                    .then(response => {
                        this.props.showMessage(0,'Passwort erfolgreich geändert');
                        this.clearFields();
                    })
                    .catch(error => {
                        if(error.response) {
                            this.props.showMessage(2,error.response.data.message);
                            console.log(error.response)
                        } else if (error.request) {
                            this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                            console.log(error.request);
                        } else {
                            this.props.showMessage(2,"Etwas ist schiefgelaufen");
                            console.log(error.message);
                        }
                    });
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,"Falsches Passwort");
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    clearFields = () => {
        this.setState({currentPassword:''});
        this.setState({newPassword:''});
        this.setState({checkPassword:''});
    }

    render() {
        return (
            <div className='security-settings'>
                <h1>Sicherheit</h1>
                <TextField value={this.state.currentPassword} onChange={this.currentPasswordOnChangeHandler} type='password' label='Aktuelles Passwort' className='input'/>
                <TextField value={this.state.newPassword} onChange={this.newPasswordOnChangeHandler} type='password' label='Neues Passwort' className='input'/>
                <TextField value={this.state.checkPassword} onChange={this.checkPasswordOnChangeHandler} type='password' label='Passwort wiederholen' className='input'/>
                <ToolButton onClick={this.changeOwnPasswordOnClickHandler} main className='save-button'>Speichern</ToolButton>
            </div>
        )
    }
}

export default SecuritySettings;