import React, {Component} from 'react';
import './ListItem.css';

//Components
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Paper from '@material-ui/core/Paper';
import ReactTooltip from "react-tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

class ListItem extends Component {

    //Types: 1 = User, 2 = Application, 3 = Customer Request, 4 = Appointment, 5 = Offer

    render() {
        return (
            <Paper elevation={3} className='list-item'>
                <ReactTooltip/>
                {this.props.type === 4 || this.props.type === 5 ? <div data-tip={this.props.item.status === 1 ? "aktiv" : this.props.item.status === 2 ? "abgeschlossen" : this.props.item.status === 3 ? "abgebrochen" : null} style={this.props.item.status === 1 ? {background:"lightgreen"} : this.props.item.status === 2 ? {background:"lightgray"} : this.props.item.status === 3 ? {background:"lightcoral"} : null} className='status'/> : null}
                {this.props.type === 1 && this.props.currentUser && (this.props.currentUser.rights === 3 || this.props.currentUser.rights === 4) ? <DeleteIcon onClick={this.props.showDeleteItemDialog} data-tip="Löschen" className='icon delete-icon'/> : null}
                <DocumentScannerIcon data-tip="Anzeigen" onClick={this.props.showItemDialog} className='icon show-icon'/>
                <p className='item-nr'>
                    {this.props.type === 1 && this.props.item.username ? this.props.item.username : null}
                    {this.props.type === 2 && this.props.item.futureUsername ? this.props.item.futureUsername : null}
                    {this.props.type === 3 && this.props.item.creationDate ? "Erstellt am " + this.props.item.creationDate.substring(8,10) + "." + this.props.item.creationDate.substring(5,7) + "." + this.props.item.creationDate.substring(0,4) : null}
                    {this.props.type === 4 && this.props.item.dateTime ? this.props.item.dateTime.substring(8,10) + "." + this.props.item.dateTime.substring(5,7) + "." + this.props.item.dateTime.substring(0,4) + " " + this.props.item.dateTime.substring(11,16) : null}
                    {this.props.type === 5 && this.props.item.date ? this.props.item.date.substring(8,10) + "." + this.props.item.date.substring(5,7) + "." + this.props.item.date.substring(0,4) + " " + this.props.item.date.substring(11,16) : null}
                    {this.props.type === 6 && this.props.item.teamLeader && this.props.item.teamLeader.username ? "Teamleiter: " + this.props.item.teamLeader.username : ''}
                </p>
                <h3 className='item-name'>
                    {this.props.type === 1 && this.props.item.userInformation && this.props.item.userInformation.companyName ? this.props.item.userInformation.companyName :
                        this.props.type === 1 && this.props.item.userInformation && this.props.item.userInformation.firstName && this.props.item.userInformation.lastName ? this.props.item.userInformation.firstName + " " + this.props.item.userInformation.lastName : null}
                    {this.props.type === 2 && this.props.item.userInformation && this.props.item.userInformation.companyName ? this.props.item.userInformation.companyName :
                        this.props.type === 2 && this.props.item.userInformation && this.props.item.userInformation.firstName && this.props.item.userInformation.lastName ? this.props.item.userInformation.firstName + " " + this.props.item.userInformation.lastName : null}
                    {this.props.type === 3 && this.props.item.customer && this.props.item.customer.name ? this.props.item.customer.name : null}
                    {this.props.type === 4 && this.props.item.customer && this.props.item.customer.name ? this.props.item.customer.name : null}
                    {this.props.type === 5 && this.props.item.customer && this.props.item.customer.name ? this.props.item.customer.name : null}
                    {this.props.type === 6 && this.props.item.name ? this.props.item.name : ''}
                </h3>
            </Paper>
        )
    }
}

export default ListItem;