import React, {Component} from 'react';
import './MasterDataItem.css';

//Components
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';

class MasterDataItem extends Component {
    render() {
        return (
            <Paper elevation={3} className='master-data-item'>
                <DeleteIcon onClick={this.props.deleteItem} className='icon delete-icon'/>
                <EditIcon onClick={() => this.props.showItemDialog(false,this.props.item)} className='icon edit-icon'/>
                <p className='item-nr'>{this.props.item.nr ? this.props.item.nr : null}</p>
                <h3 className='item-name'>{this.props.item.name ? this.props.item.name : 'Name unbekannt'}</h3>
            </Paper>
        )
    }
}

export default MasterDataItem;