import React, {Component} from "react";
import './ApplyDialog.css';
import Dialog from "@material-ui/core/Dialog";

//Components
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import ToolButton from "../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from "react-tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import api from "../../../api";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

class ApplyDialog extends Component {

    state = {
        abbreviation:'',
        application: {
            userInformation:{

            }
        },
        truth:false,
        dataPrivacy:false,
        picture:null,
        idFront:null,
        idBack:null,
        businessLicence:null,
        hasChildren:false,
        securityKey:''
    }

    // On Change Handler

    abbreviationOnChangeHandler = (e) => {
        this.setState({abbreviation:e.target.value});
    }

    typeOnChangeHandler = (e) => {
        let application = this.state.application;
        application.type = e.target.value;
        this.setState({application:application});
    }

    companyNameOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.companyName = e.target.value;
        this.setState({application:application});
    }

    streetNameOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.streetName = e.target.value;
        this.setState({application:application});
    }

    streetNrOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.streetNr = e.target.value;
        this.setState({application:application});
    }

    addressAdditionOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.addressAddition = e.target.value;
        this.setState({application:application});
    }

    postCodeOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.postCode = e.target.value;
        this.setState({application:application});
    }

    cityOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.city = e.target.value;
        this.setState({application:application});
    }

    countryOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.country = e.target.value;
        this.setState({application:application});
    }

    taxNumberOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.taxNumber = e.target.value;
        this.setState({application:application});
    }

    vatNumberOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.vatNumber = e.target.value;
        this.setState({application:application});
    }

    salutationOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.salutation = e.target.value;
        this.setState({application:application});
    }

    firstNameOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.firstName = e.target.value;
        this.setState({application:application});
    }

    lastNameOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.lastName = e.target.value;
        this.setState({application:application});
    }

    birthDateOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.birthDate = e.target.value;
        this.setState({application:application});
    }

    nationalityOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.nationality = e.target.value;
        this.setState({application:application});
    }

    emailOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.email = e.target.value;
        this.setState({application:application});
    }

    phoneOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.phone = e.target.value;
        this.setState({application:application});
    }

    jacketSizeOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.jacketSize = e.target.value;
        this.setState({application:application});
    }

    tshirtSizeOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.tshirtSize = e.target.value;
        this.setState({application:application});
    }

    accountHolderOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.accountHolder = e.target.value;
        this.setState({application:application});
    }

    ibanOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.iban = e.target.value;
        this.setState({application:application});
    }

    bicOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.bic = e.target.value;
        this.setState({application:application});
    }

    plannedEmploymentStartOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.plannedEmploymentStart = e.target.value;
        this.setState({application:application});
    }

    desiredSalaryOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.desiredSalary = e.target.value;
        this.setState({application:application});
    }

    pictureOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 10000000) {
            this.setState({picture:file});
        }else{
            this.props.showMessage(2,"Die Datei darf maximal 10MB groß sein");
        }
    }

    idFrontOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 10000000) {
            this.setState({idFront:file});
        }else{
            this.props.showMessage(2,"Die Datei darf maximal 10MB groß sein");
        }
    }

    idBackOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 10000000) {
            this.setState({idBack:file});
        }else{
            this.props.showMessage(2,"Die Datei darf maximal 10MB groß sein");
        }
    }
    idNumberOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.idNumber = e.target.value;
        this.setState({application:application});
    }

    businessLicenceOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 10000000) {
            this.setState({businessLicence:file});
        }else{
            this.props.showMessage(2,"Die Datei darf maximal 10MB groß sein");
        }
    }

    truthToggle = (e) => {
        this.setState({truth:e.target.checked});
    }

    dataPrivacyToggle = (e) => {
        this.setState({dataPrivacy:e.target.checked});
    }

    hasChildrenToggle = () => {
        if(this.state.hasChildren) {
            let application = this.state.application;
            application.userInformation.children = [];
            this.setState({hasChildren:!this.state.hasChildren,application:application});
        }else{
            this.setState({hasChildren:!this.state.hasChildren});
        }
    }

    childrenCountOnChangeHandler = (e) => {
        let application = this.state.application;
        let children = [];
        let formattedNumber = e.target.value;
        if(formattedNumber > 20) {
            formattedNumber = 20;
            this.props.showMessage(2,"Es sind maximal 20 Kinder möglich");
        }
        for(let i = 0; i < formattedNumber; i++) {
            children.push({
                firstName:'',
                lastName:'',
                birtDate:''
            });
        }
        application.userInformation.children = children;
        this.setState({application:application});
    }

    taxIdOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.taxId = e.target.value;
        this.setState({application:application});
    }

    childAllowanceOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.childAllowance = e.target.value;
        this.setState({application:application});
    }

    churchTaxToggle = (e) => {
        let application = this.state.application;
        application.userInformation.churchTax = !application.churchTax;
        this.setState({application:application});
    }

    taxBracketOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.taxBracket = e.target.value;
        this.setState({application:application});
    }

    healthInsuranceOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.healthInsurance = e.target.value;
        this.setState({application:application});
    }

    insuranceNumberOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.insuranceNumber = e.target.value;
        this.setState({application:application});
    }

    insuranceStatusOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.insuranceStatus = e.target.value;
        this.setState({application:application});
    }

    nationalInsuranceNumberOnChangeHandler = (e) => {
        let application = this.state.application;
        application.userInformation.nationalInsuranceNumber = e.target.value;
        this.setState({application:application});
    }

    childFirstNameOnChangeHandler = (e,index) => {
        let application = this.state.application;
        application.userInformation.children[index].firstName = e.target.value;
        this.setState({application:application});
    }

    childLastNameOnChangeHandler = (e,index) => {
        let application = this.state.application;
        application.userInformation.children[index].lastName = e.target.value;
        this.setState({application:application});
    }

    childBirthDateOnChangeHandler = (e,index) => {
        let application = this.state.application;
        application.userInformation.children[index].birthDate = e.target.value;
        this.setState({application:application});
    }

    securityKeyOnChangeHandler = (e) => {
        this.setState({securityKey:e.target.value});
    }

    sendApplicationOnClickHandler = () => {
        if(this.state.application.type === "distributor") {
            if(
                this.state.securityKey &&
                this.state.application.userInformation.companyName &&
                this.state.application.userInformation.streetName &&
                this.state.application.userInformation.streetNr &&
                this.state.application.userInformation.postCode &&
                this.state.application.userInformation.city &&
                this.state.application.userInformation.country &&
                this.state.application.userInformation.taxNumber &&
                this.state.application.userInformation.vatNumber &&
                this.state.application.userInformation.salutation &&
                this.state.application.userInformation.firstName &&
                this.state.application.userInformation.lastName &&
                this.state.application.userInformation.birthDate &&
                this.state.application.userInformation.email &&
                this.state.application.userInformation.phone &&
                this.state.application.userInformation.jacketSize &&
                this.state.application.userInformation.tshirtSize &&
                this.state.application.userInformation.accountHolder &&
                this.state.application.userInformation.iban &&
                this.state.application.userInformation.bic &&
                this.state.application.userInformation.plannedEmploymentStart &&
                this.state.picture &&
                this.state.idFront &&
                this.state.idBack &&
                this.state.application.userInformation.idNumber &&
                this.state.businessLicence &&
                this.state.truth &&
                this.state.dataPrivacy
            ) {
                this.sendApplication(this.state.abbreviation,this.state.securityKey,this.state.application);
            }else{
                this.props.showMessage(2,"Fehlende oder falsche Werte");
            }
        }else if(this.state.application.type === "employee") {
            let childrenCheck = true;
            if(this.state.application.userInformation.children) {
                for(let i = 0; i < this.state.application.userInformation.children.length; i++) {
                    if(
                        !this.state.application.userInformation.children[i].firstName ||
                        !this.state.application.userInformation.children[i].lastName ||
                        !this.state.application.userInformation.children[i].birthDate
                    ) {
                        childrenCheck = false;
                        break;
                    }
                }
            }
            if(
                this.state.securityKey &&
                this.state.application.userInformation.firstName &&
                this.state.application.userInformation.lastName &&
                this.state.application.userInformation.streetName &&
                this.state.application.userInformation.streetNr &&
                this.state.application.userInformation.postCode &&
                this.state.application.userInformation.city &&
                this.state.application.userInformation.birthDate &&
                this.state.application.userInformation.nationality &&
                this.state.application.userInformation.email &&
                this.state.application.userInformation.phone &&
                this.state.application.userInformation.jacketSize &&
                this.state.application.userInformation.tshirtSize &&
                this.state.application.userInformation.accountHolder &&
                this.state.application.userInformation.iban &&
                this.state.application.userInformation.bic &&
                this.state.application.userInformation.taxId &&
                this.state.application.userInformation.taxBracket &&
                this.state.application.userInformation.childAllowance &&
                this.state.application.userInformation.healthInsurance &&
                this.state.application.userInformation.insuranceNumber &&
                this.state.application.userInformation.insuranceStatus &&
                this.state.application.userInformation.nationalInsuranceNumber &&
                this.state.picture &&
                this.state.idFront &&
                this.state.idBack &&
                childrenCheck &&
                this.state.truth &&
                this.state.dataPrivacy
            ) {
                this.sendApplication(this.state.abbreviation,this.state.securityKey,this.state.application);
            }else{
                this.props.showMessage(2,"Fehlende oder falsche Werte");
            }
        }
    }

    sendApplication = (abbreviation, securityKey, application) => {
        const url = '/applications/apply/' + abbreviation + "/" + securityKey;
        api.post(url,application)
            .then(response => {
                this.appendFiles(abbreviation,securityKey,response.data);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    appendFiles = (abbreviation, securityKey, application) => {
        let formData = new FormData();
        formData.append("picture",this.state.picture);
        formData.append("idFront",this.state.idFront);
        formData.append("idBack",this.state.idBack);
        if(this.state.businessLicence) {
            formData.append("businessLicence",this.state.businessLicence);
        }

        const url = '/applications/append-files/' + abbreviation + "/" + securityKey + "/" + application.id;
        api.post(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Bewerbung wurde erfolgreich geschickt")
                this.props.close();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    render() {

        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='apply-dialog'>
                <ReactTooltip/>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle><h1>Bewerbung</h1></DialogTitle>
                <DialogContent>

                    <TextField inputProps={{ maxLength: 5 }} value={this.state.abbreviation ? this.state.abbreviation : ""} onChange={this.abbreviationOnChangeHandler} className='field' label='Unternehmenskürzel (5-stellig)'/>
                    <TextField inputProps={{ maxLength: 16 }} value={this.state.securityKey} onChange={this.securityKeyOnChangeHandler} className='field' label='Sicherheitsschlüssel'/>

                    {this.state.application && this.state.abbreviation && this.state.abbreviation.length === 5 ? <TextField value={this.state.application.type ? this.state.application.type : ""} onChange={this.typeOnChangeHandler} select className='field' label='Beschäftigungsart'>
                        <MenuItem key={0} value={'distributor'}>Vertriebspartner</MenuItem>
                        <MenuItem key={1} value={'employee'}>Angestellter</MenuItem>
                    </TextField> : null}

                    {this.state.application.type === "distributor" ? <div>
                        <div>
                            <h2>Angaben zum Unternehmen</h2>
                            <TextField value={this.state.application.userInformation.companyName ? this.state.application.userInformation.companyName : ""} onChange={this.companyNameOnChangeHandler} className='field' label='Unternehmensname (inkl. Rechtsf.)'/>
                            <TextField value={this.state.application.userInformation.streetName ? this.state.application.userInformation.streetName : ""} onChange={this.streetNameOnChangeHandler} className='field' label='Straße'/>
                            <TextField value={this.state.application.userInformation.streetNr ? this.state.application.userInformation.streetNr : ""} onChange={this.streetNrOnChangeHandler} className='field' label='Hausnummer'/>
                            <TextField value={this.state.application.userInformation.addressAddition ? this.state.application.userInformation.addressAddition : ""} helperText='optional' onChange={this.addressAdditionOnChangeHandler} className='field' label='Adresszusatz'/>
                            <TextField value={this.state.application.userInformation.postCode ? this.state.application.userInformation.postCode : ""} onChange={this.postCodeOnChangeHandler} className='field' label='Postleitzahl'/>
                            <TextField value={this.state.application.userInformation.city ? this.state.application.userInformation.city : ""} onChange={this.cityOnChangeHandler} className='field' label='Ort'/>
                            <TextField value={this.state.application.userInformation.country ? this.state.application.userInformation.country : ""} onChange={this.countryOnChangeHandler} className='field' label='Land'>
                                {this.props.countries}
                            </TextField>
                            <TextField value={this.state.application.userInformation.taxNumber ? this.state.application.userInformation.taxNumber : ""} onChange={this.taxNumberOnChangeHandler} className='field' label='Steuernummer'/>
                            <TextField value={this.state.application.userInformation.vatNumber ? this.state.application.userInformation.vatNumber : ""} onChange={this.vatNumberOnChangeHandler} className='field' label='Umsatzsteuernummer'/>
                        </div>
                        <div>
                            <h2>Geschäftsinhaber</h2>
                            <TextField value={this.state.application.userInformation.salutation} onChange={this.salutationOnChangeHandler} select className='field' label='Anrede'>
                                <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                            </TextField>
                            <TextField value={this.state.application.userInformation.firstName ? this.state.application.userInformation.firstName : ""} onChange={this.firstNameOnChangeHandler} className='field' label='Vorname'/>
                            <TextField value={this.state.application.userInformation.lastName ? this.state.application.userInformation.lastName : ""} onChange={this.lastNameOnChangeHandler} className='field' label='Nachname'/>
                            <TextField value={this.state.application.userInformation.birthDate ? this.state.application.userInformation.birthDate : ""} onChange={this.birthDateOnChangeHandler} type='date' className='field' label='Geburtsdatum'/>
                            <TextField value={this.state.application.userInformation.email ? this.state.application.userInformation.email : ""} onChange={this.emailOnChangeHandler} type='email' className='field' label='E-Mail'/>
                            <TextField value={this.state.application.userInformation.phone ? this.state.application.userInformation.phone : ""} onChange={this.phoneOnChangeHandler} className='field' label='Telefon'/>
                            <TextField value={this.state.application.userInformation.jacketSize} onChange={this.jacketSizeOnChangeHandler} select className='field' label='Jacken Größe'>
                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                            </TextField>
                            <TextField value={this.state.application.userInformation.tshirtSize} onChange={this.tshirtSizeOnChangeHandler} select className='field' label='T-Shirt Größe'>
                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                            </TextField>
                        </div>
                        <div>
                            <h2>Bankverbindung</h2>
                            <TextField value={this.state.application.userInformation.accountHolder ? this.state.application.userInformation.accountHolder : ""} onChange={this.accountHolderOnChangeHandler} className='field' label='Kontoinhaber'/>
                            <TextField value={this.state.application.userInformation.iban ? this.state.application.userInformation.iban : ""} onChange={this.ibanOnChangeHandler} className='field' label='IBAN'/>
                            <TextField value={this.state.application.userInformation.bic ? this.state.application.userInformation.bic : ""} onChange={this.bicOnChangeHandler} className='field' label='BIC'/>
                            <TextField value={this.state.application.userInformation.plannedEmploymentStart ? this.state.application.userInformation.plannedEmploymentStart : ""} onChange={this.plannedEmploymentStartOnChangeHandler} type='date' className='field' label='Geplanter Beschäftigungsstart'/>
                            <TextField value={this.state.application.userInformation.desiredSalary ? this.state.application.userInformation.desiredSalary : ""} onChange={this.desiredSalaryOnChangeHandler} helperText='optional' type='number' className='field' label='Wunschgehalt (netto)'/>
                            <div className='field'>
                                <p>Bild hochladen</p>
                                <Button variant="contained" component="label" className='field button' onChange={this.pictureOnChangeHandler}>
                                    {this.state.picture ? this.state.picture.name : "Datei auswählen"}
                                    <input type="file" hidden accept="*"/>
                                </Button>
                            </div>
                            <div className='field'>
                                <p>Personalausweis Vorderseite hochladen</p>
                                <Button variant="contained" component="label" className='field button' onChange={this.idFrontOnChangeHandler}>
                                    {this.state.idFront ? this.state.idFront.name : "Datei auswählen"}
                                    <input type="file" hidden accept="*"/>
                                </Button>
                            </div>
                            <div className='field'>
                                <p>Personalausweis Rückseite hochladen</p>
                                <Button variant="contained" component="label" className='field button' onChange={this.idBackOnChangeHandler}>
                                    {this.state.idBack ? this.state.idBack.name : "Datei auswählen"}
                                    <input type="file" hidden accept="*"/>
                                </Button>
                            </div>
                            <TextField value={this.state.application.userInformation.idNumber ? this.state.application.userInformation.idNumber : ""} onChange={this.idNumberOnChangeHandler} className='field' label='Personalausweis Nummer'/>
                            <div className='field'>
                                <p>Gewerbescheinauszug hochladen</p>
                                <Button variant="contained" component="label" className='field button' onChange={this.businessLicenceOnChangeHandler}>
                                    {this.state.businessLicence ? this.state.businessLicence.name : "Datei auswählen"}
                                    <input type="file" hidden accept="*"/>
                                </Button>
                            </div>
                            <FormControlLabel className='field' control={<Checkbox checked={this.state.truth} onChange={this.truthToggle}/>} label="Ich habe alles wahrheitsgemäß beantwortet" />
                            <FormControlLabel className='field' control={<Checkbox checked={this.state.dataPrivacy} onChange={this.dataPrivacyToggle}/>} label={"Ich stimme den Datenschutzbestimmungen zu"} />
                        </div>
                    </div> : this.state.application.type === "employee" ? <div>
                        <div>
                            <h2>Angaben zur Person</h2>
                            <TextField value={this.state.application.userInformation.salutation} onChange={this.salutationOnChangeHandler} select className='field' label='Anrede'>
                                <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                            </TextField>
                            <TextField value={this.state.application.userInformation.firstName ? this.state.application.userInformation.firstName : ""} onChange={this.firstNameOnChangeHandler} className='field' label='Vorname'/>
                            <TextField value={this.state.application.userInformation.lastName ? this.state.application.userInformation.lastName : ""} onChange={this.lastNameOnChangeHandler} className='field' label='Nachname'/>
                            <TextField value={this.state.application.userInformation.streetName ? this.state.application.userInformation.streetName : ""} onChange={this.streetNameOnChangeHandler} className='field' label='Straße'/>
                            <TextField value={this.state.application.userInformation.streetNr ? this.state.application.userInformation.streetNr : ""} onChange={this.streetNrOnChangeHandler} className='field' label='Hausnummer'/>
                            <TextField value={this.state.application.userInformation.addressAddition ? this.state.application.userInformation.addressAddition : ""} helperText='optional' onChange={this.addressAdditionOnChangeHandler} className='field' label='Adresszusatz'/>
                            <TextField value={this.state.application.userInformation.postCode ? this.state.application.userInformation.postCode : ""} onChange={this.postCodeOnChangeHandler} className='field' label='Postleitzahl'/>
                            <TextField value={this.state.application.userInformation.city ? this.state.application.userInformation.city : ""} onChange={this.cityOnChangeHandler} className='field' label='Stadt'/>
                            <TextField value={this.state.application.userInformation.birthDate ? this.state.application.userInformation.birthDate : ""} onChange={this.birthDateOnChangeHandler} type='date' className='field' label='Geburtsdatum'/>
                            <TextField value={this.state.application.userInformation.nationality ? this.state.application.userInformation.nationality : ""} select onChange={this.nationalityOnChangeHandler} className='field' label='Staatsangehörigkeit'>
                                {this.props.countries}
                            </TextField>
                            <TextField value={this.state.application.userInformation.email ? this.state.application.userInformation.email : ""} onChange={this.emailOnChangeHandler} type='email' className='field' label='E-Mail'/>
                            <TextField value={this.state.application.userInformation.phone ? this.state.application.userInformation.phone : ""} onChange={this.phoneOnChangeHandler} className='field' label='Telefon'/>
                            <TextField value={this.state.application.userInformation.jacketSize} onChange={this.jacketSizeOnChangeHandler} select className='field' label='Jacken Größe'>
                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                            </TextField>
                            <TextField value={this.state.application.userInformation.tshirtSize} onChange={this.tshirtSizeOnChangeHandler} select className='field' label='T-Shirt Größe'>
                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                            </TextField>
                            <div className='field'>
                                <p>Bild hochladen</p>
                                <Button variant="contained" component="label" className='field button' onChange={this.pictureOnChangeHandler}>
                                    {this.state.picture ? this.state.picture.name : "Datei auswählen"}
                                    <input type="file" hidden accept="*"/>
                                </Button>
                            </div>
                            <div className='field'>
                                <p>Personalausweis Vorderseite hochladen</p>
                                <Button variant="contained" component="label" className='field button' onChange={this.idFrontOnChangeHandler}>
                                    {this.state.idFront ? this.state.idFront.name : "Datei auswählen"}
                                    <input type="file" hidden accept="*"/>
                                </Button>
                            </div>
                            <div className='field'>
                                <p>Personalausweis Rückseite hochladen</p>
                                <Button variant="contained" component="label" className='field button' onChange={this.idBackOnChangeHandler}>
                                    {this.state.idBack ? this.state.idBack.name : "Datei auswählen"}
                                    <input type="file" hidden accept="*"/>
                                </Button>
                            </div>
                        </div>
                        <div>
                            <h2>Bankverbindung</h2>
                            <TextField value={this.state.application.userInformation.accountHolder ? this.state.application.userInformation.accountHolder : ""} onChange={this.accountHolderOnChangeHandler} className='field' label='Kontoinhaber'/>
                            <TextField value={this.state.application.userInformation.iban ? this.state.application.userInformation.iban : ""} onChange={this.ibanOnChangeHandler} className='field' label='IBAN'/>
                            <TextField value={this.state.application.userInformation.bic ? this.state.application.userInformation.bic : ""} onChange={this.bicOnChangeHandler} className='field' label='BIC'/>
                        </div>
                        <div>
                            <h2>Lohnsteuer</h2>
                            <TextField value={this.state.application.userInformation.taxId ? this.state.application.userInformation.taxId : ""} onChange={this.taxIdOnChangeHandler} className='field' label='Kontoinhaber'/>
                            <TextField value={this.state.application.userInformation.taxBracket ? this.state.application.userInformation.taxBracket : ""} onChange={this.taxBracketOnChangeHandler} className='field' label='IBAN'/>
                            <TextField value={this.state.application.userInformation.childAllowance ? this.state.application.userInformation.childAllowance : ""} onChange={this.childAllowanceOnChangeHandler} className='field' label='BIC'/>
                            <FormControlLabel className='field' control={<Checkbox checked={this.state.application.userInformation.churchTax} onChange={this.churchTaxToggle}/>} label="Kirchensteuerpflichtig" />
                        </div>
                        <div>
                            <h2>Sozialversicherung</h2>
                            <TextField value={this.state.application.userInformation.healthInsurance ? this.state.application.userInformation.healthInsurance : ""} onChange={this.healthInsuranceOnChangeHandler} className='field' label='Krankenkasse'/>
                            <TextField value={this.state.application.userInformation.insuranceNumber ? this.state.application.userInformation.insuranceNumber : ""} onChange={this.insuranceNumberOnChangeHandler} className='field' label='Versichertennummer'/>
                            <TextField value={this.state.application.userInformation.insuranceStatus ? this.state.application.userInformation.insuranceStatus : ""} onChange={this.insuranceStatusOnChangeHandler} select className='field' label='Versichertenstatus'>
                                <MenuItem key={0} value={'statutory'}>gesetzlich</MenuItem>
                                <MenuItem key={1} value={'private'}>privat</MenuItem>
                            </TextField>
                            <TextField value={this.state.application.userInformation.nationalInsuranceNumber ? this.state.application.userInformation.nationalInsuranceNumber : ""} onChange={this.nationalInsuranceNumberOnChangeHandler} className='field' label='Sozialversicherungsnummer'/>
                            <FormControlLabel className='field' control={<Checkbox checked={this.state.hasChildren} onChange={this.hasChildrenToggle}/>} label="Ich habe Kinder" />
                            {this.state.hasChildren ? <TextField value={this.state.application.userInformation.children ? this.state.application.userInformation.children.length : 0} type='number' onChange={this.childrenCountOnChangeHandler} className='field' label='Anzahl Kinder'/> : null}
                            {this.state.application.userInformation.children ? this.state.application.userInformation.children.map((child,index) => (
                                <div className='field'>
                                    <h2>{index + 1}. Kind</h2>
                                    <TextField value={child.firstName} onChange={(e) => this.childFirstNameOnChangeHandler(e,index)} className='field' label='Vorname'/>
                                    <TextField value={child.lastName} onChange={(e) => this.childLastNameOnChangeHandler(e,index)} className='field' label='Nachname'/>
                                    <TextField value={child.birthDate} type='date' onChange={(e) => this.childBirthDateOnChangeHandler(e,index)} className='field' label='Geburtsdatum'/>
                                </div>
                            )) : null}
                            <FormControlLabel className='field' control={<Checkbox checked={this.state.truth} onChange={this.truthToggle}/>} label="Ich habe alles wahrheitsgemäß beantwortet" />
                            <FormControlLabel className='field' control={<Checkbox checked={this.state.dataPrivacy} onChange={this.dataPrivacyToggle}/>} label={"Ich stimme den Datenschutzbestimmungen zu"} />
                        </div>
                    </div> : null}
                    <DialogActions>
                        <ToolButton main className='field' onClick={this.sendApplicationOnClickHandler}>Bewerben</ToolButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
}

export default ApplyDialog;