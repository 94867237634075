import React, {Component} from "react";
import './Actions.css';

//Components
import {Route} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import MasterData from "./MasterData/MasterData";
import Administration from "./Administration/Administration";
import Settings from "./Settings/Settings";
import Applications from "./Applications/Applications";
import AppointmentManagement from "./AppointmentManagement/AppointmentManagement";
import OfferManagement from "./OfferManagement/OfferManagement";
import Reporting from "./Reporting/Reporting";
import CompanyDashboard from "./CompanyDashboard/CompanyDashboard";

class Actions extends Component {
    render() {
        return (
            <div className='actions'>
                <Route exact path='/'>
                    {this.props.company && this.props.company.abbreviation !== "ONEMG" ? <Dashboard
                        user={this.props.user}
                        showMessage={this.props.showMessage}
                        updateContractFileStorageLocation={this.props.updateContractFileStorageLocation}
                    /> :
                        <CompanyDashboard
                            showMessage={this.props.showMessage}
                            companyDataReports={this.props.companyDataReports}
                        />}
                </Route>

                <Route path='/appointment-management'>
                    <AppointmentManagement
                        showMessage={this.props.showMessage}
                        user={this.props.user}
                        users={this.props.users}
                        countries={this.props.countries}

                        //Customer Requests
                        customerRequests={this.props.customerRequests}
                        addCustomerRequestToList={this.props.addCustomerRequestToList}
                        removeCustomerRequestFromList={this.props.removeCustomerRequestFromList}

                        //Customers
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}

                        //Appointments
                        addAppointmentToList={this.props.addAppointmentToList}
                        removeAppointmentFromList={this.props.removeAppointmentFromList}
                    />
                </Route>

                <Route path='/offer-management'>
                    <OfferManagement
                        showMessage={this.props.showMessage}
                        user={this.props.user}
                        users={this.props.users}
                        countries={this.props.countries}
                        generateOffer={this.props.generateOffer}

                        //Customers
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}

                        //Offers
                        offers={this.props.offers}
                        addOfferToList={this.props.addOfferToList}
                        updateOfferInList={this.props.updateOfferInList}
                        removeOfferFromList={this.props.removeOfferFromList}

                        //Articles
                        articles={this.props.articles}

                        //Suppliers
                        suppliers={this.props.suppliers}

                        //Customers
                        craftsmen={this.props.craftsmen}
                    />
                </Route>

                <Route path='/applications'>
                    <Applications
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}

                        //Company
                        company={this.props.company}

                        //Reporting
                        applications={this.props.applications}
                        addApplicationToList={this.props.addApplicationToList}
                        removeApplicationFromList={this.props.removeApplicationFromList}
                        addUserToList={this.props.addUserToList}

                        //File
                        displayEmployeeFile={this.props.displayEmployeeFile}
                    />
                </Route>

                <Route path='/reporting'>
                    <Reporting
                        showMessage={this.props.showMessage}
                        formatDate={this.props.formatDate}
                        formatString={this.props.formatString}
                        round={this.props.round}

                        //Company
                        company={this.props.company}

                        //Users
                        users={this.props.users}

                        //Articles
                        articles={this.props.articles}

                        //Customers
                        customers={this.props.customers}
                    />
                </Route>

                <Route path='/master-data'>
                    <MasterData
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}

                        //Company
                        company={this.props.company}

                        //Articles
                        articles={this.props.articles}
                        addNewArticle={this.props.addNewArticle}
                        updateArticle={this.props.updateArticle}
                        deleteArticle={this.props.deleteArticle}
                        addArticleToList={this.props.addArticleToList}
                        removeArticleFromList={this.props.removeArticleFromList}

                        //Customers
                        customers={this.props.customers}
                        addNewCustomer={this.props.addNewCustomer}
                        updateCustomer={this.props.updateCustomer}
                        deleteCustomer={this.props.deleteCustomer}
                        addMultipleCustomers={this.props.addMultipleCustomers}
                        addCustomerToList={this.props.addCustomerToList}
                        removeCustomerFromList={this.props.removeCustomerFromList}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        addNewSupplier={this.props.addNewSupplier}
                        updateSupplier={this.props.updateCustomer}
                        deleteSupplier={this.props.deleteSupplier}
                        addSupplierToList={this.props.addSupplierToList}
                        removeSupplierFromList={this.props.removeSupplierFromList}

                        //Customers
                        craftsmen={this.props.craftsmen}
                        addNewCraftsman={this.props.addNewCraftsman}
                        updateCraftsman={this.props.updateCraftsman}
                        deleteCraftsman={this.props.deleteCraftsman}
                        addCraftsmanToList={this.props.addCraftsmanToList}
                        removeCraftsmanFromList={this.props.removeCraftsmanFromList}
                    />
                </Route>

                <Route path='/administration'>
                    <Administration
                        //Common
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Users
                        user={this.props.user}
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUserInList={this.props.updateUserInList}
                        addUserToList={this.props.addUserToList}
                        removeUserFromList={this.props.removeUserFromList}

                        //File
                        displayEmployeeFile={this.props.displayEmployeeFile}

                        //Teams
                        teams={this.props.teams}
                        addTeamToList={this.props.addTeamToList}
                        removeTeamFromList={this.props.removeTeamFromList}
                    />
                </Route>

                <Route path='/settings'>
                    <Settings
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        changeOwnPassword={this.props.changeOwnPassword}
                        updateUserInformation={this.props.updateUserInformation}
                        user={this.props.user}
                        company={this.props.company}

                        //File
                        displayEmployeeFile={this.props.displayEmployeeFile}
                    />
                </Route>
            </div>
        )
    }
}

export default Actions;