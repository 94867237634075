import axios from 'axios';

const api = axios.create({
    baseURL:'https://one-m-system.de:8443'
    //baseURL:'http://localhost:8080'
});

api.interceptors.request.use((config) => {
    config.headers.Authorization = localStorage.getItem('token');
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api;