import React, {Component} from "react";
import './PlanAppointmentDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from "react-tooltip";

class PlanAppointmentDialog extends Component {

    render() {

        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='plan-appointment-dialog'>
                <ReactTooltip/>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle><h1>Termin planen</h1></DialogTitle>
                <DialogContent>
                    <TextField text='Datum und Uhrzeit' value={this.props.dateTime} type='datetime-local' onChange={this.props.dateTimeOnChangeHandler} className='search-input' label='Datum und Uhrzeit'/>
                    <p className='info-text'>Durch das Anlegen eines Termins, wird die Kundenanfrage entfernt und ein Termin eingestellt</p>
                </DialogContent>
                <DialogActions>
                    <ToolButton disabled={!this.props.dateTime} className='button' main onClick={this.props.planAppointmentOnClickHandler}>Termin anlegen</ToolButton>
                </DialogActions>
            </Dialog>
        )
    }
}

export default PlanAppointmentDialog;