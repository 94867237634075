import React, {Component} from 'react';
import './AppointmentOverview.css';

//Components
import MenuItem from '@material-ui/core/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import AppointmentDialog from "./AppointmentDialog/AppointmentDialog";
import api from "../../../../../api";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";
import AddIcon from "@material-ui/icons/Add";
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import DialogActions from "@material-ui/core/DialogActions";
import {createFilterOptions} from "@mui/material/Autocomplete";

class AppointmentOverview extends Component {

    state = {
        activeAppointment:null,
        shownAppointments: [],
        searchWord:1,
        searchType:'status',

        //Dialogs
        showAppointmentDialog:false,
        showDeleteAppointmentDialog:false
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        switch (e.target.value) {
            case "status":
                this.setState({searchWord:1,searchType:e.target.value});
                break;
            case "customerNr":
                this.setState({searchWord:"",searchType:e.target.value});
                break;
            case "customerName":
                this.setState({searchWord:"",searchType:e.target.value});
                break;
            default:
                this.setState({searchType:e.target.value});
                break;
        }
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownAppointments = [];
            switch (this.state.searchType) {
                case "status":
                    this.props.user.appointments.forEach(element => {
                        if(element.status === this.state.searchWord) {
                            shownAppointments.push(element);
                        }
                    });
                    break;
                case "customerNr":
                    this.props.user.appointments.forEach(element => {
                        if(element.customer && element.customer.nr && element.customer.nr.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownAppointments.push(element);
                        }
                    });
                    break;
                case "customerName":
                    this.props.user.appointments.forEach(element => {
                        if(element.customer && element.customer.name.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownAppointments.push(element);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownAppointments:shownAppointments});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    createOwnAppointment = (appointment) => {
        const url = '/users/appointments/own';
        api.put(url,appointment)
            .then(response => {
                this.props.showMessage(0,"Termin wurde erfolgreich angelegt");
                this.props.addAppointmentToList(appointment);
                if(!appointment.customer.id) {
                    this.props.addCustomerToList(response.data.customer);
                }
                this.searchOnClick();
                this.closeAppointmentDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateAppointment = (appointment) => {
        const url = '/users/appointments';
        api.put(url,appointment)
            .then(response => {
                if(!appointment.customer.id) {
                    this.props.addCustomerToList(response.data.customer);
                }
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
                this.closeAppointmentDialog();
                this.searchOnClick();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions

    showAppointmentDialog = (appointment) => {
        this.setState({activeAppointment:appointment,showAppointmentDialog:true});
    }

    closeAppointmentDialog = () => {
        this.setState({showAppointmentDialog:false});
    }

    showDeleteAppointmentDialog = (appointment) => {
        this.setState({activeAppointment:appointment,showDeleteAppointmentDialog:true});
    }

    closeDeleteAppointmentDialog = () => {
        this.setState({showDeleteAppointmentDialog:false});
    }

    render() {

        let blankAppointment = {
            customer:{

            },
            status:1
        }

        return (
            <div className='customer-request-overview'>
                <ReactTooltip/>

                <AppointmentDialog
                    open={this.state.showAppointmentDialog}
                    close={this.closeAppointmentDialog}
                    showMessage={this.props.showMessage}
                    appointment={this.state.activeAppointment}
                    customers={this.props.customers}
                    addCustomerRequestToList={this.props.addAppointmentToList}
                    removeCustomerRequestFromList={this.props.removeAppointmentFromList}
                    user={this.props.user}
                    createOwnAppointment={this.createOwnAppointment}
                    updateAppointment={this.updateAppointment}
                    countries={this.props.countries}
                />

                <YesCancelDialog
                    open={this.state.showDeleteCustomerRequestDialog}
                    close={this.closeDeleteCustomerRequestDialog}
                    header='Löschen'
                    text={'Wollen Sie die Kundenanfrage wirklich löschen?'}
                    onClick={() => this.deleteCustomerRequest(this.state.activeCustomerRequest)}
                />

                <div className='header-bar'>
                    <h1>Termine</h1>
                    {this.state.searchType !== "status" ? <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-select' label='Suchwort' size='small'/> :
                        <TextField value={this.state.searchWord} onChange={this.searchWordOnChange} size='small' select className='search-select' label='Suche'>
                            <MenuItem key={1} value={1}>aktiv</MenuItem>
                            <MenuItem key={2} value={2}>abgeschlossen</MenuItem>
                            <MenuItem key={3} value={3}>abgebrochen</MenuItem>
                        </TextField>}
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-select' label='Suche'>
                        <MenuItem key={1} value={'status'}>Status</MenuItem>
                        <MenuItem key={2} value={'customerNr'}>Kundenname</MenuItem>
                        <MenuItem key={3} value={'customerName'}>Kundenname</MenuItem>
                    </TextField>
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownAppointments.sort((a,b) => a.dateTime < b.dateTime ? 1 : -1).map((appointment,index) => (
                        <ListItem
                            user={this.props.user}
                            key={index}
                            type={4}
                            item={appointment}
                            showItemDialog={() => this.showAppointmentDialog(appointment)}
                            showDeleteItemDialog={() => this.showDeleteAppointmentDialog(appointment)}
                        />
                    ))}
                </div>
                <DialogActions>
                    <ToolFab className='add-fab' onClick={() => this.showAppointmentDialog(blankAppointment)} aria-label="add">
                        <AddIcon/>
                    </ToolFab>
                </DialogActions>
            </div>
        )
    }
}

export default AppointmentOverview;