import React, {Component} from "react";

//Components
import Button from "@material-ui/core/Button";

class ToolButton extends Component {

    state = {
        hovered:false
    }

    hoverOn = () => {
        this.setState({hovered:true});
    }

    hoverOff = () => {
        this.setState({hovered:false});
    }

    render() {

        let style = null;

        if(this.props.main) {
            if(this.state.hovered) {
                style = {
                    backgroundColor:'#66573F'
                }
            }else {
                style = {
                    backgroundColor:'#DABB88'
                }
            }
        }else{
            if(this.state.hovered) {
                style = {
                    backgroundColor:'#999999'
                }
            }else {
                style = {
                    backgroundColor:'#CCCCCC'
                }
            }
        }

        return (
            <Button title={this.props.title} type={this.props.type} disabled={this.props.disabled} onMouseEnter={this.hoverOn} onMouseLeave={this.hoverOff} onClick={this.props.onClick} style={style} props={this.props} color='primary'  variant='contained' className={['tool-button', this.props.className].join(' ')}>
                {this.props.children}
            </Button>
        )
    }
}

export default ToolButton;