import React, {Component} from "react";
import './SendApplicationOpportunityDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from "react-tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import api from "../../../../../api";

class SendApplicationOpportunityDialog extends Component {

    state = {
        email:""
    }

    emailOnChangeHandler = (e) => {
        this.setState({email:e.target.value});
    }

    sendApplicationOpportunityOnClickHandler = () => {
        if(this.state.email) {
            this.sendApplicationOpportunity(this.state.email);
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    sendApplicationOpportunity = (email) => {
        let formData = new FormData();
        formData.append("email",email)
        const url = '/applications/application-opportunity';
        api.post(url,formData)
            .then(response => {
                this.props.showMessage(0,'E-Mail erfolgreich versendet');
                this.props.close();
                this.setState({email:""});
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    render() {

        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='send-application-opportunity-dialog'>
                <ReactTooltip/>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle>Bewerbungsmöglichkeit versenden</DialogTitle>
                <DialogContent>
                    <DialogContentText>Bitte geben Sie die E-Mail ein, an die die Bewerbungsmöglichkeit geschickt werden soll.</DialogContentText>
                    <TextField value={this.state.email} type='email' label='E-Mail' onChange={this.emailOnChangeHandler}/>
                </DialogContent>
                <DialogActions>
                    <ToolButton main className='button' onClick={this.sendApplicationOpportunityOnClickHandler}>Senden</ToolButton>
                </DialogActions>
            </Dialog>
        )
    }
}

export default SendApplicationOpportunityDialog;