import React, {Component} from 'react';
import './UserAdministration.css';

//Components
import MenuItem from '@material-ui/core/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import UserDialog from "./UserDialog/UserDialog";
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@material-ui/icons/Add";
import api from "../../../../../api";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";

class UserAdministration extends Component {

    state = {
        activeItem:null,
        shownUsers: [],
        searchWord:'',
        searchType:'username',
        selectedItem:null,
        action:null,
        isNewItem:true,
        formattedItems:[],
        isSaved:true,

        //Dialogs
        showUserDialog:false,
        showDeleteUserDialog:false,
        showSendWarningDialog:false,
        showSendWarningNoticeDialog:false,
        showSendTerminationDialog:false
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownUsers = [];
            switch (this.state.searchType) {
                case "username":
                    this.props.users.forEach(element => {
                        if(element.username && element.username.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownUsers.push(element);
                        }
                    });
                    break;
                case "firstName":
                    this.props.users.forEach(element => {
                        if(element.firstName && element.firstName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownUsers.push(element);
                        }
                    });
                    break;
                case "lastName":
                    this.props.users.forEach(element => {
                        if(element.lastName && element.lastName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownUsers.push(element);
                        }
                    });
                    break;
                case "companyName":
                    this.props.users.forEach(element => {
                        if(element.companyName && element.companyName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownUsers.push(element);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownUsers:shownUsers});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    //User on Change Handler

    usernameOnChangeHandler = (e) => {
        let user = this.state.activeItem;
        user.username = e.target.value;
        this.setState({activeItem:user});
    }

    passwordOnChangeHandler = (e) => {
        let user = this.state.activeItem;
        user.password = e.target.value;
        this.setState({activeItem:user});
    }

    rightsOnChangeHandler = (e) => {
        let user = this.state.activeItem;
        user.rights = e.target.value;
        this.setState({activeItem:user});
    }

    typeOnChangeHandler = (e) => {
        let user = this.state.activeItem;
        user.type = e.target.value;
        this.setState({user:user});
    }

    setIsSaved = (isSaved) => {
        this.setState({isSaved:isSaved});
    }

    approveUser = (user) => {
        const url = '/users/approve';
        api.put(url,user)
            .then(response => {
                this.props.updateUserInList(response.data);
                this.searchOnClick();
                this.props.showMessage(0,"Der Benutzer wurde erfolgreich freigeschaltet");
                this.closeUserDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    createNewUser = (user) => {
        const url = '/users';
        api.post(url,user)
            .then(response => {
                this.props.addUserToList(response.data);
                this.props.showMessage(0,"Der Benutzer wurde erfolgreich angelegt");
                this.searchOnClick();
                this.closeUserDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateUser = (user) => {
        const url = '/users';
        api.put(url,user)
            .then(response => {
                this.props.updateUserInList(response.data);
                this.props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert");
                this.searchOnClick();
                this.closeUserDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    displayEmployeeFile = (employeeFileRequest) => {
        let url = '/users/display-file/employee';
        api.post(url,employeeFileRequest,{responseType:'blob'})
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: response.data.type});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteUser = (user) => {
        let url = '/users/' + user.id;
        api.delete(url)
            .then(response => {
                this.props.showMessage(0,"Der Benutzer wurde erfolgreich gelöscht");
                this.props.removeUserFromList(user);
                this.closeDeleteUserDialog();
                this.searchOnClick();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    sendWarning = (user) => {
        let url = '/users/send-warning';
        api.post(url,user)
            .then(response => {
                this.props.showMessage(0,"Die Verwarnung wurde erfolgreich an die hinterlegte E-Mail verschickt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    sendWarningNotice = (user) => {
        let url = '/users/send-warning-notice';
        api.post(url,user)
            .then(response => {
                this.props.showMessage(0,"Die Abmahnung wurde erfolgreich an die hinterlegte E-Mail verschickt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    sendTermination = (user) => {
        let url = '/users/send-termination';
        api.post(url,user)
            .then(response => {
                this.props.showMessage(0,"Die Kündigung wurde erfolgreich an die hinterlegte E-Mail verschickt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions

    showUserDialog = (user) => {
        this.setState({activeItem:user,showUserDialog:true});
    }

    closeUserDialog = () => {
        this.setState({showUserDialog:false});
    }

    showDeleteUserDialog = (user) => {
        this.setState({activeItem:user,showDeleteUserDialog:true});
    }

    closeDeleteUserDialog = () => {
        this.setState({showDeleteUserDialog:false});
    }

    showSendWarningDialog = () => {
        this.setState({showSendWarningDialog:true});
    }

    closeSendWarningDialog = () => {
        this.setState({showSendWarningDialog:false});
    }

    showSendWarningNoticeDialog = () => {
        this.setState({showSendWarningNoticeDialog:true});
    }

    closeSendWarningNoticeDialog = () => {
        this.setState({showSendWarningNoticeDialog:false})
    }

    showSendTerminationDialog = () => {
        this.setState({showSendTerminationDialog:true});
    }

    closeSendTerminationDialog = () => {
        this.setState({showSendTerminationDialog:false});
    }

    render() {

        let blankUser = {
            username:"",
            password:"",
            rights:1
        }

        return (
            <div className='user-administration'>
                <ReactTooltip/>

                <YesCancelDialog
                    open={this.state.showDeleteUserDialog}
                    close={this.closeDeleteUserDialog}
                    header='Löschen'
                    text='Wollen Sie den Benutzer wirklich löschen?'
                    onClick={() => this.deleteUser(this.state.activeItem)}
                />

                <YesCancelDialog
                    open={this.state.showSendWarningDialog}
                    close={this.closeSendWarningDialog}
                    header='Verwarnung'
                    text='Wollen Sie dem Benutzer wirklich eine Verwarnung senden?'
                    onClick={() => this.sendWarning(this.state.activeItem)}
                />

                <YesCancelDialog
                    open={this.state.showSendWarningNoticeDialog}
                    close={this.closeSendWarningNoticeDialog}
                    header='Abmahnung'
                    text='Wollen Sie dem Benutzer wirklich eine Abmahnung senden?'
                    onClick={() => this.sendWarningNotice(this.state.activeItem)}
                />

                <YesCancelDialog
                    open={this.state.showSendTerminationDialog}
                    close={this.closeSendTerminationDialog}
                    header='Kündigung'
                    text='Wollen Sie dem Benutzer wirklich eine Kündigung senden?'
                    onClick={() => this.sendTermination(this.state.activeItem)}
                />

                <UserDialog
                    open={this.state.showUserDialog}
                    close={this.closeUserDialog}
                    showMessage={this.props.showMessage}
                    currentUser={this.props.user}
                    user={this.state.activeItem}
                    updateUser={this.updateUser}
                    updateUserInList={this.props.updateUserInList}
                    createNewUser={this.createNewUser}
                    approveUser={this.approveUser}

                    //File
                    displayEmployeeFile={this.displayEmployeeFile}

                    //On Change Handler
                    usernameOnChangeHandler={this.usernameOnChangeHandler}
                    passwordOnChangeHandler={this.passwordOnChangeHandler}
                    rightsOnChangeHandler={this.rightsOnChangeHandler}
                    typeOnChangeHandler={this.typeOnChangeHandler}

                    showSendWarningDialog={this.showSendWarningDialog}
                    showSendWarningNoticeDialog={this.showSendWarningNoticeDialog}
                    showSendTerminationDialog={this.showSendTerminationDialog}
                />

                <div className='header-bar'>
                    <h1>Benutzerverwaltung</h1>
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'username'}>Benutzername</MenuItem>
                        <MenuItem key={1} value={'firstName'}>Vorname</MenuItem>
                        <MenuItem key={2} value={'lastName'}>Nachname</MenuItem>
                        <MenuItem key={3} value={'companyName'}>Unternehmensname</MenuItem>
                    </TextField>
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownUsers.map((user,index) => (
                        <ListItem
                            key={index}
                            type={1}
                            item={user}
                            currentUser={this.props.user}
                            showItemDialog={() => this.showUserDialog(user)}
                            showDeleteItemDialog={() => this.showDeleteUserDialog(user)}
                        />
                    ))}
                </div>
                {this.props.user && this.props.user.rights === 4 ?
                    <ToolFab className='add-fab' onClick={() => this.showUserDialog(blankUser)} aria-label="add">
                        <AddIcon/>
                    </ToolFab> : null}
            </div>
        )
    }
}

export default UserAdministration;