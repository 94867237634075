import React, {Component} from "react";
import './Administration.css';
import {Route} from "react-router-dom";

//Components
import UserAdministration from "./UserAdministration/UserAdministration";
import CompanyAdministration from "./CompanyAdministration/CompanyAdministration";
import TeamAdministration from "./TeamAdministration/TeamAdministration";

class Administration extends Component {
    render() {
        return (
            <div className='administration'>
                <Route exact path='/administration'>
                    <UserAdministration
                        showMessage={this.props.showMessage}

                        //Users
                        user={this.props.user}
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUserInList={this.props.updateUserInList}
                        addUserToList={this.props.addUserToList}
                        removeUserFromList={this.props.removeUserFromList}

                        //File
                        displayEmployeeFile={this.props.displayEmployeeFile}
                    />
                </Route>
                <Route exact path='/administration/teams'>
                    <TeamAdministration
                        showMessage={this.props.showMessage}

                        //Users
                        user={this.props.user}
                        users={this.props.users}

                        //Teams
                        teams={this.props.teams}
                        addTeamToList={this.props.addTeamToList}
                        removeTeamFromList={this.props.removeTeamFromList}
                    />
                </Route>
                <Route exact path='/administration/company'>
                    <CompanyAdministration
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}
                    />
                </Route>
            </div>
        )
    }
}

export default Administration;