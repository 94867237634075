import React, {Component} from "react";
import './NavigationItems.css';

//Components
import {NavLink} from "react-router-dom";
import DashboardIcon from '@material-ui/icons/Dashboard';
import StorageIcon from '@material-ui/icons/Storage';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReactTooltip from "react-tooltip";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import BarChartIcon from '@mui/icons-material/BarChart';

class NavigationItems extends Component {
    render() {
        return (
            <menu className='navigation-items'>
                <ReactTooltip/>
                {this.props.company && this.props.company.abbreviation !== "ONEMG" ? <NavLink className='nav-item' activeClassName='active' exact to='/'><h2><DashboardIcon className='icon'/> Übersicht</h2></NavLink> : <NavLink className='nav-item' activeClassName='active' exact to='/'><h2><DashboardIcon className='icon'/> Unternehmensübersicht</h2></NavLink>}
                {this.props.company && this.props.company.abbreviation !== "ONEMG" ? <NavLink className='nav-item' activeClassName='active' to='/appointment-management'><h2><CalendarMonthIcon className='icon'/> Terminverwaltung</h2></NavLink> : null}
                {this.props.company && this.props.company.abbreviation !== "ONEMG" && this.props.user && (this.props.user.rights === 1 || this.props.user.rights === 2 || this.props.user.rights === 4) ? <NavLink className='nav-item' activeClassName='active' to='/offer-management'><h2><RequestPageIcon className='icon'/> Angebotsverwaltung</h2></NavLink> : null}
                {this.props.company && this.props.company.abbreviation !== "ONEMG" && this.props.user && (this.props.user.rights === 3 || this.props.user.rights === 4) ? <NavLink className='nav-item' activeClassName='active' to='/applications'><h2><AssignmentIndIcon className='icon'/> Bewerbungen</h2></NavLink> : null}
                {this.props.company && this.props.company.abbreviation !== "ONEMG" && this.props.user && (this.props.user.rights === 2 || this.props.user.rights === 3 || this.props.user.rights === 4) ? <NavLink className='nav-item' activeClassName='active' to='/reporting'><h2><BarChartIcon className='icon'/> Reporting</h2></NavLink> : null}
                {this.props.company && this.props.company.abbreviation !== "ONEMG" && this.props.user && (this.props.user.rights === 3 || this.props.user.rights === 4) ? <NavLink className='nav-item' activeClassName='active' to='/master-data'><h2><StorageIcon className='icon'/> Stammdaten</h2></NavLink> : null}
                {this.props.company && this.props.company.abbreviation !== "ONEMG" && this.props.user && (this.props.user.rights === 3 || this.props.user.rights === 4) ? <NavLink className='nav-item' activeClassName='active' to='/administration'><h2><SupervisorAccountIcon className='icon'/> Administration</h2></NavLink> : null}
                <NavLink className='nav-item' activeClassName='active' to='/settings'><h2><SettingsIcon className='icon'/> Einstellungen</h2></NavLink>
            </menu>
        )
    }
}

export default NavigationItems;