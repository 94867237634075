import React, {Component} from "react";
import './ImportDataDialog.css';
import Dialog from "@material-ui/core/Dialog";
import { parse } from 'papaparse';

//Components

class ImportDataDialog extends Component {

    state = {
        items:[],
        hovered:false
    }

    onDragOverHandler = (e) => {
        e.preventDefault();
    }

    onDropHandler = (e) => {
        e.preventDefault();
        Array.from(e.dataTransfer.files)
            .forEach(async file => {
                const text = await file.text();
                const result = parse(text, {header:true});
                console.log(result.data)
                this.props.addMultipleItems(result.data);
            });
        this.setState({hovered:false});
    }

    onDragEnterHandler = () => {
        this.setState({hovered:true});
    }

    onDragLeaveHandler = () => {
        this.setState({hovered:false});
    }

    render() {

        let style = null;

        if(this.state.hovered) {
            style = {
                backgroundColor:'gray'
            }
        }else {
            style = {
                backgroundColor:'white'
            }
        }

        return (
            <Dialog open={this.props.open} onClose={this.props.close} className='import-data-dialog'>
                <div
                    onDragOver={this.onDragOverHandler}
                    onDrop={this.onDropHandler}
                    onDragEnter={this.onDragEnterHandler}
                    onDragLeave={this.onDragLeaveHandler}
                    className='surrounder'
                    style={style}
                >
                    {this.props.type === 1 ? <p className='info-text'>Ziehen Sie eine .csv-Datei mit den Daten in das Feld<br/><br/>
                        Die Datei muss mindestens die Spalten "nr", "name" und "email" haben.<br/><br/>
                        Weitere mögliche Spalten sind "streetName", "streetNr", "addressAddition", "postCode", "city", "country" und "phone".
                    </p> : null}
                </div>
            </Dialog>
        )
    }
}

export default ImportDataDialog;