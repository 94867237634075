import React, {Component} from 'react';
import './ImpressumDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";

class ImpressumDialog extends Component {
    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='impressum-dialog'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle><h1>Impressum</h1></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>
                            Angaben gemäß § 5 TMG<br/><br/>

                            ONE M – FZCO<br/>
                            DDP Building A2<br/>
                            Dubai Silicon Oasis<br/>
                            United Arab Emirates<br/><br/>

                            Commercial License: 12698<br/><br/>

                            Vertreten durch:<br/>
                            Prof. Dr. Wolfram Jochen Berger<br/><br/>

                            E-Mail: info@one-m-system.de<br/>
                            www.one-m-system.de<br/><br/>

                            Urheberrecht<br/><br/>

                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                            unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                            Gebrauch gestattet.
                            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                            Urheberrechte Dritter
                            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                            trotzdem auf eine
                            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                            Hinweis. Bei
                            Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
                            entfernen.<br/><br/>
                            Quelle:
                            https://www.e-recht24.de/impressum-generator.html
                        </p>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

export default ImpressumDialog;