import React, {Component} from 'react';
import './Applications.css';

//Components
import MenuItem from '@material-ui/core/MenuItem';
import ToolButton from "../../../../reusable/ToolButton/ToolButton";
import TextField from "@material-ui/core/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../reusable/ListItem/ListItem";
import api from "../../../../api";
import ApplicationDialog from "./ApplicationDialog/ApplicationDialog";
import ToolFab from "../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@material-ui/icons/Add";
import SendApplicationOpportunityDialog from "./SendApplicationOpportunityDialog/SendApplicationOpportunityDialog";

class Applications extends Component {

    state = {
        activeItem:null,
        shownApplications: [],
        searchWord:'',
        searchType:'firstName',
        selectedItem:null,
        action:null,
        isNewItem:true,
        formattedItems:[],
        isSaved:true,

        //Dialogs
        showApplicationDialog:false,
        showSendApplicationOpportunityDialog:false
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownApplications = [];
            switch (this.state.searchType) {
                case "firstName":
                    this.props.applications.forEach(element => {
                        if(element.userInformation.firstName && element.userInformation.firstName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownApplications.push(element);
                        }
                    });
                    break;
                case "lastName":
                    this.props.applications.forEach(element => {
                        if(element.userInformation.lastName && element.lastName.userInformation.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownApplications.push(element);
                        }
                    });
                    break;
                case "companyName":
                    this.props.applications.forEach(element => {
                        if(element.userInformation.companyName && element.userInformation.companyName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownApplications.push(element);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownApplications:shownApplications});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    setIsSaved = (isSaved) => {
        this.setState({isSaved:isSaved});
    }

    //Dialog actions

    showApplicationDialog = (application) => {
        this.setState({activeItem:application,showApplicationDialog:true});
    }

    closeApplicationDialog = () => {
        this.setState({showApplicationDialog:false});
    }

    showSendApplicationOpportunityDialog = () => {
        this.setState({showSendApplicationOpportunityDialog:true});
    }

    closeSendApplicationOpportunityDialog = () => {
        this.setState({showSendApplicationOpportunityDialog:false});
    }

    render() {

        return (
            <div className='applications'>
                <ReactTooltip/>

                <ApplicationDialog
                    open={this.state.showApplicationDialog}
                    close={this.closeApplicationDialog}
                    showMessage={this.props.showMessage}
                    application={this.state.activeItem}
                    addApplicationToList={this.props.addApplicationToList}
                    removeApplicationFromList={this.props.removeApplicationFromList}
                    addUserToList={this.props.addUserToList}
                    searchOnClick={this.searchOnClick}

                    //File
                    displayEmployeeFile={this.props.displayEmployeeFile}
                />

                <SendApplicationOpportunityDialog
                    open={this.state.showSendApplicationOpportunityDialog}
                    close={this.closeSendApplicationOpportunityDialog}
                    showMessage={this.props.showMessage}
                    addApplicationToList={this.props.addApplicationToList}
                    removeApplicationFromList={this.props.removeApplicationFromList}
                />

                <div className='header-bar'>
                    <h1>Bewerbungen</h1>
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'firstName'}>Vorname</MenuItem>
                        <MenuItem key={1} value={'lastName'}>Nachname</MenuItem>
                        <MenuItem key={2} value={'companyName'}>Unternehmensname</MenuItem>
                    </TextField>
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownApplications.map((application,index) => (
                        <ListItem
                            key={index}
                            type={2}
                            item={application}
                            showItemDialog={() => this.showApplicationDialog(application)}
                        />
                    ))}
                </div>
                <ToolFab className='add-fab' onClick={this.showSendApplicationOpportunityDialog} aria-label="add">
                    <AddIcon/>
                </ToolFab>
            </div>
        )
    }
}

export default Applications;