import React, {Component} from 'react';
import './CommonSettings.css';

//Components
import TextField from "@material-ui/core/TextField";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import MenuItem from "@material-ui/core/MenuItem";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import api from "../../../../../api";
import FileDialog from "../../../../../reusable/FileDialog/FileDialog";
class CommonSettings extends Component {

    state = {
        user:this.props.user,
        hasChildren:false,

        // Dialog actions
        showUploadPictureDialog:false,
        showUploadIdFrontDialog:false,
        showUploadIdBackDialog:false,
        showUploadBusinessLicenceDialog:false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({user:this.props.user});
        }
    }

    signatureOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.signature = e.target.value;
        this.setState({user:user});
    }

    companyNameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.companyName = e.target.value;
        this.setState({user:user});
    }

    streetNameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.streetName = e.target.value;
        this.setState({user:user});
    }

    streetNrOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.streetNr = e.target.value;
        this.setState({user:user});
    }

    addressAdditionOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.addressAddition = e.target.value;
        this.setState({user:user});
    }

    postCodeOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.postCode = e.target.value;
        this.setState({user:user});
    }

    cityOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.city = e.target.value;
        this.setState({user:user});
    }

    countryOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.country = e.target.value;
        this.setState({user:user});
    }

    taxNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.taxNumber = e.target.value;
        this.setState({user:user});
    }

    vatNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.vatNumber = e.target.value;
        this.setState({user:user});
    }

    salutationOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.salutation = e.target.value;
        this.setState({user:user});
    }

    firstNameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.firstName = e.target.value;
        this.setState({user:user});
    }

    lastNameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.lastName = e.target.value;
        this.setState({user:user});
    }

    birthDateOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.birthDate = e.target.value;
        this.setState({user:user});
    }

    nationalityOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.nationality = e.target.value;
        this.setState({user:user});
    }

    emailOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.email = e.target.value;
        this.setState({user:user});
    }

    phoneOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.phone = e.target.value;
        this.setState({user:user});
    }

    jacketSizeOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.jacketSize = e.target.value;
        this.setState({user:user});
    }

    tshirtSizeOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.tshirtSize = e.target.value;
        this.setState({user:user});
    }

    accountHolderOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.accountHolder = e.target.value;
        this.setState({user:user});
    }

    ibanOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.iban = e.target.value;
        this.setState({user:user});
    }

    bicOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.bic = e.target.value;
        this.setState({user:user});
    }

    plannedEmploymentStartOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.plannedEmploymentStart = e.target.value;
        this.setState({user:user});
    }

    desiredSalaryOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.desiredSalary = e.target.value;
        this.setState({user:user});
    }

    idNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.idNumber = e.target.value;
        this.setState({user:user});
    }

    truthToggle = (e) => {
        this.setState({truth:e.target.checked});
    }

    dataPrivacyToggle = (e) => {
        this.setState({dataPrivacy:e.target.checked});
    }

    hasChildrenToggle = () => {
        if(this.state.hasChildren) {
            let user = this.state.user;
            user.userInformation.children = [];
            this.setState({hasChildren:!this.state.hasChildren,user:user});
        }else{
            this.setState({hasChildren:!this.state.hasChildren});
        }
    }

    childrenCountOnChangeHandler = (e) => {
        let user = this.state.user;
        let children = [];
        let formattedNumber = e.target.value;
        if(formattedNumber > 20) {
            formattedNumber = 20;
            this.props.showMessage(2,"Es sind maximal 20 Kinder möglich");
        }
        for(let i = 0; i < formattedNumber; i++) {
            children.push({
                firstName:'',
                lastName:'',
                birtDate:''
            });
        }
        user.userInformation.children = children;
        this.setState({user:user});
    }

    taxIdOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.taxId = e.target.value;
        this.setState({user:user});
    }

    childAllowanceOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.childAllowance = e.target.value;
        this.setState({user:user});
    }

    churchTaxToggle = (e) => {
        let user = this.state.user;
        user.userInformation.churchTax = !user.churchTax;
        this.setState({user:user});
    }

    taxBracketOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.taxBracket = e.target.value;
        this.setState({user:user});
    }

    healthInsuranceOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.healthInsurance = e.target.value;
        this.setState({user:user});
    }

    insuranceNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.insuranceNumber = e.target.value;
        this.setState({user:user});
    }

    insuranceStatusOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.insuranceStatus = e.target.value;
        this.setState({user:user});
    }

    nationalInsuranceNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.nationalInsuranceNumber = e.target.value;
        this.setState({user:user});
    }

    childFirstNameOnChangeHandler = (e,index) => {
        let user = this.state.user;
        user.userInformation.children[index].firstName = e.target.value;
        this.setState({user:user});
    }

    childLastNameOnChangeHandler = (e,index) => {
        let user = this.state.user;
        user.userInformation.children[index].lastName = e.target.value;
        this.setState({user:user});
    }

    childBirthDateOnChangeHandler = (e,index) => {
        let user = this.state.user;
        user.userInformation.children[index].birthDate = e.target.value;
        this.setState({user:user});
    }

    uploadPicture = (user,multipartFile) => {
        let url = '/users/upload-file/picture';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Datei wurde erfolgreich hochgeladen");
                let user = this.state.user;
                user.userInformation.pictureFileStorageLocation = response.data;
                this.setState({user:user});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    uploadIdFront = (user,multipartFile) => {
        let url = '/users/upload-file/id-front';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Datei wurde erfolgreich hochgeladen");
                let user = this.state.user;
                user.userInformation.idFrontFileStorageLocation = response.data;
                this.setState({user:user});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    uploadIdBack = (user,multipartFile) => {
        let url = '/users/upload-file/id-back';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Datei wurde erfolgreich hochgeladen");
                let user = this.state.user;
                user.userInformation.idBackFileStorageLocation = response.data;
                this.setState({user:user});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    uploadBusinessLicence = (user,multipartFile) => {
        let url = '/users/upload-file/business-licence';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Datei wurde erfolgreich hochgeladen");
                let user = this.state.user;
                user.userInformation.businessLicenceFileStorageLocation = response.data;
                this.setState({user:user});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    updateUserInformation = (userInformation) => {
        let url = "/users/user-information";
        api.put(url,userInformation)
            .then(response => {
                this.props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert")
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    displayEmployeeFileOnClickHandler = (fileStorageLocation) => {
        let pathFileRequest = {
            username: this.state.user.username,
            fileStorageLocation: fileStorageLocation
        }
        this.props.displayEmployeeFile(pathFileRequest);
    }

    // Dialog actions

    showUploadPictureDialog = () => {
        this.setState({showUploadPictureDialog:true});
    }

    closeUploadPictureDialog = () => {
        this.setState({showUploadPictureDialog:false});
    }

    showUploadIdFrontDialog = () => {
        this.setState({showUploadIdFrontDialog:true});
    }

    closeUploadIdFrontDialog = () => {
        this.setState({showUploadIdFrontDialog:false});
    }

    showUploadIdBackDialog = () => {
        this.setState({showUploadIdBackDialog:true});
    }

    closeUploadIdBackDialog = () => {
        this.setState({showUploadIdBackDialog:false});
    }

    showUploadBusinessLicenceDialog = () => {
        this.setState({showUploadBusinessLicenceDialog:true});
    }

    closeUploadBusinessLicenceDialog = () => {
        this.setState({showUploadBusinessLicenceDialog:false});
    }

    render() {
        return (
            <div className='common-settings'>

                <FileDialog
                    open={this.state.showUploadPictureDialog}
                    close={this.closeUploadPictureDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.uploadPicture}
                    item={this.state.user}
                />

                <FileDialog
                    open={this.state.showUploadIdFrontDialog}
                    close={this.closeUploadIdFrontDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.uploadIdFront}
                    item={this.state.user}
                />

                <FileDialog
                    open={this.state.showUploadIdBackDialog}
                    close={this.closeUploadIdBackDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.uploadIdBack}
                    item={this.state.user}
                />

                <FileDialog
                    open={this.state.showUploadBusinessLicenceDialog}
                    close={this.closeUploadBusinessLicenceDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.uploadBusinessLicence}
                    item={this.state.user}
                />

                <h1>Allgemein</h1>
                {this.props.company && this.props.company.abbreviation && this.props.company.abbreviation !== "ONEMG" && this.state.user && this.state.user.userInformation ?
                    <div className='surrounder'>
                        {(this.state.user.rights === 1 || this.state.user.rights === 2) ?
                            <div>
                            {this.state.user.type === "distributor" ?
                                <div>
                                    <div>
                                        <h2>E-Mail Versand</h2>
                                        <TextField onChange={this.signatureOnChangeHandler} value={this.state.user.userInformation.signature ? this.state.user.userInformation.signature : ""} multiline minRows={7} className='field multiline' label='E-Mail Signatur'/>
                                    </div>
                                    <div>
                                        <h2>Angaben zum Unternehmen</h2>
                                        <TextField onChange={this.companyNameOnChangeHandler} value={this.state.user.userInformation.companyName ? this.state.user.userInformation.companyName : ""} className='field' label='Unternehmensname (inkl. Rechtsf.)'/>
                                        <TextField onChange={this.streetNameOnChangeHandler} value={this.state.user.userInformation.streetName ? this.state.user.userInformation.streetName : ""} className='field' label='Straße'/>
                                        <TextField onChange={this.streetNrOnChangeHandler} value={this.state.user.userInformation.streetNr ? this.state.user.userInformation.streetNr : ""} className='field' label='Hausnummer'/>
                                        <TextField onChange={this.addressAdditionOnChangeHandler} value={this.state.user.userInformation.addressAddition ? this.state.user.userInformation.addressAddition : ""} helperText='optional' className='field' label='Adresszusatz'/>
                                        <TextField onChange={this.postCodeOnChangeHandler} value={this.state.user.userInformation.postCode ? this.state.user.userInformation.postCode : ""} className='field' label='Postleitzahl'/>
                                        <TextField onChange={this.cityOnChangeHandler} value={this.state.user.userInformation.city ? this.state.user.userInformation.city : ""} className='field' label='Ort'/>
                                        <TextField onChange={this.countryOnChangeHandler} value={this.state.user.userInformation.country ? this.state.user.userInformation.country : ""} className='field' label='Land'>
                                            {this.props.countries}
                                        </TextField>
                                        <TextField onChange={this.taxNumberOnChangeHandler} value={this.state.user.userInformation.taxNumber ? this.state.user.userInformation.taxNumber : ""} className='field' label='Steuernummer'/>
                                        <TextField onChange={this.vatNumberOnChangeHandler} value={this.state.user.userInformation.vatNumber ? this.state.user.userInformation.vatNumber : ""} className='field' label='Umsatzsteuernummer'/>
                                    </div>
                                    <div>
                                        <h2>Geschäftsinhaber</h2>
                                        <TextField onChange={this.salutationOnChangeHandler} value={this.state.user.userInformation.salutation} select className='field' label='Anrede'>
                                            <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                            <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                                        </TextField>
                                        <TextField onChange={this.firstNameOnChangeHandler} value={this.state.user.userInformation.firstName ? this.state.user.userInformation.firstName : ""} className='field' label='Vorname'/>
                                        <TextField onChange={this.lastNameOnChangeHandler} value={this.state.user.userInformation.lastName ? this.state.user.userInformation.lastName : ""} className='field' label='Nachname'/>
                                        <TextField onChange={this.birthDateOnChangeHandler} value={this.state.user.userInformation.birthDate ? this.state.user.userInformation.birthDate : ""} type='date' className='field' label='Geburtsdatum'/>
                                        <TextField onChange={this.emailOnChangeHandler} value={this.state.user.userInformation.email ? this.state.user.userInformation.email : ""} type='email' className='field' label='E-Mail'/>
                                        <TextField onChange={this.phoneOnChangeHandler} value={this.state.user.userInformation.phone ? this.state.user.userInformation.phone : ""} className='field' label='Telefon'/>
                                        <TextField onChange={this.jacketSizeOnChangeHandler} value={this.state.user.userInformation.jacketSize} select className='field' label='Jacken Größe'>
                                            <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                            <MenuItem key={1} value={'s'}>S</MenuItem>
                                            <MenuItem key={2} value={'m'}>M</MenuItem>
                                            <MenuItem key={3} value={'l'}>L</MenuItem>
                                            <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                            <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                        </TextField>
                                        <TextField onChange={this.tshirtSizeOnChangeHandler} value={this.state.user.userInformation.tshirtSize} select className='field' label='T-Shirt Größe'>
                                            <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                            <MenuItem key={1} value={'s'}>S</MenuItem>
                                            <MenuItem key={2} value={'m'}>M</MenuItem>
                                            <MenuItem key={3} value={'l'}>L</MenuItem>
                                            <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                            <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                        </TextField>
                                    </div>
                                    <div>
                                        <h2>Bankverbindung</h2>
                                        <TextField onChange={this.accountHolderOnChangeHandler} value={this.state.user.userInformation.accountHolder ? this.state.user.userInformation.accountHolder : ""} className='field' label='Kontoinhaber'/>
                                        <TextField onChange={this.ibanOnChangeHandler} value={this.state.user.userInformation.iban ? this.state.user.userInformation.iban : ""} className='field' label='IBAN'/>
                                        <TextField onChange={this.bicOnChangeHandler} value={this.state.user.userInformation.bic ? this.state.user.userInformation.bic : ""} className='field' label='BIC'/>
                                        <TextField onChange={this.plannedEmploymentStartOnChangeHandler} value={this.state.user.userInformation.plannedEmploymentStart ? this.state.user.userInformation.plannedEmploymentStart : ""} type='date' className='field' label='Geplanter Beschäftigungsstart'/>
                                        <TextField onChange={this.desiredSalaryOnChangeHandler} value={this.state.user.userInformation.desiredSalary ? this.state.user.userInformation.desiredSalary : ""} helperText='optional' type='number' className='field' label='Wunschgehalt (netto)'/>
                                        <div className='field'>
                                            <p>Bild</p>
                                            {this.state.user.userInformation.pictureFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.state.user.userInformation.pictureFileStorageLocation)}/> :
                                                <UploadFileIcon onClick={this.showUploadPictureDialog} className='icon'/>}
                                        </div>
                                        <div className='field'>
                                            <p>Personalausweis Vorderseite</p>
                                            {this.state.user.userInformation.idFrontFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.state.user.userInformation.idFrontFileStorageLocation)}/> :
                                                <UploadFileIcon onClick={this.showUploadIdFrontDialog} className='icon'/>}
                                        </div>
                                        <div className='field'>
                                            <p>Personalausweis Rückseite</p>
                                            {this.state.user.userInformation.idBackFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.state.user.userInformation.idBackFileStorageLocation)}/> :
                                                <UploadFileIcon onClick={this.showUploadIdBackDialog} className='icon'/>}
                                        </div>
                                        <TextField onChange={this.idNumberOnChangeHandler} value={this.state.user.userInformation.idNumber ? this.state.user.userInformation.idNumber : ""} className='field' label='Personalausweis Nummer'/>
                                        <div className='field'>
                                            <p>Gewerbescheinauszug</p>
                                            {this.state.user.userInformation.businessLicenceFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.state.user.userInformation.businessLicenceFileStorageLocation)}/> :
                                                <UploadFileIcon onClick={this.showUploadBusinessLicenceDialog} className='icon'/>}
                                        </div>
                                    </div>
                                </div> : this.state.user.type !== "distributor" ?
                                    <div>
                                        <div>
                                            <h2>E-Mail Versand</h2>
                                            <TextField onChange={this.signatureOnChangeHandler} value={this.state.user.userInformation.signature ? this.state.user.userInformation.signature : ""} multiline minRows={7} className='field multiline' label='E-Mail Signatur'/>
                                        </div>
                                        <div>
                                            <h2>Angaben zur Person</h2>
                                            <TextField onChange={this.salutationOnChangeHandler} value={this.state.user.userInformation.salutation} select className='field' label='Anrede'>
                                                <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                                <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                                            </TextField>
                                            <TextField onChange={this.firstNameOnChangeHandler} value={this.state.user.userInformation.firstName ? this.state.user.userInformation.firstName : ""} className='field' label='Vorname'/>
                                            <TextField onChange={this.lastNameOnChangeHandler} value={this.state.user.userInformation.lastName ? this.state.user.userInformation.lastName : ""} className='field' label='Nachname'/>
                                            <TextField onChange={this.streetNameOnChangeHandler} value={this.state.user.userInformation.streetName ? this.state.user.userInformation.streetName : ""} className='field' label='Straße'/>
                                            <TextField onChange={this.streetNrOnChangeHandler} value={this.state.user.userInformation.streetNr ? this.state.user.userInformation.streetNr : ""} className='field' label='Hausnummer'/>
                                            <TextField onChange={this.addressAdditionOnChangeHandler} value={this.state.user.userInformation.addressAddition ? this.state.user.userInformation.addressAddition : ""} helperText='optional' className='field' label='Adresszusatz'/>
                                            <TextField onChange={this.postCodeOnChangeHandler} value={this.state.user.userInformation.postCode ? this.state.user.userInformation.postCode : ""} className='field' label='Postleitzahl'/>
                                            <TextField onChange={this.cityOnChangeHandler} value={this.state.user.userInformation.city ? this.state.user.userInformation.city : ""} className='field' label='Stadt'/>
                                            <TextField onChange={this.birthDateOnChangeHandler} value={this.state.user.userInformation.birthDate ? this.state.user.userInformation.birthDate : ""} type='date' className='field' label='Geburtsdatum'/>
                                            <TextField onChange={this.nationalityOnChangeHandler} value={this.state.user.userInformation.nationality ? this.state.user.userInformation.nationality : ""} select className='field' label='Staatsangehörigkeit'>
                                                {this.props.countries}
                                            </TextField>
                                            <TextField onChange={this.emailOnChangeHandler} value={this.state.user.userInformation.email ? this.state.user.userInformation.email : ""} type='email' className='field' label='E-Mail'/>
                                            <TextField onChange={this.phoneOnChangeHandler} value={this.state.user.userInformation.phone ? this.state.user.userInformation.phone : ""} className='field' label='Telefon'/>
                                            <TextField onChange={this.jacketSizeOnChangeHandler} value={this.state.user.userInformation.jacketSize} select className='field' label='Jacken Größe'>
                                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                            </TextField>
                                            <TextField onChange={this.tshirtSizeOnChangeHandler} value={this.state.user.userInformation.tshirtSize} select className='field' label='T-Shirt Größe'>
                                                <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                                <MenuItem key={1} value={'s'}>S</MenuItem>
                                                <MenuItem key={2} value={'m'}>M</MenuItem>
                                                <MenuItem key={3} value={'l'}>L</MenuItem>
                                                <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                                <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                            </TextField>
                                        </div>
                                        <div className='field'>
                                            <p>Bild</p>
                                            {this.state.user.userInformation.pictureFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.state.user.userInformation.pictureFileStorageLocation)}/> :
                                                <UploadFileIcon onClick={this.showUploadPictureDialog} className='icon'/>}
                                        </div>
                                        <div className='field'>
                                            <p>Personalausweis Vorderseite</p>
                                            {this.state.user.userInformation.idFrontFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.state.user.userInformation.idFrontFileStorageLocation)}/> :
                                                <UploadFileIcon onClick={this.showUploadIdFrontDialog} className='icon'/>}
                                        </div>
                                        <div className='field'>
                                            <p>Personalausweis Rückseite</p>
                                            {this.state.user.userInformation.idBackFileStorageLocation ? <FileOpenIcon className='icon' onClick={() => this.displayEmployeeFileOnClickHandler(this.state.user.userInformation.idBackFileStorageLocation)}/> :
                                                <UploadFileIcon onClick={this.showUploadIdBackDialog} className='icon'/>}
                                        </div>
                                        <div>
                                            <h2>Bankverbindung</h2>
                                            <TextField onChange={this.accountHolderOnChangeHandler} value={this.state.user.userInformation.accountHolder ? this.state.user.userInformation.accountHolder : ""} className='field' label='Kontoinhaber'/>
                                            <TextField onChange={this.ibanOnChangeHandler} value={this.state.user.userInformation.iban ? this.state.user.userInformation.iban : ""} className='field' label='IBAN'/>
                                            <TextField onChange={this.bicOnChangeHandler} value={this.state.user.userInformation.bic ? this.state.user.userInformation.bic : ""} className='field' label='BIC'/>
                                        </div>
                                        <div>
                                            <h2>Lohnsteuer</h2>
                                            <TextField onChange={this.taxIdOnChangeHandler} value={this.state.user.userInformation.taxId ? this.state.user.userInformation.taxId : ""} className='field' label='Steueridentifikationsnummer'/>
                                            <TextField onChange={this.taxBracketOnChangeHandler} value={this.state.user.userInformation.taxBracket ? this.state.user.userInformation.taxBracket : ""} className='field' label='Steuerklasse'/>
                                            <TextField onChange={this.childAllowanceOnChangeHandler} value={this.state.user.userInformation.childAllowance ? this.state.user.userInformation.childAllowance : ""} className='field' label='Kinderfreibetrag'/>
                                            <FormControlLabel onChange={this.churchTaxToggle} className='field' control={<Checkbox checked={this.state.user.userInformation.churchTax}/>} label="Kirchensteuerpflichtig" />
                                        </div>
                                        <div>
                                            <h2>Sozialversicherung</h2>
                                            <TextField onChange={this.healthInsuranceOnChangeHandler} value={this.state.user.userInformation.healthInsurance ? this.state.user.userInformation.healthInsurance : ""} className='field' label='Krankenkasse'/>
                                            <TextField onChange={this.insuranceNumberOnChangeHandler} value={this.state.user.userInformation.insuranceNumber ? this.state.user.userInformation.insuranceNumber : ""} className='field' label='Versichertennummer'/>
                                            <TextField onChange={this.insuranceStatusOnChangeHandler} value={this.state.user.userInformation.insuranceStatus ? this.state.user.userInformation.insuranceStatus : ""} select className='field' label='Versichertenstatus'>
                                                <MenuItem key={0} value={'statutory'}>gesetzlich</MenuItem>
                                                <MenuItem key={1} value={'private'}>privat</MenuItem>
                                            </TextField>
                                            <TextField onChange={this.nationalInsuranceNumberOnChangeHandler} value={this.state.user.userInformation.nationalInsuranceNumber ? this.state.user.userInformation.nationalInsuranceNumber : ""} className='field' label='Sozialversicherungsnummer'/>
                                            <FormControlLabel onChange={this.hasChildrenToggle} className='field' control={<Checkbox checked={this.state.hasChildren}/>} label="Ich habe Kinder" />
                                            {this.state.hasChildren ? <TextField onChange={this.childrenCountOnChangeHandler} value={this.state.user.userInformation.children ? this.state.user.userInformation.children.length : 0} type='number' className='field' label='Anzahl Kinder'/> : null}
                                            {this.state.user.userInformation.children ? this.state.user.userInformation.children.map((child,index) => (
                                                <div className='field'>
                                                    <h2>{index + 1}. Kind</h2>
                                                    <TextField value={child.firstName} onChange={(e) => this.childFirstNameOnChangeHandler(e,index)} className='field' label='Vorname'/>
                                                    <TextField value={child.lastName} onChange={(e) => this.childLastNameOnChangeHandler(e,index)} className='field' label='Nachname'/>
                                                    <TextField value={child.birthDate} type='date' onChange={(e) => this.childBirthDateOnChangeHandler(e,index)} className='field' label='Geburtsdatum'/>
                                                </div>
                                            )) : null}
                                        </div>
                                    </div> : null}
                        </div> :
                            <div>
                                <div>
                                    <h2>E-Mail Versand</h2>
                                    <TextField onChange={this.signatureOnChangeHandler} value={this.state.user.userInformation.signature ? this.state.user.userInformation.signature : ""} multiline minRows={7} className='field multiline' label='E-Mail Signatur'/>
                                </div>
                                <h2>Angaben zur Person</h2>
                                <TextField onChange={this.salutationOnChangeHandler} value={this.state.user.userInformation.salutation} select className='field' label='Anrede'>
                                    <MenuItem key={0} value={'Herr'}>Herr</MenuItem>
                                    <MenuItem key={1} value={'Frau'}>Frau</MenuItem>
                                </TextField>
                                <TextField onChange={this.firstNameOnChangeHandler} value={this.state.user.userInformation.firstName ? this.state.user.userInformation.firstName : ""} className='field' label='Vorname'/>
                                <TextField onChange={this.lastNameOnChangeHandler} value={this.state.user.userInformation.lastName ? this.state.user.userInformation.lastName : ""} className='field' label='Nachname'/>
                                <TextField onChange={this.streetNameOnChangeHandler} value={this.state.user.userInformation.streetName ? this.state.user.userInformation.streetName : ""} className='field' label='Straße'/>
                                <TextField onChange={this.streetNrOnChangeHandler} value={this.state.user.userInformation.streetNr ? this.state.user.userInformation.streetNr : ""} className='field' label='Hausnummer'/>
                                <TextField onChange={this.addressAdditionOnChangeHandler} value={this.state.user.userInformation.addressAddition ? this.state.user.userInformation.addressAddition : ""} helperText='optional' className='field' label='Adresszusatz'/>
                                <TextField onChange={this.postCodeOnChangeHandler} value={this.state.user.userInformation.postCode ? this.state.user.userInformation.postCode : ""} className='field' label='Postleitzahl'/>
                                <TextField onChange={this.cityOnChangeHandler} value={this.state.user.userInformation.city ? this.state.user.userInformation.city : ""} className='field' label='Stadt'/>
                                <TextField onChange={this.birthDateOnChangeHandler} value={this.state.user.userInformation.birthDate ? this.state.user.userInformation.birthDate : ""} type='date' className='field' label='Geburtsdatum'/>
                                <TextField onChange={this.nationalityOnChangeHandler} value={this.state.user.userInformation.nationality ? this.state.user.userInformation.nationality : ""} select className='field' label='Staatsangehörigkeit'>
                                    {this.props.countries}
                                </TextField>
                                <TextField onChange={this.emailOnChangeHandler} value={this.state.user.userInformation.email ? this.state.user.userInformation.email : ""} type='email' className='field' label='E-Mail'/>
                                <TextField onChange={this.phoneOnChangeHandler} value={this.state.user.userInformation.phone ? this.state.user.userInformation.phone : ""} className='field' label='Telefon'/>
                                <TextField onChange={this.jacketSizeOnChangeHandler} value={this.state.user.userInformation.jacketSize} select className='field' label='Jacken Größe'>
                                    <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                    <MenuItem key={1} value={'s'}>S</MenuItem>
                                    <MenuItem key={2} value={'m'}>M</MenuItem>
                                    <MenuItem key={3} value={'l'}>L</MenuItem>
                                    <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                    <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                </TextField>
                                <TextField onChange={this.tshirtSizeOnChangeHandler} value={this.state.user.userInformation.tshirtSize} select className='field' label='T-Shirt Größe'>
                                    <MenuItem key={0} value={'xs'}>XS</MenuItem>
                                    <MenuItem key={1} value={'s'}>S</MenuItem>
                                    <MenuItem key={2} value={'m'}>M</MenuItem>
                                    <MenuItem key={3} value={'l'}>L</MenuItem>
                                    <MenuItem key={4} value={'xl'}>XL</MenuItem>
                                    <MenuItem key={5} value={'xxl'}>XXL</MenuItem>
                                </TextField>
                            </div>}
                    </div> :
                    <div className='surrounder'>
                        {this.props.company && this.props.company.abbreviation === "ONEMG" ? <h2>Das ist für diesen Account nicht verfügbar</h2> : <h2>Lädt...</h2>}
                    </div>}
                {this.props.company && this.props.company.abbreviation !== "ONEMG" ? <ToolButton onClick={() => this.updateUserInformation(this.state.user.userInformation)} main className='save-button'>Speichern</ToolButton> : null}
            </div>
        )
    }
}

export default CommonSettings;