import React, {Component} from "react";
import './BottomPanel.css';

//Component

class BottomPanel extends Component {

    render() {
        return (
            <div className='bottom-panel'>

            </div>
        )
    }
}

export default BottomPanel;