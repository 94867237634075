import React, {Component} from "react";
import './CompanyDashboard.css';
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import CompanyDataReportItem from "./CompanyDataReportItem/CompanyDataReportItem";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

//Components

class CompanyDashboard extends Component {

    state = {
        file:null
    }

    render() {

        let content;

        if(this.props.companyDataReports) {
            content = <div>
                {this.props.companyDataReports.map(companyDataReport => (
                    <CompanyDataReportItem
                        companyDataReport={companyDataReport}
                    />
                    ))}
            </div>
        }else {
            content = <Box sx={{ marginTop: '20%' }}>
                <CircularProgress className='load-icon'/>
            </Box>
        }

        return (
            <div className='company-dashboard'>
                <h1>Unternehmensübersicht</h1>
                <div className='item-list'>
                    {content}
                </div>
            </div>
        )
    }
}

export default CompanyDashboard;