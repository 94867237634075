import React, {Component} from "react";
import './AppointmentDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from "react-tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";

class AppointmentDialog extends Component {

    state = {
        appointment:this.props.appointment,
        isNewCustomer:false,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({appointment:this.props.appointment});
        }
    }

    customerOnChangeHandler = (value) => {
        let appointment = this.state.appointment;
        appointment.customer = value;
        this.setState({appointment:appointment});
    }

    customerNrOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.nr = e.target.value;
        this.setState({appointment:appointment});
    }

    customerStreetNameOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.streetName = e.target.value;
        this.setState({appointment:appointment});
    }

    customerStreetNrOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.streetNr = e.target.value;
        this.setState({appointment:appointment});
    }

    customerAddressAdditionOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.addressAddition = e.target.value;
        this.setState({appointment:appointment});
    }

    customerPostCodeOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.postCode = e.target.value;
        this.setState({appointment:appointment});
    }

    customerCityOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.city = e.target.value;
        this.setState({appointment:appointment});
    }

    customerCountryOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.country = e.target.value;
        this.setState({appointment:appointment});
    }

    customerEmailOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.email = e.target.value;
        this.setState({appointment:appointment});
    }

    customerPhoneOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.customer.phone = e.target.value;
        this.setState({appointment:appointment});
    }

    descriptionOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.description = e.target.value;
        this.setState({appointment:appointment});
    }

    dateTimeOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.dateTime = e.target.value;
        this.setState({appointment:appointment});
    }

    statusOnChangeHandler = (e) => {
        let appointment = this.state.appointment;
        appointment.status = e.target.value;
        this.setState({appointment:appointment});
    }

    updateAppointmentOnClickHandler = (appointment) => {
        if(appointment.dateTime && appointment.customer) {
            if(appointment.id) {
                this.props.updateAppointment(appointment);
            }else{
                this.props.createOwnAppointment(appointment);
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    render() {

        const filter = createFilterOptions();

        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='appointment-dialog'>
                <ReactTooltip/>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle><h1>Termin</h1></DialogTitle>
                    <DialogContent>
                        <div>
                            <h2>Status</h2>
                            <TextField value={this.state.appointment ? this.state.appointment.status : ''} onChange={this.statusOnChangeHandler} select className='field' label='Status'>
                                <MenuItem key={1} value={1}>aktiv</MenuItem>
                                <MenuItem key={2} value={2}>abgeschlossen</MenuItem>
                                <MenuItem key={3} value={3}>abgebrochen</MenuItem>
                            </TextField>
                        </div>
                        <div>
                            <h2>Zeit</h2>
                            <TextField onChange={this.dateTimeOnChangeHandler} className='field' value={this.state.appointment && this.state.appointment.dateTime ? this.state.appointment.dateTime : ''} type='datetime-local' label="Uhrzeit und Datum"/>
                        </div>
                        <div>
                            <h2>Kunde</h2>
                            <Autocomplete
                                disabled={this.state.appointment && this.state.appointment.id}
                                value={this.state.appointment && this.state.appointment.customer ? this.state.appointment.customer.name: ''}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        this.customerOnChangeHandler({
                                            name: newValue,
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        this.customerOnChangeHandler({
                                            name: newValue.inputValue,
                                        });
                                        this.setState({isNewCustomer:true});
                                    } else {
                                        this.customerOnChangeHandler(newValue);
                                        this.setState({isNewCustomer:false});
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.name);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            name: `Erstellen "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="customers"
                                className='field'
                                options={this.props.customers}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                freeSolo
                                renderInput={(params) => (
                                    <TextField {...params} label="Kunde" />
                                )}
                            />
                            {this.state.appointment && !this.state.appointment.id && this.state.isNewCustomer ?
                            <div>
                                <TextField className='field' label='Kundennummer' value={this.state.appointment.customer.nr} onChange={this.customerNrOnChangeHandler}/>
                                <TextField className='field' label='Straße' value={this.state.appointment.customer.streetName} onChange={this.customerStreetNameOnChangeHandler}/>
                                <TextField className='field' label='Hausnummer' value={this.state.appointment.customer.streetNr} onChange={this.customerStreetNrOnChangeHandler}/>
                                <TextField className='field' label='Adresszusatz' value={this.state.appointment.customer.addressAddition} onChange={this.customerAddressAdditionOnChangeHandler}/>
                                <TextField className='field' label='Postleitzahl' value={this.state.appointment.customer.postCode} onChange={this.customerPostCodeOnChangeHandler}/>
                                <TextField className='field' label='Stadt' value={this.state.appointment.customer.city} onChange={this.customerCityOnChangeHandler}/>
                                <TextField className='field' label='Land' value={this.state.appointment.customer.country} select onChange={this.customerCountryOnChangeHandler}>
                                    {this.props.countries}
                                </TextField>
                                <TextField className='field' label='E-Mail' value={this.state.appointment.customer.email} onChange={this.customerEmailOnChangeHandler}/>
                                <TextField className='field' label='Telefon' value={this.state.appointment.customer.phone} onChange={this.customerPhoneOnChangeHandler}/>
                            </div> : null}
                            {this.state.appointment && this.state.appointment.id ?
                            <div>
                                <TextField className='field' label='Kundennummer' disabled value={this.state.appointment.customer.nr}/>
                                <TextField className='field' label='Straße' disabled value={this.state.appointment.customer.streetName}/>
                                <TextField className='field' label='Hausnummer' disabled value={this.state.appointment.customer.streetNr}/>
                                <TextField className='field' label='Adresszusatz' disabled value={this.state.appointment.customer.addressAddition}/>
                                <TextField className='field' label='Postleitzahl' disabled value={this.state.appointment.customer.postCode}/>
                                <TextField className='field' label='Stadt' disabled value={this.state.appointment.customer.city}/>
                                <TextField className='field' label='Land' disabled value={this.state.appointment.customer.country}/>
                                <TextField className='field' label='E-Mail' disabled value={this.state.appointment.customer.email}/>
                                <TextField className='field' label='Telefon' disabled value={this.state.appointment.customer.phone}/>
                            </div> : null}
                        </div>
                        <div>
                            <h2>Beschreibung</h2>
                            <TextField disabled={this.props.appointment && this.props.appointment.id} inputProps={{maxLength:200}} value={this.state.appointment ? this.state.appointment.description : ''} label="Beschreibung" helperText='optional' multiline className='multiline-input' minRows={7} onChange={this.descriptionOnChangeHandler}/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <ToolButton className='button' main onClick={() => this.updateAppointmentOnClickHandler(this.state.appointment)}>Speichern</ToolButton>
                    </DialogActions>
            </Dialog>
        )
    }
}

export default AppointmentDialog;