import React, {Component} from "react";
import './OfferDialog.css';

//Components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@material-ui/icons/Close';
import ReactTooltip from "react-tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import DocumentPosition from "../../../../../reusable/DocumentPosition/DocumentPosition";
import RequestPageIcon from '@mui/icons-material/RequestPage';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from '@mui/icons-material/Delete';
import FileDialog from "../../../../../reusable/FileDialog/FileDialog";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

class OfferDialog extends Component {

    state = {
        offer:this.props.offer,
        isNewCustomer:false,
        showAppendFileDialog:false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({offer:this.props.offer});
        }
    }

    statusOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.status = e.target.value;
        this.setState({offer:offer});
    }

    customerOnChangeHandler = (value) => {
        let offer = this.state.offer;
        offer.customer = value;
        this.setState({offer:offer});
    }

    craftsmanOnChangeHandler = (craftsman) => {
        let offer = this.state.offer;
        offer.craftsman = craftsman;
        this.setState({offer:offer});
    }

    customerNrOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.nr = e.target.value;
        this.setState({offer:offer});
    }

    customerStreetNameOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.streetName = e.target.value;
        this.setState({offer:offer});
    }

    customerStreetNrOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.streetNr = e.target.value;
        this.setState({offer:offer});
    }

    customerAddressAdditionOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.addressAddition = e.target.value;
        this.setState({offer:offer});
    }

    customerPostCodeOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.postCode = e.target.value;
        this.setState({offer:offer});
    }

    customerCityOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.city = e.target.value;
        this.setState({offer:offer});
    }
    customerCountryOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.country = e.target.value;
        this.setState({offer:offer});
    }

    customerEmailOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.email = e.target.value;
        this.setState({offer:offer});
    }

    customerPhoneOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.customer.phone = e.target.value;
        this.setState({offer:offer});
    }

    addDocumentPosition = () => {
        let offer = this.state.offer;
        offer.documentPositions.push({
            article:{
                nr:''
            },
            count:'',
            price:0
        });
        this.setIsSaved(false);
        this.setState({offer:offer});
    }

    documentPositionArticleOnChange = (e, index) => {
        let offer = this.state.offer;
        offer.documentPositions[index].article = e;
        this.setIsSaved(false);
        this.setState({offer:offer});
        this.checkPositionPrice(index);
    }

    documentPositionCountOnChange = (e, index) => {
        let offer = this.state.offer;
        offer.documentPositions[index].count = e.target.value >= 0 ? e.target.value : 0;
        this.setIsSaved(false);
        this.setState({offer:offer});
        this.checkPositionPrice(index);
    }

    articlePriceOnChange = (e,index) => {
        let offer = this.state.offer;
        offer.documentPositions[index].articlePrice = e.target.value;
        this.setIsSaved(false);
        this.setState({offer:offer});
        this.checkPositionPrice(index);
    }

    provisionOnChangeHandler = (e,index) => {
        let offer = this.state.offer;
        offer.documentPositions[index].provision =  e.target.value >= 0 ? e.target.value : 0;
        this.setIsSaved(false);
        this.setState({offer:offer});
        this.checkPositionPrice(index);
    }

    removeDocumentPosition = (index) => {
        let offer = this.state.offer;
        offer.documentPositions.splice(index,1);
        for(let i = 0; i < offer.documentPositions.length; i++) {
            offer.documentPositions[i].index = i;
        }
        this.setIsSaved(false);
        this.setState({offer:offer});
    };

    dateOnChangeHandler = (e) => {
        let offer = this.state.offer;
        offer.date = e.target.value;
        this.setState({offer:offer});
    }

    checkPositionPrice = (index) => {
        if(this.state.offer.documentPositions[index].article.name && this.state.offer.documentPositions[index].count !== '') {
            let offer = this.state.offer;
            let articlePrice = 0;
            articlePrice = parseFloat(offer.documentPositions[index].article.price * offer.documentPositions[index].count);
            offer.documentPositions[index].articlePrice = articlePrice;
            offer.documentPositions[index].price = parseFloat(articlePrice) + parseFloat(offer.documentPositions[index].provision);
            this.setState({offer:offer});
        }
    }

    setIsSaved = (isSaved) => {
        this.setState({isSaved:isSaved});
    }

    saveOfferOnClickHandler = (offer) => {
        let checked = true;
        for(let i = 0; i < offer.documentPositions.length; i++) {
            if(offer.documentPositions[i].article.nr === ''
                || offer.documentPositions[i].count === ''
                || offer.documentPositions[i].count <= 0
                || offer.documentPositions[i].price === ''
                || !offer.documentPositions[i].provision
            ) {
                checked = false;
            }
        }
        if(offer.customer && offer.date && offer.documentPositions.length > 0 && checked) {
            if(offer.id) {
                this.props.updateOffer(offer);
            }else{
                this.props.createOffer(offer);
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    // Dialog actions

    showAppendFileDialog = (item) => {
        this.setState({activeItem:item,showAppendFileDialog:true});
    }

    closeAppendFileDialog = () => {
        this.setState({showAppendFileDialog:false});
    }

    showRemoveOfferFileDialog = () => {
        this.setState({showRemoveOfferFileDialog:true});
    }

    closeRemoveOfferFileDialog = () => {
        this.setState({showRemoveOfferFileDialog:false});
    }

    showCompleteOfferDialog = () => {
        this.setState({showCompleteOfferDialog:true});
    }

    closeCompleteOfferDialog = () => {
        this.setState({showCompleteOfferDialog:false});
    }

    showCancelOfferDialog = () => {
        this.setState({showCancelOfferDialog:true});
    }

    closeCancelOfferDialog = () => {
        this.setState({showCancelOfferDialog:false});
    }

    showReactivateOfferDialog = () => {
        this.setState({showReactivateOfferDialog:true});
    }

    closeReactivateOfferDialog = () => {
        this.setState({showReactivateOfferDialog:false});
    }

    render() {

        const filter = createFilterOptions();

        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='offer-dialog'>
                <ReactTooltip/>

                <FileDialog
                    open={this.state.showAppendFileDialog}
                    close={this.closeAppendFileDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.props.appendFile}
                    item={this.state.offer}
                />

                <YesCancelDialog
                    open={this.state.showRemoveOfferFileDialog}
                    close={this.closeRemoveOfferFileDialog}
                    showMessage={this.props.showMessage}
                    onClick={() => this.props.removeFile(this.state.offer)}
                    header="Angebots-Datei löschen"
                    text="Wollen Sie die Datei wirklich löschen?"
                />

                <YesCancelDialog
                    open={this.state.showCompleteOfferDialog}
                    close={this.closeCompleteOfferDialog}
                    showMessage={this.props.showMessage}
                    onClick={() => this.props.completeOffer(this.state.offer)}
                    header="Angebot erfolgreich abschließen"
                    text="Wollen Sie das Angebot wirklich erfolgreich abschließen? Sie können das Angebot danach nicht wieder aktivieren."
                />

                <YesCancelDialog
                    open={this.state.showCancelOfferDialog}
                    close={this.closeCancelOfferDialog}
                    showMessage={this.props.showMessage}
                    onClick={() => this.props.cancelOffer(this.state.offer)}
                    header="Angebot abbrechen"
                    text="Wollen Sie das Angebot wirklich abbrechen? Sie können das Angebot danach nicht wieder aktivieren."
                />

                <YesCancelDialog
                    open={this.state.showReactivateOfferDialog}
                    close={this.closeReactivateOfferDialog}
                    showMessage={this.props.showMessage}
                    onClick={() => this.props.reactivateOffer(this.state.offer)}
                    header="Angebot abbrechen"
                    text="Wollen Sie das Angebot wirklich abbrechen? Sie können das Angebot danach nicht wieder aktivieren."
                />

                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle><h1>Angebot</h1></DialogTitle>
                <DialogContent>
                    <div>
                        <h2>Aktionen</h2>
                        {this.state.offer && this.state.offer.id && this.state.offer.status !== 1 && this.props.user && (this.props.user.rights === 3 || this.props.user.rights === 4) ? <RestartAltIcon data-tip='Angebot reaktivieren' onClick={() => this.showReactivateOfferDialog(this.state.offer)} className='icon action-icon'/> : null}
                        {this.state.offer && this.state.offer.id && this.state.offer.status === 1 ? <CheckIcon data-tip='Angebot erfolgreich abschließen' onClick={() => this.showCompleteOfferDialog(this.state.offer)} className='icon action-icon'/> : null}
                        {this.state.offer && this.state.offer.id && this.state.offer.status === 1 ? <DoDisturbIcon data-tip='Angebot abbrechen' onClick={() => this.showCancelOfferDialog(this.state.offer)} className='icon action-icon delete-icon'/> : null}
                        {this.state.offer && this.state.offer.id && this.state.offer.status === 1 ? <RequestPageIcon data-tip='Angebots-PDF generieren' onClick={() => this.props.generateOffer(this.state.offer)} className='icon action-icon'/> :
                            <RequestPageIcon style={{color:"gray"}} data-tip="Sie müssen zunächst speichern und der Status muss aktiv sein um dies machen zu können" className='icon action-icon'/>}
                        {this.state.offer && this.state.offer.id && this.state.offer.status === 1 ? <EmailIcon data-tip='Den Artikeln hinterlegte Lieferanten informieren' onClick={this.props.showInformSuppliersDialog} className='icon action-icon'/> :
                            <EmailIcon style={{color:"gray"}} data-tip="Sie müssen zunächst speichern und der Status muss aktiv sein um dies machen zu können" className='icon action-icon'/>}
                        {this.state.offer && this.state.offer.id && this.state.offer.status === 1 && this.state.offer.craftsman ? <EmailIcon data-tip='Den hinterlegten Handwerker informieren' onClick={this.props.showInformCraftsmanDialog} className='icon action-icon'/> :
                            <EmailIcon style={{color:"gray"}} data-tip="Sie müssen zunächst speichern, es muss ein Handwerker hinterlegt sein und der Status muss aktiv sein um dies machen zu können" className='icon action-icon'/>}
                    </div>
                    <div>
                        <h2>Datei</h2>
                        {this.state.offer && this.state.offer.id && this.state.offer.status === 1 ? <UploadFileIcon onClick={() => this.showAppendFileDialog(this.state.offer)} data-tip="Datei anhängen" className='icon edit-icon'/> :
                            <UploadFileIcon style={{color:"gray"}} data-tip="Sie müssen zunächst speichern und der Status muss aktiv sein um dies machen zu können" className='icon edit-icon'/>}
                        {this.state.offer && this.state.offer.id && this.state.offer.offerFileStorageLocation ? <PictureAsPdfIcon onClick={() => this.props.displayOfferFile(this.state.offer)} data-tip={this.state.offer.offerFileStorageLocation.name + " anzeigen"} className='icon display-pdf-icon'/> : null}
                        {this.state.offer && this.state.offer.id && this.state.offer.status === 1 && this.state.offer.id && this.state.offer.offerFileStorageLocation ? <EmailIcon onClick={() => this.props.sendOfferFileAsEmail(this.state.offer)} data-tip={this.state.offer.offerFileStorageLocation.name + " als E-Mail an den Kunden versenden"} className='icon mail-icon'/> : null}
                        {this.state.offer && this.state.offer.id && this.state.offer.status === 1 && this.state.offer.offerFileStorageLocation ? <DeleteIcon onClick={this.showRemoveOfferFileDialog} data-tip={this.state.offer.offerFileStorageLocation.name +  " löschen"} className='icon delete-icon'/> : null}
                    </div>
                    <div>
                        <h2>Kunde</h2>
                        <Autocomplete
                            disabled={this.state.offer && this.state.offer.id}
                            value={this.state.offer && this.state.offer.customer ? this.state.offer.customer.name: ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.customerOnChangeHandler({
                                        name: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    this.customerOnChangeHandler({
                                        name: newValue.inputValue,
                                    });
                                    this.setState({isNewCustomer:true});
                                } else {
                                    this.customerOnChangeHandler(newValue);
                                    this.setState({isNewCustomer:false});
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.name);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        name: `Erstellen "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="customers"
                            className='field'
                            options={this.props.customers}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Kunde" />
                            )}
                        />
                        {this.state.isNewCustomer ? <TextField className='field' label='Kundennummer' value={this.state.offer.customer.nr} onChange={this.customerNrOnChangeHandler}/> : null}
                        {this.state.isNewCustomer ? <TextField className='field' label='Straße' value={this.state.offer.customer.streetName} onChange={this.customerStreetNameOnChangeHandler}/> : null}
                        {this.state.isNewCustomer ? <TextField className='field' label='Hausnummer' value={this.state.offer.customer.streetNr} onChange={this.customerStreetNrOnChangeHandler}/> : null}
                        {this.state.isNewCustomer ? <TextField className='field' label='Adresszusatz' value={this.state.offer.customer.addressAddition} onChange={this.customerAddressAdditionOnChangeHandler}/> : null}
                        {this.state.isNewCustomer ? <TextField className='field' label='Postleitzahl' value={this.state.offer.customer.postCode} onChange={this.customerPostCodeOnChangeHandler}/> : null}
                        {this.state.isNewCustomer ? <TextField className='field' label='Stadt' value={this.state.offer.customer.city} onChange={this.customerCityOnChangeHandler}/> : null}
                        {this.state.isNewCustomer ? <TextField className='field' label='Land' value={this.state.offer.customer.country} select onChange={this.customerCountryOnChangeHandler}>
                            {this.props.countries}
                        </TextField> : null}
                        {this.state.isNewCustomer ? <TextField className='field' label='E-Mail' value={this.state.offer.customer.email} onChange={this.customerEmailOnChangeHandler}/> : null}
                        {this.state.isNewCustomer ? <TextField className='field' label='Telefon' value={this.state.offer.customer.phone} onChange={this.customerPhoneOnChangeHandler}/> : null}
                    </div>
                    <div>
                        <h2>Handwerker</h2>
                        <Autocomplete
                            disabled={this.state.offer && this.state.offer.status !== 1}
                            value={this.state.offer && this.state.offer.craftsman ? this.state.offer.craftsman.name : ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.craftsmanOnChangeHandler({
                                        name: newValue,
                                    });
                                } else {
                                    this.craftsmanOnChangeHandler(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="users"
                            className='field'
                            options={this.props.craftsmen}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.name;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <TextField helperText='optional' {...params} label="Handwerker" />
                            )}
                        />
                    </div>
                    <div>
                        <h2>Allgemein</h2>
                        <TextField disabled={this.state.offer && this.state.offer.status !== 1} className='field' type='date' label='Datum' value={this.state.offer ? this.state.offer.date : null} onChange={this.dateOnChangeHandler}/>
                    </div>
                    <div>
                        <h2>Positionen</h2>
                        {this.state.offer ? this.state.offer.documentPositions.map((documentPosition,index) => (
                            <DocumentPosition
                                key={index}
                                index={index}
                                documentPosition={documentPosition}
                                articleOnChange={this.documentPositionArticleOnChange}
                                countOnChange={this.documentPositionCountOnChange}
                                removeDocumentPosition={this.removeDocumentPosition}
                                articles={this.props.articles}
                                disabled={this.state.offer && this.state.offer.status !== 1}
                                articlePriceOnChange={this.articlePriceOnChange}
                                provisionOnChangeHandler={this.provisionOnChangeHandler}
                            />
                        )) : null}
                        <ToolButton disabled={this.state.offer && this.state.offer.status !== 1} className='button add-position-button' onClick={this.addDocumentPosition}>Position hinzufügen</ToolButton>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ToolButton disabled={this.state.offer && this.state.offer.status !== 1} className='button' main onClick={() => this.saveOfferOnClickHandler(this.state.offer)}>Speichern</ToolButton>
                </DialogActions>
            </Dialog>
        )
    }
}

export default OfferDialog;