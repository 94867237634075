import React, {Component} from "react";
import './SidePanel.css';

//Components

class SidePanel extends Component {

    render() {
        return (
            <div onKeyDown={this._handleKeyDown} className='side-panel'>

            </div>
        )
    }
}

export default SidePanel;